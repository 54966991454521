import React from 'react';
import { Close as IconClose } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';

interface SnackbarCloseProps {
  snackbarKey: number;
}

const SnackbarCloseButton = ({ snackbarKey }: SnackbarCloseProps): React.ReactElement => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton sx={{ transform: 'scale(0.5)' }} onClick={() => closeSnackbar(snackbarKey)}>
      <IconClose />
    </IconButton>
  );
};

export default SnackbarCloseButton;
