import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from '../../app/hooks';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { es } from 'yup-locales';
import TagManager from 'react-gtm-module';

yup.setLocale(es);

import GoogleLogin from './GoogleLogin';

// Features
import { SessionState } from './sessionSlice';

// API
import { sessionsApi } from '../../common/api';

// Components
import {
  Container,
  Card,
  CircularProgress,
  Typography,
  TextField,
  Button,
  Grid,
  InputAdornment,
} from '@mui/material';

import Recaptcha from '../../common/components/Recaptcha';

// Assets
import styles from './Login.module.scss';
import { setDocumentTitle } from '../../common/utils';

const SignupSchema = yup.object().shape({
  name: yup.string().min(3).max(32).required().label('Nombre'),
  email: yup.string().email().required().label('Correo'),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, 'Deben ser solo dígitos')
    .min(9, 'Debe tener un largo exacto de 9 dígitos')
    .max(9, 'Debe tener un largo exacto de 9 dígitos')
    .required(),
  password: yup.string().min(8).max(32).required().label('Contraseña'),
  confirmation: yup
    .string()
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], 'Contraseñas no coinciden'),
    })
    .required()
    .label('Contraseña'),
});

interface SignupInfo {
  name: string;
  email: string;
  phone: string;
  password: string;
  confirmation: string;
}

const Register = (): React.ReactElement => {
  setDocumentTitle('Zafepay | Registro');
  const { loading } = useSelector(({ session }: { session: SessionState }) => session);
  const { enqueueSnackbar } = useSnackbar();
  const recaptchaRef = useRef<{ execute: () => Promise<string> }>();

  const formik = useFormik<SignupInfo>({
    initialValues: { name: '', email: '', phone: '', password: '', confirmation: '' },
    validationSchema: SignupSchema,
    onSubmit: (signupInfo: SignupInfo, { setErrors }: any) => {
      recaptchaRef.current
        ?.execute()
        .then((token: string) => {
          sessionsApi
            .signup({ user: signupInfo, recaptcha: token })
            .then(executeTagManager)
            .catch((err: any): void => {
              if (err?.response?.status === 400) {
                setErrors(err.response.data?.meta?.errors);
              } else {
                enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
              }
            });
        })
        .catch(() => enqueueSnackbar('reCAPTCHA fallido', { variant: 'error' }));
    },
  });

  const executeTagManager = () => {
    TagManager.dataLayer({
      dataLayer: { event: 'register' },
    });
  };

  return (
    <Container className={styles.baseContainer} maxWidth={false}>
      <Container className={styles.logoRegisterContainer}>
        <Grid container>
          <Grid item>
            <img
              src={
                'https://storage.googleapis.com/onlypays-public/assets/images/boy%20using%20laptop%20and%20talking%20on%20the%20phone.svg'
              }
              className={styles.boyImage}
            />
          </Grid>
          <Grid item>
            <Typography variant="h5" align="center">
              <strong>Pagos Seguros</strong>
            </Typography>
            <Typography variant="h5" align="center">
              <strong>Facilitamos tus Cobros</strong>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container className={styles.container}>
        <Recaptcha innerRef={recaptchaRef} />
        <Card className={styles.card}>
          <Grid container className={styles.grid}>
            <Grid item>
              <Typography variant="h3">Regístrate</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" align="center">
                ¡Comienza a generar cobros automáticos hoy!
              </Typography>
              <Typography variant="h5" align="center">
                De forma fácil y segura.
              </Typography>
            </Grid>
            <Grid item className={styles.formGridItem}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>Nombre</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="name"
                      type="text"
                      autoComplete="name"
                      variant="outlined"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Correo</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="email"
                      type="text"
                      autoComplete="email"
                      variant="outlined"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Teléfono*</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="phone"
                      type="text"
                      autoComplete="phone"
                      variant="outlined"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={formik.touched.phone && Boolean(formik.errors.phone)}
                      helperText={formik.touched.phone && formik.errors.phone}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Contraseña</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="password"
                      type="password"
                      autoComplete="password"
                      variant="outlined"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Confirma Contraseña</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="confirmation"
                      type="password"
                      variant="outlined"
                      value={formik.values.confirmation}
                      onChange={formik.handleChange}
                      error={formik.touched.confirmation && Boolean(formik.errors.confirmation)}
                      helperText={formik.touched.confirmation && formik.errors.confirmation}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      disableElevation
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading}
                      className="loader"
                    >
                      {loading && <CircularProgress size={20} />}
                      Registrarse
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      disableElevation
                      size="large"
                      variant="outlined"
                      color="primary"
                      type="submit"
                      disabled={loading}
                      component={Link}
                      to="/auth/login"
                    >
                      Volver
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item>
              <GoogleLogin triggerTagManager={true} />
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Container>
  );
};

export default Register;
