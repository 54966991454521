import { ApiList, PaymentPlan, ApiObject, Statement, Card } from '../../../app/type';

import client from '../client';

export const list = (clientId: string): Promise<ApiList<PaymentPlan>> => {
  return client({
    method: 'get',
    url: `/v1/client/payment_plans`,
    params: { client_id: clientId },
  })
    .then(({ data: body }: { data: ApiList<PaymentPlan> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const show = (paymentPlanId: string, clientId: string): Promise<ApiObject<PaymentPlan>> => {
  return client({
    method: 'get',
    url: `/v1/client/payment_plans/${paymentPlanId}`,
    params: { client_id: clientId },
  })
    .then(({ data: body }: { data: ApiObject<PaymentPlan> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const futureStatements = (
  paymentPlanId: string,
  clientId: string,
  page?: number
): Promise<ApiList<Statement>> => {
  return client({
    method: 'get',
    url: `/v1/client/payment_plans/${paymentPlanId}/future_statements`,
    params: { client_id: clientId, page: page || 1 },
  })
    .then(({ data: body }: { data: ApiList<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const paidStatements = (
  paymentPlanId: string,
  clientId: string,
  page?: number
): Promise<ApiList<Statement>> => {
  return client({
    method: 'get',
    url: `/v1/client/payment_plans/${paymentPlanId}/paid_statements`,
    params: { client_id: clientId, page: page || 1 },
  })
    .then(({ data: body }: { data: ApiList<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const assignCard = (
  cardId: string,
  paymentPlanId: string,
  clientId: string
): Promise<Card> => {
  return client({
    method: 'post',
    url: `/v1/client/payment_plans/${paymentPlanId}/assign_card`,
    params: { client_id: clientId, card_id: cardId },
  })
    .then(({ data: body }: { data: any }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
