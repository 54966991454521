import React, { useEffect } from 'react';

import { FormikProps, getIn } from 'formik';

// Components
import { Typography, Grid, TextField, MenuItem } from '@mui/material';

// Assets
import styles from './InvoicingSetupForm.module.scss';
import { InvoiceConfiguration } from '../../app/type';
import { InfoButton } from './InfoButton';

interface InvoicingSetupFormProps {
  formik: FormikProps<InvoiceConfiguration>;
  singlePayment?: boolean;
}

const InvoicingSetupForm = ({
  formik,
  singlePayment,
}: InvoicingSetupFormProps): React.ReactElement => {
  useEffect(() => {
    if (singlePayment) formik.setFieldValue('emit_at', 'payment');
  }, []);
  return (
    <div className={styles.mainContainer}>
      <div className={styles.fieldContainer}>
        <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
          <Typography className={styles.fieldHeader}>Tipo de documento</Typography>
          <InfoButton text="document" context="invoiceConfiguration" />
        </Grid>
        <TextField
          className={styles.inputField}
          id="document"
          fullWidth
          select
          variant="outlined"
          required
          name="document"
          value={formik.values.document}
          onChange={formik.handleChange}
          error={getIn(formik.touched, 'document') && Boolean(getIn(formik.errors, 'document'))}
          helperText={getIn(formik.touched, 'document') && getIn(formik.errors, 'document')}
        >
          <MenuItem value="receipt">Boleta</MenuItem>
          <MenuItem value="invoice">Factura</MenuItem>
          <MenuItem value="selectable">Cliente elige</MenuItem>
        </TextField>
      </div>
      {['receipt', 'selectable'].includes(formik.values.document) && (
        <div className={styles.fieldContainer}>
          <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
            <Typography className={styles.fieldHeader}>¿Pedir RUT para emitir boleta?</Typography>
            <InfoButton text="ask_rut" context="invoiceConfiguration" />
          </Grid>
          <TextField
            className={styles.inputField}
            id="ask_rut"
            fullWidth
            select
            variant="outlined"
            name="ask_rut"
            value={formik.values.ask_rut}
            onChange={(event) =>
              formik.setFieldValue('ask_rut', event.target.value === 'true' ? true : false)
            }
            error={getIn(formik.touched, 'ask_rut') && Boolean(getIn(formik.errors, 'ask_rut'))}
            helperText={getIn(formik.touched, 'ask_rut') && getIn(formik.errors, 'ask_rut')}
          >
            <MenuItem value="true">Sí</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </TextField>
        </div>
      )}
      <div className={styles.fieldContainer}>
        <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
          <Typography className={styles.fieldHeader}>IVA</Typography>
          <InfoButton text="vat_exempt" context="invoiceConfiguration" />
        </Grid>
        <TextField
          className={styles.inputField}
          id="vat_exempt"
          autoComplete="vat_exempt"
          defaultValue="false"
          fullWidth
          select
          required
          variant="outlined"
          name="vat_exempt"
          value={formik.values.vat_exempt}
          onChange={(event) =>
            formik.setFieldValue('vat_exempt', event.target.value === 'true' ? true : false)
          }
          error={getIn(formik.touched, 'vat_exempt') && Boolean(getIn(formik.errors, 'vat_exempt'))}
          helperText={getIn(formik.touched, 'vat_exempt') && getIn(formik.errors, 'vat_exempt')}
        >
          <MenuItem value="false">Afecto</MenuItem>
          <MenuItem value="true">Exento</MenuItem>
        </TextField>
        {formik.values.vat_exempt === false && (
          <Typography fontSize={14} mt={0.5}>
            *Importante: el valor del servicio debe incluir el IVA.
          </Typography>
        )}
      </div>
      <div className={styles.fieldContainer}>
        <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
          <Typography className={styles.fieldHeader}>¿Cuándo emitir el documento?</Typography>
          <InfoButton text="emit_at" context="invoiceConfiguration" />
        </Grid>
        <TextField
          className={styles.inputField}
          id="emit_at"
          fullWidth
          select
          required
          variant="outlined"
          name="emit_at"
          value={formik.values.emit_at}
          onChange={formik.handleChange}
          error={getIn(formik.touched, 'emit_at') && Boolean(getIn(formik.errors, 'emit_at'))}
          helperText={getIn(formik.touched, 'emit_at') && getIn(formik.errors, 'emit_at')}
        >
          <MenuItem value="payment">Pago exitoso</MenuItem>
          {!singlePayment && <MenuItem value="due_date">Fecha de cobro</MenuItem>}
        </TextField>
      </div>
    </div>
  );
};

export default InvoicingSetupForm;
