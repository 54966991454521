import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  FormLabel,
} from '@mui/material';
import React, { Fragment } from 'react';
import Avatar from './Avatar';

import styles from '../../features/buyer/Payment.module.scss';
import formStyles from '../../features/buyer/components/Forms/Forms.module.scss';
import paySummaryStyles from '../../features/buyer/components/PaymentSummary.module.scss';
import variables from '../../common/styles/variables.module.scss';
import { Company } from '../../app/type';
import { formatCurrency, hexToRgb } from '../utils';
import cardIcon from '../../assets/images/seller/card.svg';
import walletIcon from '../../assets/images/seller/wallet.svg';
import moneyBillIcon from '../../assets/images/seller/money_bill.svg';

interface PreviewProps {
  background_color?: string;
  company?: Company;
}

const PreviewBox = (props: PreviewProps): React.ReactElement => {
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const rgbColor = props.background_color ? hexToRgb(props.background_color) : null;
  const cardsBackgroundColor = rgbColor
    ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    : '#f5f6ff';

  return (
    <div className={styles.paymentPreviewContianer}>
      <div className={styles.previewContainerPayment}>
        <Avatar
          className={styles.sellerHeaderAvatar}
          alt={props.company?.name}
          img={props.company?.image}
          context="company"
        />
        <div className={styles.stepperContainer}>
          <Stepper activeStep={0} nonLinear>
            <Step
              completed={false}
              sx={{
                text: {
                  fill: `${props.background_color} !important`,
                },
                svg: {
                  borderColor: `${props.background_color} !important`,
                  color: 'white !important',
                },
              }}
            >
              <StepLabel />
            </Step>
            <Step
              completed={false}
              sx={{
                text: {
                  fill: '#838383 !important',
                },
                svg: {
                  borderColor: '#838383!important',
                  color: 'white !important',
                },
              }}
            >
              <StepLabel />
            </Step>
          </Stepper>
        </div>
        <div className={isMobile ? styles.verticalContainer : styles.verticalContainer}>
          <div className={formStyles.buyerData}>
            <div className={formStyles.titleForm}>
              <Typography
                className={formStyles.title}
                id="clientForm"
                style={{ color: props.background_color }}
              >
                Resumen de pago
              </Typography>
              <Typography className={formStyles.stepDescription}>
                <span className={formStyles.mediumText}>¡Completa este formulario!</span> y luego
                podrás visualizar el resumen de tu pago.
              </Typography>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} className={formStyles.inputContainer}>
                <Typography>
                  Nombre completo
                  <span className={formStyles.mandatoryText}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  id="name"
                  type="text"
                  autoComplete="name"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography>
                  Email<span className={formStyles.mandatoryText}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  id="email"
                  type="text"
                  autoComplete="email"
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </div>
          <div className={styles.productSummary} style={{ width: '80%', marginTop: '30px' }}>
            <div
              className={paySummaryStyles.paymentSummary}
              style={{
                background: cardsBackgroundColor,
                marginTop: isMobile ? '40px' : '0',
              }}
            >
              <div className={paySummaryStyles.sellerHeaderContent}>
                <Paper id="imageContainer" className={paySummaryStyles.paperImage}>
                  <img
                    className={paySummaryStyles.imageShoppingCart}
                    src={
                      'https://storage.googleapis.com/onlypays-public/assets/images/shopping%20cart%20with%20boxes.svg'
                    }
                    alt="Logo Producto"
                  />
                </Paper>
                <div className={paySummaryStyles.paymentSummaryText}>
                  <div className={paySummaryStyles.price}>
                    <Typography className={paySummaryStyles.amount}>
                      <span
                        className={paySummaryStyles.number}
                        style={{ color: props.background_color }}
                      >
                        {formatCurrency(20000, 'CLP')}
                      </span>
                      <span
                        className={paySummaryStyles.recurrence}
                        style={{ color: props.background_color }}
                      >
                        {' '}
                        / Mensual
                      </span>
                    </Typography>
                  </div>
                  <Typography variant="h5">Nombre producto</Typography>
                  <Typography className={paySummaryStyles.description}>
                    Descripción producto
                  </Typography>
                </div>
              </div>
            </div>
            <div className={styles.kioskFooter}>
              <Typography align="center">
                Cualquier duda contactános en{' '}
                <a href={`mailto:${props.company?.email}`} style={{ color: '#1f1f1f' }}>
                  {props.company?.email}
                </a>{' '}
                {props.company?.user_phone && ` o al ${props.company?.user_phone}`}
              </Typography>
            </div>
          </div>
        </div>
        <Button
          variant="contained"
          className={styles.payButton}
          style={{
            backgroundColor: props.background_color,
          }}
        >
          Ir a pagar
        </Button>
      </div>

      <div className={styles.previewContainerPayment}>
        <Avatar
          className={styles.sellerHeaderAvatar}
          alt={props.company?.name}
          img={props.company?.image}
          context="company"
        />
        <div className={styles.stepperContainer}>
          <Stepper activeStep={1} nonLinear>
            <Step
              completed={true}
              sx={{
                text: {
                  fill: 'white !important',
                },
                svg: {
                  borderColor: 'white !important',
                  color: `${props.background_color} !important`,
                },
              }}
            >
              <StepLabel />
            </Step>
            <Step
              completed={false}
              sx={{
                text: {
                  fill: `${props.background_color} !important`,
                },
                svg: {
                  borderColor: `${props.background_color} !important`,
                  color: 'white !important',
                },
              }}
            >
              <StepLabel />
            </Step>
          </Stepper>
        </div>
        <div className={styles.containerPaymentGateways} style={{ width: '80%' }}>
          <div className={formStyles.titleForm}>
            <Typography
              className={formStyles.title}
              id="clientForm"
              style={{ color: props.background_color }}
            >
              Selecciona el medio de pago
            </Typography>
            <Typography className={formStyles.stepDescription}>
              Elige el medio de pago y sigue los pasos
            </Typography>
          </div>
          <div className={styles.paymentMethods}>
            <FormControl variant="standard" className={styles.formRadio}>
              <RadioGroup name="Card Registered" value={'webpay_plus'}>
                <Paper key={'webpay_plus'} elevation={0} className={styles.paymentMethod}>
                  <div>
                    <FormControlLabel
                      checked={true}
                      value={'webpay_plus'}
                      control={<Radio style={{ color: props.background_color }} />}
                      label={
                        <div>
                          <img src={cardIcon} className={styles.icon} />
                          <Typography>Tarjeta de Crédito o Débito</Typography>
                        </div>
                      }
                    />
                  </div>
                </Paper>
                <Paper key={'mach'} elevation={0} className={styles.paymentMethod}>
                  <div>
                    <FormControlLabel
                      checked={false}
                      value={'mach'}
                      control={<Radio />}
                      label={
                        <div>
                          <img src={walletIcon} className={styles.icon} />
                          <Typography>Prepago</Typography>
                        </div>
                      }
                    />
                  </div>
                </Paper>
                <Paper key={'fintoc'} elevation={0} className={styles.paymentMethod}>
                  <div>
                    <FormControlLabel
                      checked={false}
                      value={'fintoc'}
                      control={<Radio />}
                      label={
                        <div>
                          <img src={moneyBillIcon} className={styles.icon} />
                          <Typography>Transferencia Bancaria</Typography>
                        </div>
                      }
                    />
                  </div>
                </Paper>
              </RadioGroup>
            </FormControl>
            <Fragment>
              <FormControl className={styles.subscribeCardForm}>
                <FormLabel>
                  ¿Quieres inscribir una tarjeta para realizar pagos automáticos?
                </FormLabel>
                <RadioGroup row name="Card Registered" value={true}>
                  <FormControlLabel value={false} control={<Radio />} label="Si" />
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: props.background_color }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Fragment>
          </div>
          {isMobile ? (
            <div className={styles.paymentButtonContainer}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: props.background_color,
                }}
                className={styles.payButton}
              >
                Finalizar pago
              </Button>
            </div>
          ) : (
            <Button
              variant="contained"
              style={{
                backgroundColor: props.background_color,
              }}
              className={styles.payButton}
            >
              Finalizar pago
            </Button>
          )}
        </div>
        <Button
          variant="outlined"
          className={styles.payButton}
          style={{
            backgroundColor: 'white',
            color: props.background_color,
            borderColor: props.background_color,
          }}
        >
          Volver
        </Button>
      </div>
    </div>
  );
};

export default PreviewBox;
