import React from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import styles from './Block.module.scss';

interface PlatformBlockProps {
  title?: string;
  text?: string;
  image?: string;
  logo?: boolean;
  extraActions?: React.ReactElement[];
}

const PlatformBlock = (props: PlatformBlockProps): React.ReactElement => {
  return (
    <Paper className={styles.dashBody} elevation={0}>
      <div className={styles.generalContainer}>
        <img
          src={
            props.image ||
            'https://storage.googleapis.com/onlypays-public/assets/images/expired_link.svg'
          }
        />
        <div className={styles.textContainer}>
          <Typography className={styles.title}>{props.title || '¡Lo sentimos!'}</Typography>
          <Typography className={styles.text}>
            {props.text || (
              <span>
                Tu cuenta está bloqueada. Ponte en contacto con{' '}
                <a href="mailto:contacto@zafepay.com">contacto@zafepay.com</a> para solucionar este
                problema
              </span>
            )}
          </Typography>
          {props.extraActions}
        </div>
        {props.logo && (
          <img
            src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_grey.png"
            className={styles.onlypaysLogo}
          />
        )}
      </div>
    </Paper>
  );
};

export default PlatformBlock;
