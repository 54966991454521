import React, { useState } from 'react';

import { Popover, Grid, Button, Typography } from '@mui/material';

import { SUBSCRIPTIONS_DESCRIPTIONS, STATEMENTS_DESCRIPTIONS } from '../constants/descriptions';

import { StatusLabel } from './StatusLabel';

interface DescriptionsBoxProps {
  context?: string;
}

export const DescriptionsBox = ({ context }: DescriptionsBoxProps): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [text, setText] = useState<string>('');
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, key: string) => {
    setText(
      context == 'statements'
        ? STATEMENTS_DESCRIPTIONS[key as keyof typeof STATEMENTS_DESCRIPTIONS]
        : SUBSCRIPTIONS_DESCRIPTIONS[key as keyof typeof SUBSCRIPTIONS_DESCRIPTIONS]
    );
    setAnchorEl(event.currentTarget);
  };

  return (
    <Grid container spacing={1}>
      {context == 'statements'
        ? Object.keys(STATEMENTS_DESCRIPTIONS).map((key: string) => (
            <Grid item xs={6} md={2} key={key}>
              <Button onClick={(event: any) => handleClick(event, key)} aria-describedby={id}>
                <StatusLabel type="statement" status={key} />
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Typography variant="body2" sx={{ p: 2, maxWidth: '300px' }}>
                  {text}
                </Typography>
              </Popover>
            </Grid>
          ))
        : Object.keys(SUBSCRIPTIONS_DESCRIPTIONS).map((key: string) => (
            <Grid item xs={6} md={2} key={key}>
              <Button onClick={(event: any) => handleClick(event, key)} aria-describedby={id}>
                <StatusLabel type="subscription" status={key} />
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Typography variant="body2" sx={{ p: 2, maxWidth: '300px' }}>
                  {text}
                </Typography>
              </Popover>
            </Grid>
          ))}
    </Grid>
  );
};

export default DescriptionsBox;
