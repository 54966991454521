import React from 'react';

// Assets
import styles from './Response.module.scss';

interface ResponseProps {
  loading: boolean;
  backgroundStyle: string;
  backgroundImgStyle?: string;
  icon: string;
  content: React.ReactElement;
}

const Response = ({
  loading,
  backgroundStyle,
  backgroundImgStyle,
  icon,
  content,
}: ResponseProps): React.ReactElement => {
  return (
    <div className={`${styles.responseContainer} ${backgroundStyle}`}>
      {loading && (
        <div className={styles.loaderContainer}>
          <img
            src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
            alt="Logo Zafepay"
          />
        </div>
      )}
      <div className={`${styles.response} ${backgroundImgStyle && backgroundImgStyle}`}>
        <div className={styles.responseContent}>
          <img src={icon} alt="ícono respuesta" />
          {content}
          <div className={styles.zpLogoContainer}>
            <a href="https://zafepay.com">
              <img
                src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_white.png"
                alt="Logo Zafepay"
                className={styles.zpLogo}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Response;
