import React from 'react';

import { FormikProps } from 'formik';
import { Buyer, Discount, Payable, ShippingInformation } from '../../../../app/type';

import FormSelector from '../Forms/Forms';
import ProductDisplayerAndSelector from '../ProductDisplayer/ProductDisplayerAndSelector';

import styles from './ShopifyOrder.module.scss';
import { formatCLP, hexToRgb } from '../../../../common/utils';

import { Button, Typography, useMediaQuery } from '@mui/material';

interface ShopifyOrderProps {
  formik: FormikProps<Partial<Buyer>>;
  shippingFormik: FormikProps<ShippingInformation>;
  payable: Payable;
  setPayable: (arg: Payable) => void;
  nextStep: () => void;
  customRecurrenceProps?: {
    selectedRecurrence: string | null;
    setSelectedRecurrence: (arg: string | null) => void;
    amount: number | null;
    setAmount: (arg: number | null) => void;
    errors: { [key: string]: string | null };
  };
  shippingCost: number | null;
  setChangedQty: () => void;
  disabledContinueButton: boolean;
  loadingStock: boolean;
  loadingShippingCost?: boolean;
  validateDiscountCode?: (event: any) => void;
  loadingDiscount?: boolean;
  extraDiscount?: Partial<Discount>;
  validExtraDiscount?: boolean;
  allowCustomCheckout: boolean;
  productsToSubscribeState?: {
    state: string[] | undefined;
    setState: (a: string[]) => void;
  };
  codeDiscountState?: {
    state: string | undefined;
    setState: (a: string) => void;
  };
}

const ShopifyOrder = (props: ShopifyOrderProps): React.ReactElement => {
  const isTablet = useMediaQuery(`(max-width: 1330px)`);
  const rgbColor = props.payable?.company.payment_design?.background_color
    ? hexToRgb(props.payable?.company.payment_design?.background_color)
    : null;
  const cardsBackgroundColor = rgbColor
    ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    : '#f5f6ff';

  const amountToRender = Math.ceil(
    (props.payable?.payable_products
      ?.map(
        (prod) =>
          prod.quantity *
          prod.product.value *
          (1 -
            (((props.productsToSubscribeState?.state?.includes(prod.id) &&
              !props.payable?.modificable_recurrence) ||
            props.payable?.modificable_recurrence
              ? prod.product.percentage_discount || 0
              : 0) +
              (props.extraDiscount?.value || 0)) /
              100)
      )
      .reduce((prev, curr) => prev + curr, 0) || 0) + (props.shippingCost || 0)
  );

  return (
    <>
      <div className={styles.horizontalContainer} style={{ width: isTablet ? '100%' : '90%' }}>
        <div className={styles.productDisplayContainer}>
          <ProductDisplayerAndSelector
            payable={props.payable}
            setPayable={props.setPayable}
            customRecurrenceProps={props.customRecurrenceProps}
            step="buyer_data"
            shippingCost={props.shippingCost}
            setChangedQty={props.setChangedQty}
            loadingStock={props.loadingStock}
            loadingShippingCost={props.loadingShippingCost || false}
            extraDiscount={props.extraDiscount}
            productsToSubscribeState={props.productsToSubscribeState}
          />
        </div>
        <div className={styles.formContainer} style={{ width: isTablet ? '90%' : '402px' }}>
          <FormSelector
            formik={props.formik}
            shippingFormik={props.shippingFormik}
            payable={props.payable}
            customRecurrenceProps={props.customRecurrenceProps}
            shippingCost={props.shippingCost}
            validateDiscountCode={props.validateDiscountCode}
            loadingDiscount={props.loadingDiscount}
            extraDiscount={props.extraDiscount}
            validExtraDiscount={props.validExtraDiscount}
            allowCustomCheckout={props.allowCustomCheckout}
            productsToSubscribe={props.productsToSubscribeState?.state}
            codeDiscountState={props.codeDiscountState}
          />
          <div className={styles.buttonContainer}>
            <Button
              variant="contained"
              onClick={props.nextStep}
              className={styles.nextButton}
              style={{
                backgroundColor:
                  props.payable?.company?.payment_design?.background_color || '#4653E3',
                fontFamily: 'Poppins',
              }}
              disabled={props.disabledContinueButton}
            >
              Continuar
            </Button>
          </div>
        </div>
      </div>
      {isTablet ? (
        <div className={styles.stickyBottom}>
          <div className={styles.amountSummary} style={{ backgroundColor: cardsBackgroundColor }}>
            <div className={styles.quantity}>
              <Typography variant="subtitle2">Cantidad de productos</Typography>
              <Typography variant="h4">
                {props.payable?.payable_products
                  ?.map((prod) => prod.quantity)
                  .reduce((prev, curr) => prev + curr, 0)}
              </Typography>
            </div>
            <div className={styles.total}>
              <Typography variant="subtitle2">Total</Typography>
              <Typography variant="h4">{formatCLP(amountToRender || 0)}</Typography>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ShopifyOrder;
