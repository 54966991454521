import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from '../../../app/hooks';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as yup from 'yup';

// Constants
import { RECURRENCE_ES } from '../../../common/constants/subscriptions';

// Utils
import {
  downcase,
  formatCurrency,
  formatRUT,
  setDocumentTitle,
  validateRut,
} from '../../../common/utils';

// Types
import {
  ApiObject,
  ApiList,
  Buyer,
  Product,
  Subscription,
  Statement,
  PaymentPlan,
  SubscriptionBuyer,
  SinglePaymentBuyer,
  SinglePayment,
  PaymentTransaction,
  InvoiceInformation,
  GenericObject,
  // ExtraField,
} from '../../../app/type';

// API
import { sellerApi } from '../../../common/api';

// Features
import { SellerState, setBuyer, setProduct, setSubscription } from '../sellerSlice';

// Components
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
  Button,
  Drawer,
  TextField,
  MenuItem,
} from '@mui/material';
import { Edit as EditIcon, Trash as TrashIcon } from 'react-feather';

import Avatar from '../../../common/components/Avatar';
import ResourceList from '../../../common/components/ResourceList';
import StatusLabel from '../../../common/components/StatusLabel';

// Assets
import cStyles from '../../../common/styles/common.module.scss';
import styles from './Index.module.scss';
import { setSinglePayment } from '../sellerSlice';
import PopUp from '../../../common/components/PopUp';
import { REGIONS } from '../../../common/constants/regions';
import { COMMUNES_BY_REGION } from '../../../common/constants/communes';

const BuyerShow = (): React.ReactElement => {
  const { buyer } = useSelector(({ seller }: { seller: SellerState }) => seller);
  setDocumentTitle(buyer ? `${buyer?.name} ${buyer?.last_name || ''}` : 'Clientes');
  const [loading, setLoading] = useState<boolean>(false);
  const { buyerId } = useParams<{ buyerId: string }>();
  const dispatch = useDispatch();
  const [subscriptionBuyers, setSubscriptionBuyers] = useState<SubscriptionBuyer[] | []>([]);
  const [openDeleteBuyer, setDeleteBuyerOpen] = useState(false);
  const history = useHistory();
  const onItemSubscription = (subscription?: Subscription) =>
    dispatch(setSubscription(subscription));
  const onItemSinglePayment = (singlePayment?: SinglePayment) =>
    dispatch(setSinglePayment(singlePayment));
  const onItemPaymentPlan = (product?: Product) => dispatch(setProduct(product));
  const { enqueueSnackbar } = useSnackbar();
  const [drawerInvoiceInformation, setDrawerInvoiceInformation] = useState<boolean>(false);
  const [invoiceInformationSelected, setInvoiceInformationSelected] =
    useState<InvoiceInformation>();
  const [subscriptionBuyerSelected, setSubscriptionBuyerSeleceted] = useState<SubscriptionBuyer>();
  const [extraFields, setExtraFields] = useState<GenericObject>();
  const [drawerExtraFields, setDrawerExtraFields] = useState<boolean>(false);

  const invoiceInformationSchema = useMemo(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return yup.object().shape({
      region: yup.string().label('Región'),
      commune: yup.string().label('Comuna'),
      address: yup.string().label('Dirección'),
      rut: yup
        .string()
        .label('Rut')
        .test('is-valid-rut', 'Rut ingresado inválido', (value) => !value || validateRut(value)),
      business_name: yup.string().label('Razón social'),
      activity: yup.string().label('Giro'),
      email: yup
        .string()
        .email()
        .matches(emailRegex, 'Email inválido, no puede contener caracteres especiales')
        .label('Email de contacto')
        .test('gmail-domain', 'Dominio del correo inválido, debe terminar en .com', (value) => {
          if (!value) return true;
          const domain = value.split('@')[1];
          if (domain && (domain.startsWith('gmail') || domain.startsWith('hotmail'))) {
            return domain === 'gmail.com' || domain === 'hotmail.com';
          }
          return true;
        }),
    });
  }, [invoiceInformationSelected]);

  const invoiceInformationFormik = useFormik<InvoiceInformation>({
    initialValues: {
      id: invoiceInformationSelected?.id || '',
      region: invoiceInformationSelected?.region || '',
      commune: invoiceInformationSelected?.commune || '',
      address: invoiceInformationSelected?.address || '',
      rut: invoiceInformationSelected?.rut || '',
      business_name: invoiceInformationSelected?.business_name || '',
      activity: invoiceInformationSelected?.activity || '',
      email: invoiceInformationSelected?.email || '',
    },
    validationSchema: () => invoiceInformationSchema,
    onSubmit: (values, { setErrors }: any) => {
      setLoading(true);
      sellerApi.buyers
        .editInvoiceInformation(buyer?.id || '', invoiceInformationSelected?.id || '', {
          data: values,
        })
        .then((data) => {
          dispatch(setBuyer(data.data));
          setDrawerInvoiceInformation(false);
          enqueueSnackbar('Información actualizada exitosamente', { variant: 'success' });
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
          } else if (err?.response?.status === 403) {
            enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        })
        .finally(() => setLoading(false));
    },
    enableReinitialize: true,
  });

  const deleteBuyer = () => {
    sellerApi.buyers
      .destroy(buyer?.id)
      .then(() => {
        enqueueSnackbar('Cliente eliminado exitosamente', { variant: 'success' });
        history.push('/seller/buyers');
      })
      .catch((error: any) => {
        let msg = 'No se pudo eliminar el cliente';
        if (error.response.data?.message == 'has_payments')
          msg = 'No se puede eliminar porque tiene pagos asociados';
        enqueueSnackbar(msg, { variant: 'error' });
      })
      .finally(() => setDeleteBuyerOpen(false));
  };

  const editInvoiceInformationAction = (invoiceInformation: InvoiceInformation) => {
    setDrawerInvoiceInformation(true);
    setInvoiceInformationSelected(invoiceInformation);
  };

  const editSBExtraFieldsAction = (subscriptionBuyer: SubscriptionBuyer) => {
    setDrawerExtraFields(true);
    setSubscriptionBuyerSeleceted(subscriptionBuyer);
    setExtraFields(subscriptionBuyer.extra_fields);
  };

  const handleExtraFieldChange = (event: any, id: string, question: string) => {
    if (['rut', 'rut empresa'].includes(downcase(question))) {
      setExtraFields({ ...extraFields, [id]: formatRUT(event.target.value) });
    } else {
      setExtraFields({ ...extraFields, [id]: event.target.value });
    }
  };

  const updateSBExtraFields = () => {
    let submit = true;
    if (subscriptionBuyerSelected && extraFields) {
      Object.entries(extraFields).forEach(([key, value]) => {
        // now verify that if a key is "rut" or "rut" empresa it is a valid rut
        if (
          ['rut', 'rut empresa'].includes(
            downcase(subscriptionBuyerSelected?.subscription?.all_extra_fields[key])
          )
        ) {
          if (!validateRut(value)) {
            const name = subscriptionBuyerSelected?.subscription?.all_extra_fields[key];
            enqueueSnackbar(`${name} inválido`, { variant: 'error' });
            submit = false;
            return;
          }
        }
      });
      if (!submit) return;
      setLoading(true);
      sellerApi.subscriptionBuyers
        .updateExtraFields(subscriptionBuyerSelected.id, extraFields)
        .then((data: ApiObject<SubscriptionBuyer>) => {
          setSubscriptionBuyers((prevBuyers) =>
            prevBuyers.map((sb) => (sb.id === data.data.id ? data.data : sb))
          );
          enqueueSnackbar('Información actualizada exitosamente', { variant: 'success' });
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
          setSubscriptionBuyerSeleceted(undefined);
          setExtraFields(undefined);
          setDrawerExtraFields(false);
        });
    }
  };

  useEffect(() => {
    if (buyerId) {
      setLoading(true);
      sellerApi.buyers
        .show(buyerId)
        .then((data: ApiObject<Buyer>) => {
          dispatch(setBuyer(data.data));
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [buyerId]);

  useEffect(() => {
    setLoading(true);
    sellerApi.buyers
      .subscriptionBuyers(buyerId)
      .then((data: ApiList<SubscriptionBuyer>) => {
        setSubscriptionBuyers(data.data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [buyerId]);

  return (
    <Fragment>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Paper className={cStyles.infoPaper}>
            {/* HEADER */}
            <Grid container>
              <Grid item xs={12}>
                <div className={cStyles.paperHeader}>
                  <Avatar
                    className={cStyles.paperHeaderAvatar}
                    text={buyer?.name}
                    context="buyer"
                  />
                  <div className={cStyles.paperHeaderContent}>
                    <Typography variant="h5" className="secondary">
                      {buyer?.name}
                    </Typography>
                    <Typography variant="h6" className="secondary">
                      {buyer?.email}
                    </Typography>
                  </div>
                  <div className={cStyles.paperHeaderActions}>
                    <div className={`${cStyles.baseAction} ${cStyles.editAction}`}>
                      <IconButton
                        size="medium"
                        disabled={loading}
                        component={Link}
                        to={`/seller/buyers/${buyerId}/edit`}
                        className={`${cStyles.icon} ${cStyles.editIcon}`}
                      >
                        {loading ? <CircularProgress size={20} /> : <EditIcon />}
                      </IconButton>
                      <Typography variant="body2">Editar</Typography>
                    </div>
                    <div className={`${cStyles.baseAction} ${cStyles.deleteAction}`}>
                      <IconButton
                        size="medium"
                        disabled={loading}
                        onClick={() => setDeleteBuyerOpen(true)}
                        className={`${cStyles.icon} ${cStyles.deleteIcon}`}
                      >
                        {loading ? <CircularProgress size={20} /> : <TrashIcon />}
                      </IconButton>
                      <Typography variant="body2">Eliminar</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>

            {/* INFO */}
            <Grid container className={cStyles.infoContainer}>
              <Grid item xs={12} md={6}>
                <Typography variant="caption" fontSize={16}>
                  Nombre:
                </Typography>
                <Typography variant="h6">{buyer?.name || '-'}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption" fontSize={16}>
                  Correo:
                </Typography>
                <Typography variant="h6">{buyer?.email || '-'}</Typography>
              </Grid>
              {buyer?.shipping_address && (
                <div className={styles.rowsGroup}>
                  <Typography variant="caption" fontSize={18}>
                    Dirección de despacho
                  </Typography>
                  <div className={styles.wrapRow}>
                    <Grid item>
                      <Typography variant="caption" fontSize={16}>
                        Dirección:{' '}
                        <span>
                          <Typography variant="h6">{buyer?.shipping_address.address1}</Typography>
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" fontSize={16}>
                        Comuna:
                      </Typography>
                      <Typography variant="h6">{buyer?.shipping_address.commune}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" fontSize={16}>
                        Región:
                      </Typography>
                      <Typography variant="h6">{buyer?.shipping_address.region}</Typography>
                    </Grid>
                  </div>
                </div>
              )}
              {buyer?.invoice_informations && buyer?.invoice_informations.length > 0 && (
                <div className={styles.rowsGroup}>
                  <Typography variant="caption" fontSize={18} mb={1}>
                    Datos de facturación/boletas
                  </Typography>
                  {buyer.invoice_informations.map((information) => (
                    <div className={styles.editableRow} key={information.id}>
                      <div className={styles.wrapRow}>
                        <Grid item>
                          <Typography variant="caption" fontSize={16}>
                            Rut:{' '}
                            <span>
                              <Typography variant="h6">{information.rut}</Typography>
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" fontSize={16}>
                            Razón Social:
                          </Typography>
                          <Typography variant="h6">{information.business_name}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" fontSize={16}>
                            Giro:
                          </Typography>
                          <Typography variant="h6">{information.activity}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" fontSize={16}>
                            Dirección:
                          </Typography>
                          <Typography variant="h6">
                            {information.address
                              ? `${information.address}, ${information.commune}, ${information.region}.`
                              : ''}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" fontSize={16}>
                            Email contacto:
                          </Typography>
                          <Typography variant="h6">{information.email}</Typography>
                        </Grid>
                      </div>
                      <div className={styles.editIcon}>
                        <EditIcon
                          className={`${cStyles.icon} ${cStyles.editIcon}`}
                          onClick={() => editInvoiceInformationAction(information)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <Grid container item xs={12} mt={1}>
                {subscriptionBuyers
                  .filter(
                    (subs: SubscriptionBuyer) =>
                      Object.keys(subs.subscription?.all_extra_fields || {}).length > 0
                  )
                  .map((subs: SubscriptionBuyer) => (
                    <div className={styles.rowsGroup} key={subs.id}>
                      <Grid item xs={12}>
                        <Typography variant="overline">{subs.subscription?.name}</Typography>
                      </Grid>
                      <div className={styles.editableRow} key={subs.id}>
                        <div className={styles.wrapRow}>
                          {Object.keys(subs.subscription?.all_extra_fields || {}).map(
                            (key: string) => (
                              <Grid item key={key}>
                                <Typography variant="caption">
                                  {subs.subscription?.all_extra_fields[key]}:(
                                  {subs.subscription?.mandatory_extra_fields[key]
                                    ? 'Campo obligatorio'
                                    : 'Campo no obligatorio'}
                                  )
                                </Typography>
                                <Typography variant="h6">
                                  {subs.extra_fields[key] || '-'}
                                </Typography>
                              </Grid>
                            )
                          )}
                        </div>
                        <div className={styles.editIcon}>
                          <EditIcon
                            className={`${cStyles.icon} ${cStyles.editIcon}`}
                            onClick={() => editSBExtraFieldsAction(subs)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* SINGLE_PAYMENT */}
      <ResourceList
        title="Pagos únicos"
        queryFields="Nombre"
        getResourceList={sellerApi.buyers.singlePaymentBuyers}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.singlePayments.generateExcel('buyer', parentId, query, filterParams)
        }
        resourceParent={buyer}
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'value', label: 'Valor' },
          { key: 'state', label: 'Estado' },
        ]}
        listMobileHeaders={[{ key: 'name' }, { key: 'value' }, { key: 'state' }]}
        listColumns={{
          name: (res: SinglePaymentBuyer) => res.single_payment?.name,
          value: (res: SinglePaymentBuyer) =>
            formatCurrency(res.single_payment?.render_amount, res.single_payment?.currency),
          state: (res: SinglePaymentBuyer) => (
            <StatusLabel type="singlePaymentBuyer" status={res.status} />
          ),
        }}
        listActionsHeaders={() => [{ key: 'show', label: 'Ver servicio' }]}
        listActions={{
          show: (res: SinglePaymentBuyer) => {
            onItemSinglePayment(res.single_payment);
            history.push(`/seller/single_payments/${res.single_payment?.id}`);
          },
        }}
        filtersKey="buyer_show_single_payments"
      />

      {/* SUBSCRIPTIONS */}
      <ResourceList
        title="Suscripciones"
        queryFields="Nombre"
        getResourceList={sellerApi.buyers.subscriptionBuyers}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.subscriptions.generateExcel('buyer', parentId, query, filterParams)
        }
        resourceParent={buyer}
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'recurrence', label: 'Recurrencia' },
          { key: 'value', label: 'Valor' },
          { key: 'state', label: 'Estado' },
        ]}
        listMobileHeaders={[
          { key: 'name' },
          { key: 'recurrence' },
          { key: 'value' },
          { key: 'state' },
        ]}
        listColumns={{
          name: (res: SubscriptionBuyer) => res.subscription?.name,
          recurrence: (res: SubscriptionBuyer) => RECURRENCE_ES[res.subscription?.recurrence || ''],
          value: (res: SubscriptionBuyer) =>
            formatCurrency(res.subscription?.render_amount, res.subscription?.currency),
          state: (res: SubscriptionBuyer) => (
            <StatusLabel type="subscriptionBuyer" status={res.status} />
          ),
        }}
        listActionsHeaders={() => [{ key: 'show', label: 'Ver servicio' }]}
        listActions={{
          show: (res: SubscriptionBuyer) => {
            onItemSubscription(res.subscription);
            history.push(`/seller/subscriptions/${res.subscription?.id}`);
          },
        }}
        filtersKey="buyer_show_subscriptions"
      />

      {/* PAYMENT PLANS */}
      <ResourceList
        title="Planes de pago"
        queryFields="Nombre"
        getResourceList={sellerApi.buyers.paymentPlans}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.paymentPlans.generateExcel('buyer', parentId, query, filterParams)
        }
        resourceParent={buyer}
        listHeaders={[
          { key: 'productName', label: 'Nombre' },
          { key: 'buyerName', label: 'Nombre cliente' },
          { key: 'status', label: 'Estado' },
          { key: 'installments', label: 'Cuotas pagadas/cuotas totales' },
        ]}
        listMobileHeaders={[
          { key: 'productName' },
          { key: 'buyerName' },
          { key: 'status' },
          { key: 'installments' },
        ]}
        listColumns={{
          productName: (res) => res.product.name,
          buyerName: (res) => res.buyer.name,
          status: (res) => <StatusLabel status={res.status} type="paymentPlan" />,
          installments: (res) => `${res.total_paid_installments}/${res.total_installments}`,
        }}
        listActionsHeaders={() => [{ key: 'show', label: 'Ver servicio' }]}
        listActions={{
          show: (res: PaymentPlan) => {
            onItemPaymentPlan(res.product);
            history.push(`/seller/payment_plans/${res.id}`);
          },
        }}
      />

      {/* STATEMENTS */}
      <ResourceList
        title="Pagos"
        queryFields="Nombre servicio"
        getResourceList={sellerApi.buyers.statements}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.statements.statementsExcel('buyer', parentId, query, filterParams, true)
        }
        resourceParent={buyer}
        listHeaders={[
          { key: 'service_name', label: 'Servicio' },
          { key: 'amount', label: 'Monto' },
          { key: 'due_date', label: 'Fecha de vencimiento' },
          { key: 'payment_date', label: 'Fecha de pago' },
          { key: 'status', label: 'Estado' },
        ]}
        listMobileHeaders={[{ key: 'service_name' }, { key: 'due_date' }, { key: 'status' }]}
        listColumns={{
          service_name: (res: Statement) => res.service_name,
          amount: (res: Statement) => formatCurrency(res.amount),
          due_date: (res: Statement) => res.due_date,
          payment_date: (res: Statement) => res.payment_date || '-',
          status: (res: Statement) => <StatusLabel type="statement" status={res.status} />,
        }}
        getCollapseResources={sellerApi.statements.paymentTransactions}
        listCollapseHeaders={[
          { key: 'payment_date', label: 'Fecha' },
          { key: 'status', label: 'Estado' },
          { key: 'payment_method', label: 'Método de Pago' },
          { key: 'error', label: 'Descripción' },
        ]}
        listCollapseColumns={{
          payment_date: (res: PaymentTransaction) => res.payment_date,
          status: (res: PaymentTransaction) => (
            <StatusLabel type="paymentTransaction" status={res.status} />
          ),
          payment_method: (res: PaymentTransaction) => res.payment_method,
          error: (res: PaymentTransaction) =>
            res.status === 'accepted'
              ? 'Transacción aprobada'
              : res.error || 'Sin información del proveedor',
        }}
        listMobileCollapseHeaders={[
          { key: 'payment_date', label: 'Fecha' },
          { key: 'status', label: 'Estado' },
          { key: 'payment_method', label: 'Método de Pago' },
        ]}
        filtersKey="buyer_show_statements"
      />
      <PopUp
        state={{ open: openDeleteBuyer, setOpen: setDeleteBuyerOpen }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se eliminará el cliente si no tiene pagos asociados. Todas las inscripciones del cliente
            se eliminarán. Esta acción es irreversible. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={() => deleteBuyer()} color="error" variant="contained" key={1}>
            Si
          </Button>,
          <Button onClick={() => setDeleteBuyerOpen(false)} variant="outlined" key={2}>
            No
          </Button>,
        ]}
      />
      <Drawer
        anchor="right"
        open={drawerInvoiceInformation}
        onClose={() => setDrawerInvoiceInformation(false)}
      >
        <div className={styles.drawerContainer}>
          <div className={styles.drawerForm}>
            <Typography variant="h5" mb={1}>
              Editar datos de facturación
            </Typography>
            <div>
              <Typography>Estos datos se ocupan para los siguientes servicios:</Typography>
              {invoiceInformationSelected?.payables_name?.map((name, index) => (
                <Typography key={index}>
                  <li>{name}</li>
                </Typography>
              ))}
            </div>
            <form onSubmit={invoiceInformationFormik.handleSubmit} className={styles.drawerForm}>
              <Fragment>
                <Grid item xs={12} className={styles.drawerField} mt={2}>
                  <Typography>Rut</Typography>
                  <TextField
                    fullWidth
                    required
                    id="rut"
                    type="text"
                    variant="outlined"
                    value={invoiceInformationFormik.values.rut}
                    onChange={(e) =>
                      invoiceInformationFormik.setFieldValue('rut', formatRUT(e.target.value))
                    }
                    InputLabelProps={{ shrink: !!invoiceInformationFormik.values.rut }}
                    error={Boolean(invoiceInformationFormik.errors?.rut)}
                    helperText={invoiceInformationFormik.errors?.rut}
                  />
                </Grid>
                <Grid item xs={12} className={styles.drawerField}>
                  <Typography>Razón Social</Typography>
                  <TextField
                    fullWidth
                    required
                    id="business_name"
                    type="text"
                    autoComplete="business_name"
                    variant="outlined"
                    value={invoiceInformationFormik.values.business_name}
                    onChange={invoiceInformationFormik.handleChange}
                    error={
                      invoiceInformationFormik.touched.business_name &&
                      Boolean(invoiceInformationFormik.errors?.business_name)
                    }
                    helperText={
                      invoiceInformationFormik.touched.business_name &&
                      invoiceInformationFormik.errors?.business_name
                    }
                  />
                </Grid>
                <Grid item xs={12} className={styles.drawerField}>
                  <Typography>Giro</Typography>
                  <TextField
                    fullWidth
                    required
                    id="activity"
                    type="text"
                    autoComplete="activity"
                    variant="outlined"
                    value={invoiceInformationFormik.values.activity}
                    onChange={invoiceInformationFormik.handleChange}
                    error={
                      invoiceInformationFormik.touched.activity &&
                      Boolean(invoiceInformationFormik.errors?.activity)
                    }
                    helperText={
                      invoiceInformationFormik.touched.activity &&
                      invoiceInformationFormik.errors?.activity
                    }
                  />
                </Grid>
                <Grid item xs={12} className={styles.drawerField}>
                  <Typography>Región</Typography>
                  <TextField
                    fullWidth
                    required
                    select
                    id="region"
                    type="text"
                    autoComplete="region"
                    variant="outlined"
                    value={invoiceInformationFormik.values.region}
                    onChange={(event) =>
                      invoiceInformationFormik.setFieldValue('region', event.target.value)
                    }
                    error={
                      invoiceInformationFormik.touched.region &&
                      Boolean(invoiceInformationFormik.errors?.region)
                    }
                    helperText={
                      invoiceInformationFormik.touched.region &&
                      invoiceInformationFormik.errors?.region
                    }
                  >
                    {REGIONS.map((region) => (
                      <MenuItem key={region[0]} value={region[1]}>
                        {region[1]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} className={styles.drawerField}>
                  <Typography>Comuna</Typography>
                  <TextField
                    fullWidth
                    required
                    select
                    id="commune"
                    type="text"
                    autoComplete="commune"
                    variant="outlined"
                    value={invoiceInformationFormik.values.commune}
                    onChange={(event) =>
                      invoiceInformationFormik.setFieldValue('commune', event.target.value)
                    }
                    error={
                      invoiceInformationFormik.touched.commune &&
                      Boolean(invoiceInformationFormik.errors?.commune)
                    }
                    helperText={
                      invoiceInformationFormik.touched.commune &&
                      invoiceInformationFormik.errors?.commune
                    }
                  >
                    {invoiceInformationFormik.values.region ? (
                      COMMUNES_BY_REGION[invoiceInformationFormik.values.region]
                        ?.sort()
                        .map((commune) => (
                          <MenuItem key={commune} value={commune}>
                            {commune}
                          </MenuItem>
                        ))
                    ) : (
                      <MenuItem>Selecciona región</MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={12} className={styles.drawerField}>
                  <Typography>Dirección</Typography>
                  <TextField
                    fullWidth
                    required
                    id="address"
                    type="text"
                    autoComplete="address"
                    variant="outlined"
                    value={invoiceInformationFormik.values.address}
                    onChange={invoiceInformationFormik.handleChange}
                    error={
                      invoiceInformationFormik.touched.address &&
                      Boolean(invoiceInformationFormik.errors?.address)
                    }
                    helperText={
                      invoiceInformationFormik.touched.address &&
                      invoiceInformationFormik.errors?.address
                    }
                  />
                </Grid>
                <Grid item xs={12} className={styles.drawerField}>
                  <Typography>Email contacto</Typography>
                  <TextField
                    fullWidth
                    id="email"
                    type="text"
                    autoComplete="email"
                    variant="outlined"
                    value={invoiceInformationFormik.values.email}
                    onChange={invoiceInformationFormik.handleChange}
                    error={
                      invoiceInformationFormik.touched.email &&
                      Boolean(invoiceInformationFormik.errors?.email)
                    }
                    helperText={
                      invoiceInformationFormik.touched.email &&
                      invoiceInformationFormik.errors?.email
                    }
                  />
                </Grid>
              </Fragment>
              <div className={styles.actions}>
                <Button variant="outlined" onClick={() => setDrawerInvoiceInformation(false)}>
                  Cancelar
                </Button>
                <Button variant="contained" type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress style={{ height: '20px', width: '20px' }} />
                  ) : (
                    'Guardar'
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
      <Drawer anchor="right" open={drawerExtraFields} onClose={() => setDrawerExtraFields(false)}>
        <div className={styles.drawerContainer}>
          <div className={styles.drawerForm}>
            <Typography variant="h5" mb={1}>
              Actualizar campos extra de {subscriptionBuyerSelected?.subscription?.name}
            </Typography>
            {Object.entries(subscriptionBuyerSelected?.subscription?.all_extra_fields || {}).map(
              ([key, question]: [string, string]) => (
                <Grid item xs={12} className={styles.drawerField} key={key}>
                  <Typography>
                    {subscriptionBuyerSelected?.subscription?.all_extra_fields[key]}
                    {subscriptionBuyerSelected?.subscription?.mandatory_extra_fields[key]
                      ? '*'
                      : ''}
                  </Typography>
                  {subscriptionBuyerSelected?.subscription?.extra_fields_kind?.[key] ===
                  'selector' ? (
                    <TextField
                      fullWidth
                      id={key}
                      required={
                        subscriptionBuyerSelected?.subscription?.mandatory_extra_fields[key]
                      }
                      select
                      autoComplete={key}
                      variant="outlined"
                      value={extraFields?.[key]}
                      onChange={(event: any) => handleExtraFieldChange(event, key, question)}
                    >
                      {subscriptionBuyerSelected?.subscription?.extra_fields_options[key]
                        .split(',')
                        .map((option: string) => (
                          <MenuItem key={`${option}`} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </TextField>
                  ) : (
                    <TextField
                      fullWidth
                      id={key}
                      required={
                        subscriptionBuyerSelected?.subscription?.mandatory_extra_fields[key]
                      }
                      type="text"
                      autoComplete={key}
                      variant="outlined"
                      value={extraFields?.[key]}
                      onChange={(event: any) => handleExtraFieldChange(event, key, question)}
                    />
                  )}
                </Grid>
              )
            )}
            <div className={styles.actions}>
              <Button variant="outlined" onClick={() => setDrawerExtraFields(false)}>
                Cancelar
              </Button>
              <Button variant="contained" onClick={updateSBExtraFields} disabled={loading}>
                {loading ? (
                  <CircularProgress style={{ height: '20px', width: '20px' }} />
                ) : (
                  'Guardar'
                )}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default BuyerShow;
