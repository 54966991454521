import client from '../client';

export const check_status = (paymentId: number): Promise<any> => {
  return client({
    method: 'get',
    url: `/v1/public/mach/${paymentId}/check_status`,
  })
    .then(({ data: body }: { data: { data: any } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscribeError: error });
      throw error;
    });
};

export default {
  check_status,
};
