import React from 'react';

// Utils
import { setDocumentTitle } from '../../common/utils';

// Components
import { Container, Grid, Typography } from '@mui/material';

// Assets
import styles from './Client.module.scss';

export const ClientError = (): React.ReactElement => {
  setDocumentTitle('Error de conexión');
  return (
    <div className={styles.responseContainer}>
      <div className={styles.sellerContainer}>
        <div className={styles.topBar}>
          <a href="https://zafepay.com">
            <img
              src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_white.png"
              alt="Logo Zafepay"
              className={styles.opLogo}
            />
          </a>
        </div>
        <Container maxWidth="md" sx={{ mt: 5 }}>
          <Grid container>
            <Grid item xs={12} className={styles.text}>
              <img
                src="https://storage.googleapis.com/onlypays-public/assets/images/error_robot.svg"
                alt="imagen inactivo"
              />
              <Typography component="div">
                <p>
                  <strong>¡Lo sentimos!</strong>
                </p>
                <p>Ocurrió un error obteniendo los datos del servicio,</p>
                <p>Por favor comunícate con el vendedor para solucionarlo.</p>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
