// Card API
import * as paymentPlans from './paymentPlan';
import * as client from './client';
import * as webpayCards from './webpayCards';
import * as kushkiCards from './kushkiCards';
import * as cards from './cards';
import * as fintocSubscriptions from './fintocSubscriptions';
import * as subscriptionBuyers from './subscriptionBuyer';
import * as paymentTransactions from './paymentTransaction';
import * as companies from './company';
import * as singlePaymentBuyers from './singlePaymentBuyer';
import * as khipuSubscriptions from './khipuSubscriptions';
import * as etpaySubscriptions from './etpaySubscriptions';
import * as klapCards from './klapCards';

export {
  paymentPlans,
  client,
  cards,
  webpayCards,
  fintocSubscriptions,
  subscriptionBuyers,
  paymentTransactions,
  companies,
  singlePaymentBuyers,
  kushkiCards,
  khipuSubscriptions,
  etpaySubscriptions,
  klapCards,
};

export default {
  paymentPlans,
  client,
  cards,
  webpayCards,
  fintocSubscriptions,
  subscriptionBuyers,
  paymentTransactions,
  companies,
  singlePaymentBuyers,
  kushkiCards,
  khipuSubscriptions,
  etpaySubscriptions,
  klapCards,
};
