import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Typography, useMediaQuery, Box, CircularProgress } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from '../../../app/hooks';
import { ClientState } from '../clientSlice';
import { clientApi } from '../../../common/api';
import { formatCurrency } from '../../../common/utils';
import { ApiObject, Card, PAC, PaymentPlan } from '../../../app/type';

import styles from './Product.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import CardsPopup from '../components/CardsPopup';
import { hexToRgb, pluralInstallmentsWord } from '../utils';
import PaymentsPopup from '../components/PaymentsPopup';
import { useShopCartContext } from '../../../common/contexts/ShopCart';
import StatementsCart from '../components/StatementsCart';
import StatementsTable from '../components/StatementsTable';

interface SubscriptionBuyerShowProps {
  cards: Card[];
  PACs: PAC[];
}

const PaymentPlanShow = ({ cards, PACs }: SubscriptionBuyerShowProps): React.ReactElement => {
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [loading, setLoading] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openPaymentsPopup, setOpenPaymentsPopup] = useState<boolean>(false);

  const history = useHistory();
  const { client, company } = useSelector(({ client }: { client: ClientState }) => client);
  const { statementsCart, amountToPay } = useShopCartContext();
  const { paymentPlanId } = useParams<{ paymentPlanId: string }>();
  const [paymentPlan, setPaymentPlan] = useState<PaymentPlan>();
  const totalPlan =
    paymentPlan?.currency === 'UF' ? paymentPlan?.external_amount : paymentPlan?.amount;
  const pluralInstallments = pluralInstallmentsWord(paymentPlan?.total_installments);
  const debtCount = paymentPlan?.total_debt_installments;
  const pluralDebt = pluralInstallmentsWord(debtCount);
  const rgbColor = company?.payment_design?.background_color
    ? hexToRgb(company?.payment_design?.background_color)
    : null;
  const cardsBackgroundColor = rgbColor
    ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    : '#edeeff';

  const goBack = () => {
    history.goBack();
  };

  const showQueries = () => {
    clientApi.paymentPlans
      .show(paymentPlanId, client?.id || '')
      .then((data: ApiObject<PaymentPlan>) => {
        setPaymentPlan(data.data);
      })
      .catch(() => {
        history.replace('/client/error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    showQueries();
  }, []);

  return (
    <div className={`${styles.indexContainer} ${amountToPay > 0 && styles.amountToPayHeight}`}>
      {loading ? (
        <div className={styles.spinnerContainer}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress
              sx={{
                color: company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3',
              }}
            />
          </Box>
        </div>
      ) : (
        <Fragment>
          <div className={styles.backRow} onClick={goBack}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className={styles.icon}
              style={{
                color: company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3',
              }}
            />
          </div>
          <div className={styles.headerRow}>
            <div className={styles.icon}>
              <div
                className={styles.buildingIconCircle}
                style={{
                  backgroundColor: company?.payment_design?.background_color
                    ? company?.payment_design?.background_color
                    : '#4653e3',
                }}
              >
                <FontAwesomeIcon icon={faBoxOpen} className={styles.buildingIcon} />
              </div>
            </div>
            <div>
              <div className={styles.planName}>
                <Typography>{paymentPlan?.product.name}</Typography>
              </div>
              {company?.automatic_payment_methods && (
                <div className={styles.paymentRow}>
                  <div>
                    <Typography className={styles.title}>Tarjeta inscrita</Typography>
                    <Typography className={styles.name}>
                      {paymentPlan?.card
                        ? `XXXX XXXX XXXX ${paymentPlan?.card.last_four_digits}`
                        : paymentPlan?.pac
                        ? `${
                            paymentPlan?.pac.account_type || 'PAC'
                          } •••• ${paymentPlan?.pac.account_number.slice(-4)}`
                        : 'Sin medio de pago inscrito'}
                    </Typography>
                  </div>
                  {!isMobile && (
                    <div
                      className={styles.editButton}
                      onClick={() => setOpenPopup(true)}
                      style={{
                        color: company?.payment_design?.background_color
                          ? company?.payment_design?.background_color
                          : '#4653e3',
                        borderColor: company?.payment_design?.background_color
                          ? company?.payment_design?.background_color
                          : '#4653e3',
                      }}
                    >
                      <Typography>Editar medio de pago</Typography>
                    </div>
                  )}
                </div>
              )}
              {isMobile && company?.automatic_payment_methods && (
                <div
                  className={styles.editButton}
                  onClick={() => setOpenPopup(true)}
                  style={{
                    color: company?.payment_design?.background_color
                      ? company?.payment_design?.background_color
                      : '#4653e3',
                    borderColor: company?.payment_design?.background_color
                      ? company?.payment_design?.background_color
                      : '#4653e3',
                  }}
                >
                  <Typography>Editar medio de pago</Typography>
                </div>
              )}
            </div>
          </div>
          <div className={styles.installmentsCard}>
            <div
              className={`${styles.installmentsBox} ${styles.showInstallmentsBox}`}
              style={{
                background: cardsBackgroundColor,
              }}
            >
              <div className={styles.leftCol}>
                <div>
                  <Typography className={styles.debtTitle}>{`Total: ${formatCurrency(
                    totalPlan,
                    paymentPlan?.currency
                  )}`}</Typography>
                  <div className={styles.installmentsRow}>
                    <Typography
                      className={styles.number}
                    >{`${paymentPlan?.total_paid_installments}/${paymentPlan?.total_installments} ${pluralInstallments}`}</Typography>
                    {isMobile && debtCount && debtCount > 0 ? (
                      <div className={styles.adviceFilled}>
                        <Typography>{`${debtCount} ${pluralDebt} en deuda${
                          debtCount > 1 ? 's' : ''
                        }`}</Typography>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              {!isMobile && debtCount && debtCount > 0 ? (
                <div className={styles.adviceFilled}>
                  <Typography>{`${debtCount} ${pluralDebt} en deuda${
                    debtCount > 1 ? 's' : ''
                  }`}</Typography>
                </div>
              ) : (
                <></>
              )}
            </div>
            <StatementsTable isPaymentPlan={true} productId={paymentPlanId} />
          </div>
          {paymentPlan && (
            <Fragment>
              <CardsPopup
                state={{ open: openPopup, setOpen: setOpenPopup }}
                projectName={paymentPlan.product.name}
                card={paymentPlan.card?.id}
                productId={paymentPlan.id}
                show={true}
                isPaymentPlan={true}
                cards={cards}
                PACs={PACs}
              />
              <PaymentsPopup
                state={{ open: openPaymentsPopup, setOpen: setOpenPaymentsPopup }}
                amount={amountToPay}
                statements={statementsCart}
                cards={cards}
              />
            </Fragment>
          )}
          {amountToPay > 0 && <StatementsCart setOpenPaymentsPopup={setOpenPaymentsPopup} />}
        </Fragment>
      )}
    </div>
  );
};

export default PaymentPlanShow;
