// React, packages and api
import React, { useRef, useEffect, useState } from 'react';
import { sellerApi } from '../../../common/api';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// Slice and utils
import { useSelector, useDispatch } from '../../../app/hooks';
import { SellerState, setSinglePayment } from '../sellerSlice';
import { formatCurrency, setDocumentTitle } from '../../../common/utils';

// MUI
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// Styles and assets
import { faEye, faBellSlash, faBell, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import styles from './Index.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import { Briefcase as BriefcaseIcon, Copy as CopyIcon } from 'react-feather';

// Componentes
import InfoBox from '../../../common/components/InfoBox';
import Stepper from '../../../common/components/Stepper';
import ResourceList from '../../../common/components/ResourceList';
import StatusLabel from '../../../common/components/StatusLabel';
import PopUp from '../../../common/components/PopUp';

// Types
import { SingleDashboardData, SinglePayment } from '../../../app/type';

const SinglePaymentsIndex = (): React.ReactElement => {
  setDocumentTitle('Pagos Únicos');
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const [sellerData, setSellerdata] = useState<SingleDashboardData>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const reloadRef = useRef<{ reloadResource: () => void }>();
  const [activateDialogOpen, setActivateDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [singlePaymentRes, setSinglePaymentRes] = useState<SinglePayment | null>(null);
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);

  useEffect(() => {
    if (!company) {
      history.replace('/seller/home');
    } else {
      sellerApi.companies
        .singleDashboard(company.id)
        .then((data) => {
          setSellerdata(data.data);
        })
        .catch(console.error);
    }
  }, [company]);

  const softDelete = (res: SinglePayment | null) => {
    if (res) {
      sellerApi.singlePayments
        .destroySinglePayment(res?.id)
        .then(() => {
          enqueueSnackbar('Pago único eliminado', { variant: 'success' });
          reloadRef?.current?.reloadResource?.();
        })
        .catch((error) => {
          if (error.response.status === 403) {
            enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
              variant: 'error',
            });
          } else if (error.response.status === 400) {
            enqueueSnackbar('El pago único no puede ser eliminado por tener pagos completados', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Ocurrió un error al eliminar el pago único', { variant: 'error' });
          }
        });
    }
  };

  const changeStatus = (res: SinglePayment | null, resStatus: string | null) => {
    if (res && resStatus) {
      sellerApi.singlePayments
        .change_status(res?.id, resStatus)
        .then(() => {
          enqueueSnackbar('Suscripción actualizada', { variant: 'success' });
          reloadRef?.current?.reloadResource?.();
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error al actualizar el pago único', { variant: 'error' });
        });
    }
  };

  const openActivateDialog = (res: SinglePayment) => {
    setActivateDialogOpen(true);
    setSinglePaymentRes(res);
  };

  const closeActivateDialog = () => {
    changeStatus(singlePaymentRes, singlePaymentRes?.status == 'active' ? 'inactive' : 'active');
    setActivateDialogOpen(false);
  };

  const openDeleteDialog = (res: SinglePayment) => {
    setDeleteDialogOpen(true);
    setSinglePaymentRes(res);
  };

  const closeDeleteDialog = () => {
    softDelete(singlePaymentRes);
    setDeleteDialogOpen(false);
  };

  const createLink = () => {
    history.push('single_payments/new');
  };

  const boxes = (width?: number) => [
    <InfoBox
      key={1}
      title="RECAUDADO EN EL MES"
      data={formatCurrency(sellerData?.month_single_recaudation || 0)}
      kind="info1"
      style={styles.firstInfoContainer}
      icon={<BriefcaseIcon />}
      width={width}
    />,
    <InfoBox
      key={3}
      title="Crear Pago Único"
      function={createLink}
      kind="link"
      image="https://storage.googleapis.com/onlypays-public/assets/images/young%20man%20in%20headset%20using%20computer.svg"
      style={styles.linkContainer}
      width={width}
    />,
  ];

  return (
    <>
      <div className={styles.start}>
        <Typography variant={isMobile ? 'h5' : 'h4'}>
          <CopyIcon />
          <span className={styles.startTitle}>Pagos Únicos</span>
        </Typography>
        <Typography variant="body1">
          Los <b>pagos únicos</b> se cobran solo una vez.
        </Typography>
      </div>

      {isMobile ? (
        <Button
          className={styles.toButton}
          onClick={() => history.push('single_payments/new')}
          variant="contained"
        >
          Crear Pago Único
        </Button>
      ) : null}

      {isMobile ? (
        <Stepper assets={boxes(12).slice(0, -1)} />
      ) : (
        <Grid container className={styles.containerGrid} flexWrap={'nowrap'}>
          {boxes().map((box) => box)}
        </Grid>
      )}

      <ResourceList
        title="Pagos Únicos"
        queryFields="Nombre"
        setResource={setSinglePayment}
        getResourceList={sellerApi.singlePayments.list}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.singlePayments.generateExcel('company', parentId, query, filterParams)
        }
        resourceParent={company}
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'value', label: 'Valor' },
          { key: 'status', label: 'Estado' },
          { key: 'recieved_payments', label: 'Pagos Recibidos' },
          { key: 'pending_payments', label: 'Pagos Pendientes' },
        ]}
        listMobileHeaders={[{ key: 'name' }, { key: 'status' }, { key: 'value' }]}
        listColumns={{
          name: (res: SinglePayment) => res.name,
          value: (res: SinglePayment) => formatCurrency(res.render_amount, res.currency),
          status: (res: SinglePayment) => <StatusLabel status={res.status} type="singlePayment" />,
          recieved_payments: (res: SinglePayment) => res.recieved_single_payments,
          pending_payments: (res: SinglePayment) => res.pending_single_payments,
        }}
        listActionsHeaders={(res?: SinglePayment) => {
          if (res)
            return [
              { key: 'show', label: 'Ver Pago Único', icon: faEye },
              res.status == 'active'
                ? { key: 'change_status', label: 'Terminar', icon: faBellSlash }
                : { key: 'change_status', label: 'Activar', icon: faBell },
              { key: 'delete', label: 'Eliminar', icon: faTrashCan, color: 'error' },
            ];
          return [null];
        }}
        listActions={{
          show: (res: SinglePayment) => {
            dispatch(setSinglePayment(res));
            history.push(`/seller/single_payments/${res.id}`);
          },
          delete: (res: SinglePayment) => openDeleteDialog(res),
          change_status: (res: SinglePayment) =>
            res.status == 'active' ? openActivateDialog(res) : changeStatus(res, 'active'),
        }}
        innerRef={reloadRef}
        filtersKey="single_payments_index"
        persistFilters
      />
      <PopUp
        state={{ open: deleteDialogOpen, setOpen: setDeleteDialogOpen }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se eliminará el pago único. Todas las inscripciones a este pago único se eliminarán.
            Esta acción es irreversible. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={closeDeleteDialog} color="error" variant="contained" key={1}>
            Si
          </Button>,
          <Button onClick={() => setDeleteDialogOpen(false)} variant="outlined" key={2}>
            No
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: activateDialogOpen, setOpen: setActivateDialogOpen }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se cancelarán todas las suscripciones de este servicio y se eliminarán todas las cuotas
            que no se han pagado. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={closeActivateDialog} color="error" variant="contained" key={1}>
            Si
          </Button>,
          <Button onClick={() => setActivateDialogOpen(false)} variant="outlined" key={2}>
            No
          </Button>,
        ]}
      />
    </>
  );
};

export default SinglePaymentsIndex;
