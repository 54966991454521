import React from 'react';

// Components
import ResourceList from '../../common/components/ResourceList';

// API
import { backofficeApi } from '../../common/api';

// Types
import { ResponseStatement } from '../../app/type';

// Utils
import { formatCurrency } from '../../common/utils';

// State
import { setResponseStatement } from './backofficeSlice';

const Statements = (): React.ReactElement => {
  return (
    <ResourceList
      title="Pagos"
      queryFields="Producto, Empresa"
      getResourceList={(_id, query, _, filterParams) =>
        backofficeApi.statements(query, filterParams)
      }
      listHeaders={[
        process.env.NODE_ENV === 'development' ? { key: 'id', label: 'ID del pago' } : null,
        { key: 'amount', label: 'Monto' },
        { key: 'bank_commission', label: 'Comisión medio de pago' },
        { key: 'payment_method', label: 'Medio de pago' },
        { key: 'company_name', label: 'Empresa' },
        { key: 'product_name', label: 'Producto' },
        { key: 'due_date', label: 'Fecha de vencimiento' },
        { key: 'payment_date', label: 'Fecha de pago' },
      ]}
      listMobileHeaders={[
        process.env.NODE_ENV === 'development' ? { key: 'id' } : null,
        { key: 'amount' },
        { key: 'company_name' },
        { key: 'product_name' },
      ]}
      listColumns={{
        id: (res: ResponseStatement) => res.id.slice(0, 8),
        product_name: (res: ResponseStatement) => res.product?.name,
        company_name: (res: ResponseStatement) => res.company?.name,
        amount: (res: ResponseStatement) => formatCurrency(res.amount),
        bank_commission: (res: ResponseStatement) => formatCurrency(res.to_invoice),
        due_date: (res: ResponseStatement) => res.due_date,
        payment_date: (res: ResponseStatement) => res.payment_date,
        payment_method: (res: ResponseStatement) => res.payment_type,
      }}
      resourceParent={{ id: 'none' }}
      setResource={setResponseStatement}
      defaultRoute="backoffice/dashboard"
    />
  );
};

export default Statements;
