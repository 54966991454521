import React from 'react';

export const PersistLoader = (): React.ReactElement => {
  const containerStyle = {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const logoStyle = {
    width: '25vw',
    maxWidth: '300px',
    height: 'auto',
    margin: 'auto',
  };
  return (
    <div style={containerStyle}>
      <img
        style={logoStyle}
        src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_white.png"
        alt="Logo Zafepay"
      />
    </div>
  );
};

export default PersistLoader;
