import * as React from 'react';
import { createContext } from 'react';
import { CartContext, Statement } from '../../app/type';

const ShopCartContext = createContext<CartContext | null>(null);

export const useShopCartContext = (): CartContext => {
  return React.useContext(ShopCartContext) as CartContext;
};

const ShopCartProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [statementsCart, setStatementsCart] = React.useState<string[]>([]);
  const [fullStatementsCart, setFullStatementsCart] = React.useState<Statement[]>([]);
  const [amountToPay, setAmountToPay] = React.useState<number>(0);

  const addStatementsCart = (statement: Statement): void => {
    setStatementsCart([...statementsCart, statement.id]);
    setFullStatementsCart([...fullStatementsCart, statement]);
    setAmountToPay(amountToPay + statement.amount);
  };

  const removeStatementsCart = (statement: Statement) => {
    setStatementsCart(statementsCart.filter((id: string) => id !== statement.id));
    setFullStatementsCart(
      fullStatementsCart.filter((saved_statement: Statement) => saved_statement.id !== statement.id)
    );
    setAmountToPay(amountToPay - statement.amount);
  };

  const cleanStatementsCart = () => {
    setStatementsCart([]);
    setFullStatementsCart([]);
    setAmountToPay(0);
  };

  return (
    <ShopCartContext.Provider
      value={{
        statementsCart,
        addStatementsCart,
        removeStatementsCart,
        amountToPay,
        cleanStatementsCart,
        fullStatementsCart,
      }}
    >
      {children}
    </ShopCartContext.Provider>
  );
};

export default ShopCartProvider;
