import React, { Fragment, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  Typography,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  Grid,
  GridSize,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from '@mui/material';
import { Header, Renderers } from './ResourceListTypes';
import { ApiList } from '../../app/type';

import styles from '../styles/components/BoxList.module.scss';
import variables from '../styles/variables.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faEllipsis,
  IconDefinition,
  faList,
} from '@fortawesome/free-solid-svg-icons';

interface BoxListProps<T, R> {
  resources: any[];
  headers?: ({ key: string } | null)[];
  listActionsHeaders?: (res?: T) => ({
    key?: string;
    label?: string;
    icon?: IconDefinition;
    color?: 'error' | 'success' | 'warning' | 'info';
  } | null)[];
  listActions?: { [key: string]: (object: T) => void };
  loading?: boolean;
  columns?: Renderers<T>;
  alternativeStyle?: string;
  getCollapseResources?: (resourceId: string) => Promise<ApiList<R>>;
  listCollapseHeaders?: (Header | null)[];
  listCollapseColumns?: Renderers<R>;
}
export const BoxList = <T, R>(props: BoxListProps<T, R>): React.ReactElement => {
  const defaultRender = (key: string) => (res: any) => res[key] || '-';
  const [menuEl, setMenuEl] = useState<{ [key: string]: any }>({});
  const openMenu = (key: string, event: any) => setMenuEl({ [key]: event?.currentTarget });
  const closeMenu = () => setMenuEl({});
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [collapseResources, setCollapseResources] = useState<R[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [collapseEl, setCollapseEl] = useState<{ [key: string]: any }>({});
  const { enqueueSnackbar } = useSnackbar();

  const cellRenders = useMemo((): Renderers<T> => {
    const filtered = props.headers?.filter((h) => h) as { key: string }[];
    return filtered.reduce((object: Renderers<T>, header: { key: string }) => {
      let renderer = defaultRender(header.key);
      if (props.columns?.[header.key]) renderer = props.columns[header.key];
      return { ...object, [header.key]: renderer };
    }, {});
  }, [props.headers]);

  const onItemSelect = (key: string, res: T) => () => {
    closeMenu();
    if (props.listActions) props.listActions[key]?.(res);
  };

  const openCollapsedTable = async (res: T & { id?: string }) => {
    if (res.id && props.getCollapseResources) {
      setLoading(true);
      await props
        .getCollapseResources(res.id)
        .then((data: ApiList<R>) => {
          setCollapseResources(data.data);
          res.id && setCollapseEl({ [res.id]: true });
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error cargando los pagos', { variant: 'error' });
          console.error;
        })
        .finally(() => {
          closeMenu();
          setLoading(false);
        });
    }
  };

  const collapseHeadersToUse = useMemo((): Header[] => {
    return (props.listCollapseHeaders || []).filter((h) => h) as Header[];
  }, [props.listCollapseHeaders]);

  const collapseCellRenders = useMemo((): Renderers<T> => {
    const filtered = (props.listCollapseHeaders || []).filter((h) => h) as Header[];
    return filtered.reduce((hash: Renderers<T>, header: Header) => {
      let renderer = defaultRender(header.key);
      if (props.listCollapseColumns?.[header.key])
        renderer = (props.listCollapseColumns || {})[header.key];
      return { ...hash, [header.key]: renderer };
    }, {});
  }, [props.listCollapseHeaders]);

  const filteredOptions = (res: T) => {
    if (props.listActionsHeaders)
      return props.listActionsHeaders(res).filter((o) => o?.key && o?.label) as Header[];
  };

  const presentShow = (res: T): boolean | undefined => {
    return filteredOptions(res)
      ?.map((header) => Object.values(header).includes('show'))
      .includes(true);
  };

  return (
    <div className={props.alternativeStyle || styles.mainDiv}>
      {props.loading ? (
        <Box className="emptyBox">
          <Typography variant="h6">¡Estamos cargando los resultados!</Typography>
        </Box>
      ) : props.resources.length === 0 ? (
        <Box className="emptyBox">
          <Typography variant="h6">¡Ups! No hemos encontrado nada.</Typography>
        </Box>
      ) : null}
      {props.resources.map((res: any, index: number) => (
        <Fragment key={index}>
          <Box className="mainBox">
            <div className="infoContainer">
              {isMobile && (
                <Typography variant={'subtitle1'} className="title">
                  <b>{cellRenders['name']?.(res)}</b>
                </Typography>
              )}
              <div className="content">
                <Grid container>
                  {Object.keys(cellRenders).map((objectKey, index) => {
                    if (objectKey == 'name' && isMobile) return null;
                    return (
                      <Fragment key={index}>
                        <Grid
                          item
                          xs={!isMobile && ((12 / Object.keys(cellRenders).length) as GridSize)}
                          style={{ margin: 'auto', marginLeft: '0px' }}
                        >
                          {index !== 1 && <Divider orientation="vertical" />}
                          <Typography variant={'body1'} className="title">
                            {cellRenders[objectKey]?.(res)}
                          </Typography>
                        </Grid>
                      </Fragment>
                    );
                  })}
                </Grid>
                {filteredOptions(res)?.length ? (
                  <>
                    <Divider orientation="vertical" />
                    <IconButton
                      className="ellipsis"
                      onClick={(event: any) => openMenu(res.id, event)}
                    >
                      <FontAwesomeIcon icon={faEllipsis} />
                    </IconButton>
                  </>
                ) : null}
                <Menu
                  anchorEl={menuEl[res.id]}
                  open={Boolean(menuEl[res.id])}
                  onClose={closeMenu}
                  className="menu"
                >
                  {filteredOptions(res)?.length === 0 && (
                    <MenuItem disabled>No hay acciones disponibles</MenuItem>
                  )}
                  {props.listCollapseHeaders && (
                    <div>
                      <MenuItem onClick={() => openCollapsedTable(res)}>
                        {loading ? (
                          <CircularProgress size={20} style={{ marginRight: '10px' }} />
                        ) : (
                          <FontAwesomeIcon icon={faList} style={{ marginRight: '10px' }} />
                        )}
                        Desplegar Pagos
                      </MenuItem>
                      <Divider style={{ margin: 0 }} />
                    </div>
                  )}
                  {filteredOptions(res)?.map((option, index) => {
                    return (
                      <div key={option.key}>
                        {index === 0 ? null : <Divider />}
                        <MenuItem
                          onClick={onItemSelect(option.key, res)}
                          sx={{ color: `${variables[option.color || '']}` }}
                        >
                          {option.icon && (
                            <FontAwesomeIcon icon={option.icon} style={{ marginRight: '10px' }} />
                          )}
                          {option.label}
                        </MenuItem>
                      </div>
                    );
                  })}
                </Menu>
              </div>
            </div>
            {presentShow(res) ? (
              <div className="moreInfo" onClick={onItemSelect('show', res)}>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            ) : null}
          </Box>
          <div className={`collapse ${collapseEl[res.id] && 'openCollapse'}`}>
            {
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {collapseHeadersToUse.map((header: Header) => (
                      <TableCell key={header.key}>{header.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {collapseResources.map((resource: any) => (
                    <TableRow
                      key={resource.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {collapseHeadersToUse.map((header: Header) => (
                        <TableCell key={header.key}>
                          {collapseCellRenders[header.key]?.(resource)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            }
          </div>
        </Fragment>
      ))}
    </div>
  );
};
export default BoxList;
