export const NUMBERS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28,
];

export const WEEKDAYS = [
  [1, 'Lunes'],
  [2, 'Martes'],
  [3, 'Miércoles'],
  [4, 'Jueves'],
  [5, 'Viernes'],
  [6, 'Sábado'],
  [7, 'Domingo'],
];

const MONTHS = [
  [1, 'Enero'],
  [2, 'Febrero'],
  [3, 'Marzo'],
  [4, 'Abril'],
  [5, 'Mayo'],
  [6, 'Junio'],
  [7, 'Julio'],
  [8, 'Agosto'],
  [9, 'Septiembre'],
  [10, 'Octubre'],
  [11, 'Noviembre'],
  [12, 'Diciembre'],
];

export const MONTH_WITH_IDS_ES = [
  { id: 1, label: 'Enero' },
  { id: 2, label: 'Febrero' },
  { id: 3, label: 'Marzo' },
  { id: 4, label: 'Abril' },
  { id: 5, label: 'Mayo' },
  { id: 6, label: 'Junio' },
  { id: 7, label: 'Julio' },
  { id: 8, label: 'Agosto' },
  { id: 9, label: 'Septiembre' },
  { id: 10, label: 'Octubre' },
  { id: 11, label: 'Noviembre' },
  { id: 12, label: 'Diciembre' },
];

const DATE_UNITS = [
  ['days', 'día'],
  ['weeks', 'semana'],
  ['months', 'mes'],
  ['years', 'año'],
];

const PLURAL_DATE_UNITS = [
  ['days', 'días'],
  ['weeks', 'semanas'],
  ['months', 'meses'],
  ['years', 'años'],
];

export const WEEKDAYS_ES = Object.fromEntries(WEEKDAYS);
export const MONTHS_ES = Object.fromEntries(MONTHS);
export const DATE_UNITS_ES = Object.fromEntries(DATE_UNITS);
export const PLURAL_DATE_UNITS_ES = Object.fromEntries(PLURAL_DATE_UNITS);
