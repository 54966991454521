import { Invoice, ApiList } from '../../../app/type';

import client from '../client';

export const list = (
  companyId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<Invoice>> => {
  return client({
    method: 'get',
    url: `/v1/invoices`,
    params: { id: companyId, query, page: page || 1, filter_params: filterParams },
  })
    .then(({ data: body }: { data: ApiList<Invoice> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export default { list };
