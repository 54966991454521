import React, { Fragment, useEffect } from 'react';
import { SellerState, setPaymentPlan } from '../sellerSlice';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from '../../../app/hooks';
import { useSnackbar } from 'notistack';

import { ApiObject, PaymentPlan } from '../../../app/type';
import { sellerApi } from '../../../common/api';

import { Typography, Paper } from '@mui/material';

import styles from './Index.module.scss';

import PaymentPlanForm from './form';

const Edit = (): React.ReactElement => {
  const { paymentPlan } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const dispatch = useDispatch();
  const history = useHistory();
  const { paymentPlanId } = useParams<{ paymentPlanId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const onSuccess = (data: ApiObject<PaymentPlan>) => {
    enqueueSnackbar('Plan de pago guardado exitosamente', { variant: 'success' });
    dispatch(setPaymentPlan(data.data));
    history.push(`/seller/payment_plans/${data.data.id}`);
  };

  useEffect(() => {
    if (paymentPlanId !== paymentPlan?.id && paymentPlanId) {
      sellerApi.paymentPlans
        .show(paymentPlanId)
        .then((data: ApiObject<PaymentPlan>) => {
          dispatch(setPaymentPlan(data.data));
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
        });
    }
  }, [paymentPlanId, paymentPlan]);

  return (
    <Fragment>
      <div style={{ maxWidth: '90%', margin: '2%' }}>
        <div className={styles.paperHeader}>
          <Typography variant="h5">{paymentPlanId ? 'Editar' : 'Crear'} plan de pago</Typography>
        </div>
        <div>
          <Paper className={styles.infoPaper}>
            <PaymentPlanForm initialData={paymentPlan} onSuccess={onSuccess} />
          </Paper>
        </div>
      </div>
    </Fragment>
  );
};

export default Edit;
