import React, { useEffect, useState } from 'react';

import { Grid, MenuItem, TextField, Typography, useMediaQuery } from '@mui/material';

import { Truck } from 'react-feather';
import styles from './styles.module.scss';
import { formatCLP } from '../../../../common/utils';

import { Payable } from '../../../../app/type';

interface ComponentProps {
  payable?: Payable;
  customRecurrenceProps?: {
    selectedRecurrence: string | null;
    setSelectedRecurrence: (arg: string | null) => void;
    amount: number | null;
    setAmount: (arg: number | null) => void;
    errors: { [key: string]: string | null };
  };
  shippingCost: number | null;
  loadingShippingCost: boolean;
  productsToSubscribe?: string[];
}

const DeliveryAndRecurrence = (props: ComponentProps): React.ReactElement => {
  const isTablet = useMediaQuery(`(max-width: 1330px)`);
  const [companyColor, setCompanyColor] = useState<string>('#4653E3');

  const applyDynamicBorder = (element: HTMLElement) => {
    element.style.setProperty('--dynamic-border-color', companyColor);
  };

  const removeDynamicBorder = (element: HTMLElement) => {
    element.style.setProperty('--dynamic-border-color', companyColor);
  };

  useEffect(() => {
    if (props.payable) {
      setCompanyColor(
        props.payable?.company.payment_design
          ? props.payable?.company.payment_design.background_color
          : '#4653E3'
      );
    }
  }, [props.payable]);

  return (
    <div className={styles.priceFooter}>
      <div className={styles.dispatch}>
        <Truck
          style={{
            color: props.payable?.company.payment_design
              ? props.payable?.company.payment_design?.background_color
              : '#4653E3',
          }}
        />
        <Typography
          style={{
            color: props.payable?.company.payment_design
              ? props.payable?.company.payment_design?.background_color
              : '#4653E3',
          }}
        >
          <span className={styles.cursive}>&nbsp;Tu costo de envío será de:</span>{' '}
          <b>
            {typeof props.shippingCost === 'number'
              ? props.loadingShippingCost
                ? 'Cargando...'
                : formatCLP(props.shippingCost)
              : '...'}
          </b>
        </Typography>
      </div>
      {(props.productsToSubscribe === undefined || props.productsToSubscribe.length > 0) && (
        <div className={styles.summary}>
          <Typography
            style={{
              color: props.payable?.company.payment_design
                ? props.payable?.company.payment_design?.background_color
                : '#4653E3',
            }}
            variant="h3"
          >
            <b>Resumen de pago recurrente</b>
          </Typography>
          <Typography variant="subtitle1">
            Recuerda que estos productos se te enviarán según la <b>recurrencia</b> que escojas.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={isTablet ? 7 : 6}>
              <Typography>Recurrencia del pago</Typography>
              <TextField
                select
                fullWidth
                value={props.customRecurrenceProps?.selectedRecurrence || ''}
                onChange={(event) => {
                  props.customRecurrenceProps?.setSelectedRecurrence(event.target.value);
                  event.target.value !== 'custom' && props.customRecurrenceProps?.setAmount(null);
                }}
                defaultValue={props.payable?.recurrence}
                error={Boolean(props.customRecurrenceProps?.errors.recurrence)}
                helperText={props.customRecurrenceProps?.errors.recurrence}
                onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                onBlur={(e) => removeDynamicBorder(e.currentTarget)}
              >
                {props.payable?.payment_link_configuration?.translated_payment_frequency.map(
                  (frequency) =>
                    frequency === 'Puntual' ? null : (
                      <MenuItem key={frequency} value={frequency}>
                        {frequency}
                      </MenuItem>
                    )
                )}
              </TextField>
            </Grid>
            {props.customRecurrenceProps?.selectedRecurrence === 'Personalizada' ? (
              <Grid item xs={isTablet ? 5 : 6}>
                <Typography>Días</Typography>
                <TextField
                  fullWidth
                  type="number"
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    const string = event.target.value.toString().replace('-', '').replace('.', '');
                    props.customRecurrenceProps?.setAmount(parseInt(string));
                  }}
                  value={props.customRecurrenceProps?.amount}
                  error={Boolean(props.customRecurrenceProps?.errors.amount)}
                  helperText={props.customRecurrenceProps?.errors.amount}
                  onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                  onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                  onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                  onBlur={(e) => removeDynamicBorder(e.currentTarget)}
                  InputProps={{ inputProps: { min: 7, max: 90 } }}
                />
              </Grid>
            ) : null}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default DeliveryAndRecurrence;
