import { Card, Container } from '@mui/material';
import React, { useRef } from 'react';
import Recaptcha from '../../common/components/Recaptcha';

import GoogleLogin from './GoogleLogin';

import styles from './Login.module.scss';

const BackofficeLogin = (): React.ReactElement => {
  const recaptchaRef = useRef<{ execute: () => Promise<string> }>();

  return (
    <Container maxWidth="xs" className={styles.container}>
      <Recaptcha innerRef={recaptchaRef} />
      <Card className={styles.card}>
        Zafepay Backoffice
        <GoogleLogin backoffice={true} />
      </Card>
    </Container>
  );
};

export default BackofficeLogin;
