import { ApiList, ApiObject, GenericObject, PAC } from '../../../app/type';

import client from '../client';

export const createPAC = (
  clientId: string,
  paymentPlanId?: string,
  rejectedCardId?: string,
  productType?: string
): Promise<ApiObject<GenericObject>> => {
  return client({
    method: 'post',
    url: `/v1/client/khipu_subscriptions`,
    params: {
      client_id: clientId,
      product_id: paymentPlanId,
      product_type: productType,
      rejected_card_id: rejectedCardId,
    },
  })
    .then(({ data: body }: { data: ApiObject<GenericObject> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const list = (clientId: string, params?: { [key: string]: any }): Promise<ApiList<PAC>> => {
  return client({
    method: 'get',
    url: `/v1/client/khipu_subscriptions`,
    params: { client_id: clientId, ...params },
  })
    .then(({ data: body }: { data: ApiList<PAC> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
