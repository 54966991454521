import React from 'react';

import { sellerApi } from '../../../common/api';
import { Company } from '../../../app/type';
import variables from '../../../common/styles/variables.module.scss';

import ResourceList from '../../../common/components/ResourceList';

import { useMediaQuery } from '@mui/material';

import { formatCurrency } from '../../../common/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

export interface InvoicesProps {
  company?: Company;
}

const Invoices = (props: InvoicesProps): React.ReactElement => {
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);

  return (
    <div>
      <ResourceList
        title="Facturas"
        queryFields="hola, chao"
        resourceParent={props.company}
        getResourceList={sellerApi.invoices.list}
        listHeaders={[
          { key: 'issue_date', label: 'Fecha de emisión' },
          { key: 'total', label: 'Total facturado' },
          { key: 'link', label: 'Link de referencia' },
        ]}
        listColumns={{
          issue_date: (object) => object.issue_date,
          link: (object) => (
            <a href={object.link} target="_blank" rel="noreferrer noopener">
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />{' '}
              {isMobile ? `${object.link.split('/b')[0]}...` : object.link}
            </a>
          ),
          total: (object) =>
            formatCurrency(object.invoice_lines.reduce((prev, curr) => (prev += curr.amount), 0)),
          name: (object) =>
            formatCurrency(object.invoice_lines.reduce((prev, curr) => (prev += curr.amount), 0)),
        }}
        hideQuery
        listMobileHeaders={[{ key: 'name' }, { key: 'issue_date' }, { key: 'link' }]}
      />
    </div>
  );
};

export default Invoices;
