import { ApiList, PaymentTransaction } from '../../../app/type';

import client from '../client';

export const list = (clientId: string): Promise<ApiList<PaymentTransaction>> => {
  return client({
    method: 'get',
    url: `/v1/client/payment_transactions`,
    params: { client_id: clientId },
  })
    .then(({ data: body }: { data: ApiList<PaymentTransaction> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const payWithCard = (data: string[], clientId: string, cardId: string): Promise<any> => {
  return client({
    method: 'post',
    url: `/v1/client/payment_transactions/pay_with_card`,
    params: { client_id: clientId, ids: data, card_id: cardId },
  })
    .then(({ data: body }: { data: any }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const payOtherMethod = (
  data: string[],
  clientId: string,
  paymentMethod: string,
  token?: string
): Promise<any> => {
  return client({
    method: 'post',
    url: `/v1/client/payment_transactions/pay_other_method`,
    params: { client_id: clientId, ids: data, payment_method: paymentMethod, token: token },
  })
    .then(({ data: body }: { data: any }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
