import React, { useEffect, useState } from 'react';

import { Company } from '../../../app/type';
import InfoBox from '../../../common/components/InfoBox';

import { useMediaQuery } from '@mui/material';

import { sellerApi } from '../../../common/api';

import styles from './Index.module.scss';
import { formatCurrency } from '../../../common/utils';
import variables from '../../../common/styles/variables.module.scss';

interface BoxesProps {
  context: string;
  company?: Company;
}

const Boxes = (props: BoxesProps): React.ReactElement => {
  const [data, setData] = useState<{ [key: string]: any }>({});
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);

  useEffect(() => {
    if (!props.company && !props.context) return;

    switch (props.context) {
      case 'transfers':
        sellerApi.transfers.boxesData(props.company?.id).then((data) => setData(data));
        break;
      case 'payments':
        sellerApi.statements.boxesData(props.company?.id).then((data) => setData(data));
        break;
    }
  }, [props.context, props.company]);

  if (isMobile) {
    return <></>;
  }

  return (
    <>
      {props.context === 'transfers' && (
        <>
          <InfoBox
            key={1}
            title="Monto total transferido"
            data={formatCurrency(data.transfers_total || 0)}
            kind="info2"
            style={styles.firstInfoContainer}
            width={4}
          />
          <InfoBox
            key={2}
            title="Promedio por transferencia"
            data={formatCurrency(data.transfers_average) || 0}
            kind="info2"
            style={styles.firstInfoContainer}
            width={4}
          />
        </>
      )}
      {props.context === 'payments' && (
        <>
          <InfoBox
            key={1}
            title="Monto total recaudado"
            data={formatCurrency(data.payments_total) || 0}
            kind="info2"
            style={styles.firstInfoContainer}
          />
          <InfoBox
            key={2}
            title="Ticket promedio"
            data={formatCurrency(data.payments_average) || 0}
            kind="info2"
            style={styles.firstInfoContainer}
          />
          <InfoBox
            key={3}
            title="Medio de pago más usado"
            data={data.payments_payment_method || '-'}
            kind="info2"
            style={styles.firstInfoContainer}
          />
        </>
      )}
    </>
  );
};

export default Boxes;
