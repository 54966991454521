import variables from '../styles/variables.module.scss';

export const INVOICE = [
  ['issued', 'Emitida'],
  ['rejected', 'Rechazada'],
];

export const INVOICE_COLORS = Object.fromEntries([
  ['issued', variables.success],
  ['rejected', variables.error],
]);

export const INVOICE_ES = Object.fromEntries(INVOICE);
