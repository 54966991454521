import { FileImport, ApiList } from '../../../app/type';

import client from '../client';

export const list = (
  companyId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined },
  load_type?: string
): Promise<ApiList<FileImport>> => {
  return client({
    method: 'get',
    url: `/v1/file_imports`,
    params: { id: companyId, query, page: page || 1, filter_params: filterParams, load_type },
  })
    .then(({ data: body }: { data: ApiList<FileImport> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const generateStatementsExcel = (parentId?: string): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/file_imports/${parentId}/generate_excel`,
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ changeStatusError: error });
      throw error;
    });
};

export default { list, generateStatementsExcel };
