import React, { ChangeEvent, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Button, CircularProgress, MenuItem, TextField, Typography } from '@mui/material';
import styles from '../../features/buyer/Payment.module.scss';
import { KlapCardInfo } from '../../app/type';
import { loadScript } from '../utils';
import { FRONT_URL } from '../../common/api/client';
import klapLogo from '../../assets/images/seller/payment/klap_logo.png';

interface KlapCardFormProps {
  orderId: string;
  companyColor: string;
  submitText: string;
  redirectUrls: string[];
  klapUrl: string;
  payableId?: string;
  payableType?: string;
}

const KlapCardForm = ({
  orderId,
  companyColor,
  submitText,
  redirectUrls,
  payableId,
  payableType,
  klapUrl,
}: KlapCardFormProps): React.ReactElement => {
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false);

  const cardSchema = yup.object().shape({
    cardNumber: yup
      .string()
      .required('Número de tarjeta requerido')
      .max(19, 'Debe tener un largo máximo de 16 dígitos'),
    name: yup.string().required('Nombre del titular es requerido'),
    cardExpiryDate: yup
      .string()
      .required('Fecha de vencimiento requerida')
      .min(5, 'Fecha de vencimiento inválida')
      .test('is-expiration-date-valid', 'Fecha de vencimiento inválida', (value) => {
        if (!value) {
          return false;
        }
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;
        const [inputMonth, inputYear] = value.split('/').map((v) => parseInt(v, 10));
        return (
          inputYear >= currentYear &&
          inputMonth <= 12 &&
          (inputYear !== currentYear || (inputMonth >= currentMonth && inputMonth <= 12))
        );
      }),
    cardCvv: yup.string().required('CVV requerido').min(3, 'CVV inválido'),
  });

  const formik = useFormik<KlapCardInfo>({
    initialValues: {
      cardNumber: '',
      name: '',
      cardExpiryDate: '',
      cardCvv: undefined,
      cardType: '2',
    },
    validationSchema: cardSchema,
    onSubmit: () => {
      setLoadingPayment(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      KLAP.payOrder();
    },
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const id = e.target.id;
    if (id === 'cardNumber') {
      const val = e.target.value.replace(/\D/g, '').slice(0, 16);
      const groupedDigits = val.match(/.{1,4}/g);
      const formattedString = groupedDigits ? groupedDigits.join(' ') : '';
      formik.setFieldValue(id, formattedString);
    } else if (id === 'name') {
      formik.setFieldValue(id, e.target.value);
    } else if (id === 'cardExpiryDate') {
      const val = e.target.value.replace(/\D/g, '').slice(0, 4);
      const year = val.slice(2, 4);
      const formattedString = `${val.slice(0, 2)}${year ? `/${year}` : ''}`;
      formik.setFieldValue(id, formattedString);
    } else {
      const val = e.target.value.replace(/\D/g, '').slice(0, 4);
      formik.setFieldValue(id, val);
    }
  };

  useEffect(() => {
    loadScript('klap-script', klapUrl, () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      KLAP.init({
        method: 'registro-tarjetas',
        debug: true,
        successUrl: `${FRONT_URL}${redirectUrls[0]}${
          payableId ? `&payable_id=${payableId}&payable_type=${payableType}` : ''
        }`,
        errorUrl: `${FRONT_URL}${redirectUrls[1]}`,
      });
    });
  }, []);

  return (
    <div className={styles.formContainer} id="checkout-klap" data-klap-order-id={orderId}>
      <Typography mb={2} textAlign="center" variant="h6">
        Ingresa los datos de tu tarjeta
      </Typography>
      <TextField
        fullWidth
        required
        id="cardNumber"
        className={styles.inputField}
        placeholder="Número de tarjeta"
        name="cardNumber"
        value={formik.values.cardNumber}
        onChange={(e) => {
          formik.handleChange;
          handleInputChange(e);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched.cardNumber && Boolean(formik.errors?.cardNumber)}
        helperText={formik.touched.cardNumber && formik.errors?.cardNumber}
      />
      <TextField
        fullWidth
        required
        className={styles.inputField}
        placeholder="Nombre"
        type="text"
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors?.name)}
        helperText={formik.touched.name && formik.errors?.name}
      />
      <div className={styles.row}>
        <TextField
          fullWidth
          required
          className={styles.inputField}
          placeholder="MM/YY"
          type="text"
          name="cardExpiryDate"
          id="cardExpiryDate"
          value={formik.values.cardExpiryDate}
          onChange={(e) => {
            formik.handleChange;
            handleInputChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.cardExpiryDate && Boolean(formik.errors?.cardExpiryDate)}
          helperText={formik.touched.cardExpiryDate && formik.errors?.cardExpiryDate}
          inputProps={{
            'data-klap-expiry-date': true,
          }}
        />
        <TextField
          fullWidth
          required
          className={styles.inputField}
          placeholder="CVV"
          type="password"
          name="cardCvv"
          id="cardCvv"
          value={formik.values.cardCvv}
          onChange={(e) => {
            formik.handleChange;
            handleInputChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.cardCvv && Boolean(formik.errors?.cardCvv)}
          helperText={formik.touched.cardCvv && formik.errors?.cardCvv}
          inputProps={{
            'data-klap-card-cvv': true,
          }}
        />
      </div>
      <TextField
        fullWidth
        required
        className={styles.inputFieldSelect}
        placeholder="Tarjeta"
        select
        name="cardType"
        id="cardType"
        value={formik.values.cardType}
        onChange={formik.handleChange}
        defaultValue="2"
        inputProps={{
          'data-klap-card-type': formik.values.cardType,
        }}
      >
        <MenuItem value="2">Crédito</MenuItem>
        <MenuItem value="1">Débito</MenuItem>
      </TextField>
      <input hidden value={1} data-klap-quotas readOnly />
      <input type="checkbox" hidden checked data-klap-generate-token readOnly />
      <input
        hidden
        type="text"
        value={formik.values.cardNumber?.replace(/\s/g, '')}
        data-klap-card-number
        id="cardNumber"
        readOnly
      />
      <Button
        variant="contained"
        onClick={() => {
          formik.submitForm();
        }}
        style={{
          backgroundColor: loadingPayment ? '#b9b9b9' : companyColor,
        }}
        className={styles.subscribeCard}
        disabled={loadingPayment}
      >
        {loadingPayment ? <CircularProgress size={20} /> : `${submitText}`}
      </Button>
      <div className={styles.brandRow}>
        <Typography variant="body2">Procesado por</Typography>
        <img src={klapLogo} alt="Logo Klap" />
      </div>
    </div>
  );
};

export default KlapCardForm;
