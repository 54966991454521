import variables from '../styles/variables.module.scss';

export const STATUS = [
  ['nullified', 'Anulado'],
  ['accepted', 'Pagado'],
  ['rejected', 'Rechazado'],
  ['reversed', 'Reversado'],
  ['pending_approval', 'Pendiente de aprobación'],
  ['pending_refund', 'Devolución pendiente'],
  ['reversed_manually', 'Reversado'],
];

export const STATUS_COLORS = Object.fromEntries([
  ['nullified', variables.pending],
  ['rejected', variables.error],
  ['accepted', variables.success],
  ['reversed', variables.warning],
  ['pending_approval', variables.warning],
  ['pending_refund', variables.warning],
  ['reversed_manually', variables.warning],
]);

export const STATUS_ES = Object.fromEntries(STATUS);
