const getKeys = (): (string | null)[] => {
  const keysArray = [];
  for (let i = 0, len = localStorage.length; i < len; ++i) {
    keysArray.push(localStorage.key(i));
  }
  return keysArray;
};

export const setItem = (key: string, value: { [key: string]: any }): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getItem = (key: string): { [key: string]: any } => {
  if (!getKeys().includes(key)) {
    return {};
  }
  let value = {};
  try {
    value = JSON.parse(localStorage.getItem(key) || '{}');
  } catch (e) {
    console.error(e);
    localStorage.removeItem(key);
  }
  return value;
};
