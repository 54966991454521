import AppBar from '../session/AppBar';
import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, Route, Switch, Link, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  useMediaQuery,
  Grid,
  IconButton,
  Divider,
  Typography,
} from '@mui/material';
import variables from '../../common/styles/variables.module.scss';

import Dashboard from './Dashboard';

import CompanyIndex from './company/Index';

import CompanyShow from './company/Show';

import Statements from './Statements';

import PaidStatements from './PaidStatements';

import styles from './Backoffice.module.scss';
import { initials } from '../../common/utils/index';

import Avatar from '../../common/components/Avatar';

import {
  BarChart as BarChartIcon,
  Home as HomeIcon,
  CheckCircle as CheckCircleIcon,
  Flag as FlagIcon,
  Menu as MenuIcon,
  DollarSign as DollarSignIcon,
} from 'react-feather';
import Transfers from './Transfers';

const Backoffice = (): React.ReactElement => {
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
  const { pathname } = useLocation();
  const [opaqueBg, setOpaqueBg] = useState<boolean>(false);

  useEffect(() => {
    setDrawerOpen(!isMobile);
  }, [isMobile]);

  const onDrawerButton = () => {
    setDrawerOpen((o: boolean) => !o);
  };

  const onRouteClick = () => {
    if (isMobile && drawerOpen) setDrawerOpen(false);
  };

  const darkenBg = () => {
    setOpaqueBg(!opaqueBg);
  };

  const RouteListItem = (props: {
    label: string;
    route: string;
    icon: React.ReactElement;
  }): React.ReactElement => {
    const selected = pathname.includes(`/backoffice/${props.route}`);

    return (
      <ListItem
        button
        key={props.route}
        component={Link}
        to={`/backoffice/${props.route}`}
        selected={selected}
        onClick={onRouteClick}
      >
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.label} />
      </ListItem>
    );
  };

  return (
    <div className={`${styles.appContainer} ${opaqueBg && styles.appBarOpen}`}>
      <AppBar
        home="/backoffice/dashboard"
        handleHamburguer={onDrawerButton}
        backoffice={true}
        drawerOpen={drawerOpen}
        darkenBg={darkenBg}
        isMobile={isMobile}
      />
      <Drawer
        open={drawerOpen || !isMobile}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
          },
        }}
        className={`${styles.drawer} ${!drawerOpen && styles.drawerClosed}`}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
      >
        <Grid container>
          <Grid item xs={12}>
            <div className={styles.drawer}>
              <div className={`${styles.topDrawer} ${!drawerOpen && styles.topDrawerClosed}`}>
                <Link to={'/seller/home' || '/'} className={styles.homeLink}>
                  <img
                    src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_white.png"
                    alt="Logo Zafepay"
                  />
                </Link>
                {onDrawerButton && (
                  <IconButton className={styles.hamburguerButton} onClick={onDrawerButton}>
                    <MenuIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Avatar
                className={`${styles.avatar} ${!drawerOpen && styles.topDrawerClosed}`}
                context="company"
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              {drawerOpen ? (
                <Fragment>
                  <Typography variant="h5">Plaglabs</Typography>
                </Fragment>
              ) : (
                <Typography variant="h6">{initials('Plag Labs')}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Divider variant="middle" sx={{ margin: '30px 0 10px 0' }} />
        <List>
          <RouteListItem label="Dashboard" route="dashboard" icon={<BarChartIcon />} />
          <RouteListItem label="Empresas" route="companies" icon={<HomeIcon />} />
          <RouteListItem label="Cobros sin Pagar" route="statements" icon={<FlagIcon />} />
          <RouteListItem
            label="Cobros Pagados"
            route="paid_statements"
            icon={<CheckCircleIcon />}
          />
          <RouteListItem label="Transferencias" route="transfers" icon={<DollarSignIcon />} />
        </List>
      </Drawer>
      <div className={`${styles.mainContainer} ${!drawerOpen && styles.mainContainerWhenClosed}`}>
        <Paper className={styles.dashBody} elevation={0}>
          <Switch>
            <Route path="/backoffice/dashboard">
              <Dashboard />
            </Route>
            <Route path="/backoffice/companies/:companyId">
              <CompanyShow />
            </Route>
            <Route path="/backoffice/companies">
              <CompanyIndex />
            </Route>
            <Route path="/backoffice/statements">
              <Statements />
            </Route>
            <Route path="/backoffice/paid_statements">
              <PaidStatements />
            </Route>
            <Route path="/backoffice/transfers">
              <Transfers />
            </Route>
            <Route path="/backoffice/*">
              <Redirect to="/backoffice/dashboard" />
            </Route>
          </Switch>
        </Paper>
      </div>
    </div>
  );
};

export default Backoffice;
