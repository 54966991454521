import { ApiObject, Subscription, Buyer, SubscriptionBuyer } from '../../../app/type';

import client from '../client';

interface SubscribeData {
  data: Partial<Buyer>;
  basket_amount?: number;
  extra_data: { [key: string]: any };
}

export const serviceToPay = (
  payableType: string,
  payableId: string
): Promise<ApiObject<SubscriptionBuyer>> => {
  return client({
    method: 'get',
    url: `/v1/public/payables/${payableId}?payable_type=${payableType}`,
  })
    .then(({ data: body }: { data: ApiObject<SubscriptionBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
export const subscribe = (data: SubscribeData): Promise<any> => {
  return client({
    method: 'post',
    url: `/v1/public/payables`,
    data,
  })
    .then(({ data: body }: { data: { data: any } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscribeError: error });
      throw error;
    });
};

export const changeCard = (
  payableType: string,
  payableId: string
): Promise<ApiObject<Subscription>> => {
  return client({
    method: 'put',
    url: `/v1/public/payables/${payableId}/change_card`,
    params: { payable_type: payableType },
  })
    .then(({ data: body }: { data: ApiObject<Subscription> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ changeCardError: error });
      throw error;
    });
};

interface RegistrationData {
  url: string;
  token: string;
}
export const registration = (data: RegistrationData): Promise<any> => {
  return client({
    method: 'post',
    url: data.url,
    data,
  })
    .then(({ data: body }: { data: { data: any } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ registrationError: error });
      throw error;
    });
};

export const payProduct = (data: SubscribeData & { product_id: string }): Promise<any> => {
  return client({
    method: 'post',
    url: `/v1/public/payables/pay_product`,
    data,
  })
    .then(({ data: body }: { data: { data: any } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscribeError: error });
      throw error;
    });
};

export const payGroup = (data: SubscribeData & { group_id: string }): Promise<any> => {
  return client({
    method: 'post',
    url: `/v1/public/payables/pay_group`,
    data,
  })
    .then(({ data: body }: { data: { data: any } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscribeError: error });
      throw error;
    });
};

export const paySinglePaymentBuyer = (
  data: SubscribeData & { single_payment_buyer_id: string }
): Promise<any> => {
  return client({
    method: 'post',
    url: `/v1/public/payables/pay_single_payment_buyer`,
    data,
  })
    .then(({ data: body }: { data: { data: any } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscribeError: error });
      throw error;
    });
};

export const paySubscriptionBuyer = (
  data: SubscribeData & { subscription_buyer_id: string }
): Promise<any> => {
  return client({
    method: 'post',
    url: `/v1/public/payables/pay_subscription_buyer`,
    data,
  })
    .then(({ data: body }: { data: { data: any } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscribeError: error });
      throw error;
    });
};

export const paySubscription = (
  data: SubscribeData & { subscription_id: string }
): Promise<any> => {
  return client({
    method: 'post',
    url: `/v1/public/payables/pay_subscription`,
    data,
  })
    .then(({ data: body }: { data: { data: any } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscribeError: error });
      throw error;
    });
};

export const paySinglePayment = (
  data: SubscribeData & { single_payment_id: string }
): Promise<any> => {
  return client({
    method: 'post',
    url: `/v1/public/payables/pay_single_payment`,
    data,
  })
    .then(({ data: body }: { data: { data: any } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscribeError: error });
      throw error;
    });
};

export const payPaymentPlan = (data: SubscribeData & { payment_plan_id: string }): Promise<any> => {
  return client({
    method: 'post',
    url: `/v1/public/payables/pay_payment_plan`,
    data,
  })
    .then(({ data: body }: { data: { data: any } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscribeError: error });
      throw error;
    });
};

export const payCheckout = (data: SubscribeData & { checkout_id: string }): Promise<any> => {
  return client({
    method: 'post',
    url: `/v1/public/payables/pay_checkout`,
    data,
  })
    .then(({ data: body }: { data: { data: any } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscribeError: error });
      throw error;
    });
};

export default {
  serviceToPay,
  subscribe,
  changeCard,
  registration,
  payProduct,
  payGroup,
  paySinglePaymentBuyer,
  paySubscriptionBuyer,
  paySubscription,
  paySinglePayment,
  payPaymentPlan,
  payCheckout,
};
