import { ApiList, ApiObject, GenericObject, Payable } from '../../../app/type';

import client from '../client';

export interface Suggestion {
  id: string;
  extra_fields: GenericObject;
  buyer_name: string;
  buyer_email: string;
  buyer_id: string;
}

export const index = (subscriptionIds: string[]): Promise<ApiList<Suggestion>> => {
  return client({
    method: 'get',
    url: `/v1/public/subscriptions?ids=${subscriptionIds}`,
  })
    .then(({ data: body }: { data: ApiList<Suggestion> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const markAsCanceledPublic = (subscriptionId: string): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/public/subscriptions/${subscriptionId}/mark_as_canceled_public`,
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const show = (
  subscriptionId: string,
  params?: { [key: string]: any }
): Promise<ApiObject<Payable>> => {
  return client({
    method: 'get',
    url: `/v1/public/subscriptions/${subscriptionId}`,
    params: params,
  })
    .then(({ data: body }: { data: ApiObject<Payable> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const similarSubscriptionBuyers = (
  subscriptionId: string,
  email: string,
  extra_fields: GenericObject
): Promise<ApiList<Suggestion>> => {
  return client({
    method: 'get',
    url: `/v1/public/subscriptions/${subscriptionId}/similar_subscription_buyers`,
    params: { email: email, extra_fields: extra_fields },
  })
    .then(({ data: body }: { data: ApiList<Suggestion> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const validateDiscount = (companyId: string, code: string): Promise<GenericObject> => {
  return client({
    method: 'get',
    url: `/v1/public/discounts/validate_code`,
    params: { company_id: companyId, code: code },
  })
    .then(({ data: body }: { data: GenericObject }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
