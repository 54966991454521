import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BackofficeCompany } from '../../../app/type';

import { backofficeApi } from '../../../common/api';
import { setBackofficeCompany } from '../backofficeSlice';

import ResourceList from '../../../common/components/ResourceList';
import InfoBox from '../../../common/components/InfoBox';
import { Grid } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import styles from '../Backoffice.module.scss';

const CompanyIndex = (): React.ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [boxesData, setData] = useState<{ [key: string]: string }>({});

  const onItem = (res: BackofficeCompany) => dispatch(setBackofficeCompany(res));

  useEffect(() => {
    backofficeApi.companies.boxesData().then((data) => setData(data.data));
  }, []);

  const infoBoxes = [
    <InfoBox
      key={1}
      title="Empresas que cobraron el mes pasado"
      kind="info2"
      data={boxesData?.last_month_charged}
      style={styles.firstInfoContainer}
      icon={<FontAwesomeIcon icon={faDollarSign} />}
    />,
    <InfoBox
      key={2}
      title="Empresas que cobraron este mes"
      kind="info2"
      data={boxesData?.present_month_charged}
      style={styles.secondInfoContainer}
      icon={<FontAwesomeIcon icon={faDollarSign} />}
    />,
    <InfoBox
      key={3}
      title="Empresas que pagaron el mes pasado"
      kind="info2"
      data={boxesData?.last_month_paid}
      style={styles.thirdInfoContainer}
      icon={<FontAwesomeIcon icon={faDollarSign} />}
    />,
    <InfoBox
      key={4}
      title="Empresas que pagan este mes"
      kind="info2"
      data={boxesData?.present_month_paid}
      style={styles.thirdInfoContainer}
      icon={<FontAwesomeIcon icon={faDollarSign} />}
    />,
  ];

  return (
    <Fragment>
      <Grid container className={styles.containerGrid} flexWrap={'nowrap'}>
        {infoBoxes.map((box) => box)}
      </Grid>
      <ResourceList
        title="Empresas"
        queryFields="Nombre"
        resourceParent={{ id: 'none' }}
        setResource={setBackofficeCompany}
        getResourceList={(_id, query, page) => backofficeApi.companies.list(query, page)}
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'seller', label: 'Nombre vendedor' },
          { key: 'number_of_products', label: '# Servicios' },
          { key: 'number_of_clients', label: '# Clientes' },
          { key: 'number_of_payments', label: '# Pagos' },
          { key: 'last_payment', label: 'Fecha último pago' },
        ]}
        listColumns={{
          name: (res: BackofficeCompany) => res.name,
          seller: (res: BackofficeCompany) => res.users?.[0]?.name,
          number_of_products: (res: BackofficeCompany) => res.number_of_products,
          last_payment: (res: BackofficeCompany) => res.last_payment,
          number_of_clients: (res: BackofficeCompany) => res.number_of_clients,
          number_of_payments: (res: BackofficeCompany) => res.number_of_payments,
        }}
        defaultRoute="/backoffice/dashboard"
        listActionsHeaders={() => [{ key: 'show', label: 'Más información' }]}
        listActions={{
          show: (res: BackofficeCompany) => {
            onItem(res);
            history.push(`/backoffice/companies/${res.id}`);
          },
        }}
      />
    </Fragment>
  );
};

export default CompanyIndex;
