export const ELECTRICITY_COMPANIES = [
  'CGE',
  'Chilquinta',
  'EDELAYSEN',
  'EDELMAG',
  'EEPA',
  'Enel',
  'Energía Casablanca',
  'Frontel',
  'Litoral',
  'Luz Linares',
  'Luz Osorno',
  'Luz Parral',
  'SAESA',
];

export const WATER_COMPANIES = [
  'Aguas Andinas',
  'Aguas Antofagasta',
  'Aguas Araucanía',
  'Aguas Chañar',
  'Aguas Cordillera',
  'Aguas Decima',
  'Aguas Magallanes',
  'Aguas Manquehue',
  'Aguas Patagonia',
  'Aguas Pirque',
  'Aguas San Isidro',
  'Aguas San Pedro',
  'Aguas del Altiplano',
  'Aguas del Valle',
  'Brisaguas',
  'Coopagua',
  'Cossbo',
  'ESSAL',
  'Emapal',
  'Essbio',
  'Esval',
  'Nuevosur',
  'Sampa',
  'Selar',
  'Sembcorp Aguas Chacabuco',
  'Sembcorp Aguas Santiago',
  'Sembcorp Utilitieso',
];

export const GAS_COMPANIES = [
  'Abastible Boletas',
  'Abastible Factura',
  'Abastible Granel RUT',
  'Gas Sur',
  'GasValpo/Energas',
  'Gasco Granel - Envasado',
  'Gasco Medidores',
  'Lipigas Envasado/Granel',
  'Lipigas Medidores SA',
  'Metrogas',
];

export const HIGHWAY_COMPANIES = [
  'Autopista Costanera Norte',
  'Autopista Ruta del Maipo',
  'Autopista Vespucio Norte',
  'Autopista Vespucio Oriente (AVO1)',
  'Autopista Vespucio Sur',
];

export const PHONE_COMPANIES = ['CLARO Móvil', 'Entel PCS Movil', 'Movistar Movil', 'WOM Movil'];
