import { store } from '../../../app/store';
import { ApiObject, Company } from '../../../app/type';
import { setCompany, startLoading, stopLoading } from '../../../features/seller/sellerSlice';

import client from '../client';

interface PaymentDesignInfo {
  id: string;
  background_color: string;
  company_id: string;
}

interface PaymentDesignData {
  data: PaymentDesignInfo;
}

export const create = (data: PaymentDesignData): Promise<ApiObject<Company>> => {
  const formData = new FormData();
  // @ts-expect-error: ts(7053)
  Object.keys(data.data).map((key) => formData.append(`data[${key}]`, data.data[key]));
  store.dispatch(startLoading());
  return client
    .post('v1/payment_designs', formData)
    .then(({ data: body }: { data: ApiObject<Company> }) => {
      store.dispatch(setCompany(body.data));
      return body;
    })
    .catch((error: any) => {
      console.error({ listDataError: error });
      throw error;
    })
    .finally(() => {
      store.dispatch(stopLoading());
    });
};

export const update = (data: PaymentDesignData): Promise<ApiObject<Company>> => {
  const formData = new FormData();
  // @ts-expect-error: ts(7053)
  Object.keys(data.data).map((key) => formData.append(`data[${key}]`, data.data[key]));
  store.dispatch(startLoading());
  return client
    .put(`/v1/payment_designs/${data.data.id}`, formData)
    .then(({ data: body }: { data: ApiObject<Company> }) => {
      store.dispatch(setCompany(body.data));
      return body;
    })
    .catch((error: any) => {
      console.error({ listDataError: error });
      throw error;
    })
    .finally(() => {
      store.dispatch(stopLoading());
    });
};

export default {
  create,
  update,
};
