import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Company,
  BankInformation,
  Product,
  Buyer,
  Subscription,
  PaymentPlan,
  BillingInformation,
  SinglePayment,
} from '../../app/type';

export interface SellerState {
  company?: Company;
  bankInformation?: BankInformation;
  billingInformation?: BillingInformation;
  buyer?: Buyer;
  product?: Product;
  subscription?: Subscription;
  paymentPlan?: PaymentPlan;
  singlePayment?: SinglePayment;
  loading: boolean;
  firstSessionTags: (string | boolean)[];
}

const initialState: SellerState = {
  loading: false,
  firstSessionTags: [false],
};

export const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    startLoading: (state: SellerState) => {
      state.loading = true;
    },
    stopLoading: (state: SellerState) => {
      state.loading = false;
    },
    setCompany: (state: SellerState, action: PayloadAction<Company | undefined>) => {
      if (action.payload) {
        state.company = action.payload;
      } else {
        state.company = undefined;
      }
    },
    setBankInformation: (
      state: SellerState,
      action: PayloadAction<BankInformation | undefined>
    ) => {
      if (action.payload) {
        state.bankInformation = action.payload;
      } else {
        state.bankInformation = undefined;
      }
    },
    setBillingInformation: (
      state: SellerState,
      action: PayloadAction<BillingInformation | undefined>
    ) => {
      if (action.payload) {
        state.billingInformation = action.payload;
      } else {
        state.billingInformation = undefined;
      }
    },
    setProduct: (state: SellerState, action: PayloadAction<Product | undefined>) => {
      if (action.payload) {
        state.product = action.payload;
      } else {
        state.product = undefined;
      }
    },
    setBuyer: (state: SellerState, action: PayloadAction<Buyer | undefined>) => {
      if (action.payload) {
        state.buyer = action.payload;
      } else {
        state.buyer = undefined;
      }
    },
    setSubscription: (state: SellerState, action: PayloadAction<Subscription | undefined>) => {
      if (action.payload) {
        state.subscription = action.payload;
      } else {
        state.subscription = undefined;
      }
    },
    setPaymentPlan: (state: SellerState, action: PayloadAction<PaymentPlan | undefined>) => {
      if (action.payload) {
        state.paymentPlan = action.payload;
      } else {
        state.paymentPlan = undefined;
      }
    },
    setSinglePayment: (state: SellerState, action: PayloadAction<SinglePayment | undefined>) => {
      if (action.payload) {
        state.singlePayment = action.payload;
      } else {
        state.singlePayment = undefined;
      }
    },
    setFirstSessionTags: (state: SellerState, action: PayloadAction<(string | boolean)[]>) => {
      state.firstSessionTags = action.payload;
    },
    clearData: () => {
      return initialState;
    },
  },
});

export const {
  startLoading,
  stopLoading,
  setCompany,
  setBankInformation,
  setProduct,
  setBuyer,
  setSubscription,
  setPaymentPlan,
  setSinglePayment,
  clearData,
  setFirstSessionTags,
} = sellerSlice.actions;

export default sellerSlice.reducer;
