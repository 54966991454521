import { ApiObject, PaymentTransaction } from '../../../app/type';

import client from '../client';

export const show = (paymentId: string): Promise<ApiObject<PaymentTransaction>> => {
  return client({
    method: 'get',
    url: `/v1/public/payment_transactions/${paymentId}`,
  })
    .then(({ data }: { data: ApiObject<PaymentTransaction> }) => {
      return data;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const payablePayment = (
  payableId: string,
  payableType: string
): Promise<ApiObject<PaymentTransaction>> => {
  return client({
    method: 'get',
    url: `/v1/public/payment_transactions/payable_payment?payable_id=${payableId}&payable_type=${payableType}`,
  })
    .then(({ data }: { data: ApiObject<PaymentTransaction> }) => {
      return data;
    })
    .catch((error: any) => {
      console.error({ payableTransactionError: error });
      throw error;
    });
};
