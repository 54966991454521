import * as companies from './company';
import * as transfers from './transfers';

import { ApiList, ApiObject, DashboardBoxes, Statement, ChartData } from '../../../app/type';

import client from '../client';

export const dashboard = (): Promise<ApiObject<DashboardBoxes>> => {
  return client({
    method: 'get',
    url: '/backoffice/dashboard/boxes_info',
  })
    .then(({ data: body }: { data: ApiObject<DashboardBoxes> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const salesChart = (): Promise<ChartData> => {
  return client({
    method: 'get',
    url: '/backoffice/dashboard/sales_chart',
  })
    .then(({ data: body }: { data: ChartData }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const paymentTypesAmountsChart = (): Promise<ChartData> => {
  return client({
    method: 'get',
    url: '/backoffice/dashboard/payment_types_amounts',
  })
    .then(({ data: body }: { data: ChartData }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const statements = (
  query?: string,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<Statement>> => {
  return client({
    method: 'get',
    url: '/backoffice/dashboard/statements',
    params: { query, filter_params: filterParams || {} },
  })
    .then(({ data: body }: { data: ApiList<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const payStatements = (
  query?: string,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: '/backoffice/dashboard/mark_statements_as_paid',
    params: { query, filter_params: filterParams },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const paidStatements = (
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<Statement>> => {
  return client({
    method: 'get',
    url: '/backoffice/dashboard/paid_statements',
    params: { query, page: page || 1, filter_params: filterParams || {} },
  })
    .then(({ data: body }: { data: ApiList<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const paidStatementsExcel = (query: string): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: '/backoffice/dashboard/paid_statements_excel',
    params: { query },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export default {
  dashboard,
  salesChart,
  paymentTypesAmountsChart,
  statements,
  payStatements,
  paidStatements,
  paidStatementsExcel,
  companies,
  transfers,
};

export { companies, transfers };
