import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
} from '@mui/material';

import { Card, PAC, PaymentPlan, Statement } from '../../../app/type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { formatCurrency } from '../../../common/utils';
import variables from '../../../common/styles/variables.module.scss';
import { pluralInstallmentsWord, hexToRgb } from '../utils';
import Avatar from '../../../common/components/Avatar';
import { useSelector } from '../../../app/hooks';
import { ClientState } from '../clientSlice';
import { useShopCartContext } from '../../../common/contexts/ShopCart';

import CardsPopup from './CardsPopup';

import styles from '../products/Product.module.scss';

interface PaymentPlanCardProps {
  paymentPlan: PaymentPlan;
  cards: Card[];
  PACs: PAC[];
}

export const PaymentPlanCard = ({
  paymentPlan,
  cards,
  PACs,
}: PaymentPlanCardProps): React.ReactElement => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [viewMore, setViewMore] = useState<boolean>(false);
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const { company } = useSelector(({ client }: { client: ClientState }) => client);
  const { statementsCart, removeStatementsCart, addStatementsCart } = useShopCartContext();
  const totalPlan =
    paymentPlan.currency === 'UF' ? paymentPlan.external_amount : paymentPlan.amount;
  const pluralInstallments = pluralInstallmentsWord(paymentPlan.total_installments);
  const debtCount = paymentPlan.total_debt_installments;
  const pluralDebt = pluralInstallmentsWord(debtCount);
  const rgbColor = company?.payment_design?.background_color
    ? hexToRgb(company?.payment_design?.background_color)
    : null;
  const cardsBackgroundColor = rgbColor
    ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    : '#edeeff';

  const handleCheckbox = (statement: Statement) => {
    if (!statement) return;
    if (statementsCart.includes(statement.id)) {
      removeStatementsCart(statement);
    } else {
      addStatementsCart(statement);
    }
  };

  return (
    <div className={styles.cardContainer}>
      {!isMobile && paymentPlan.image && (
        <div className={styles.imageContainer}>
          <Avatar className={styles.imagePaymentPlan} img={paymentPlan.image} context="product" />
        </div>
      )}
      <div className={styles.infoPaymentPlanContainer}>
        {isMobile && (
          <div className={styles.mobileTopCard}>
            <div
              className={styles.letterCoontainer}
              style={{
                background: company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3',
              }}
            >
              <Typography>{paymentPlan.product.name[0].toUpperCase()}</Typography>
            </div>
            <ListItem
              button
              component={Link}
              to={`/client/products/payment_plan/${paymentPlan.id}`}
              className={styles.showButton}
              style={{
                borderColor: company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3',
              }}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faEye}
                  style={{
                    color: company?.payment_design?.background_color
                      ? company?.payment_design?.background_color
                      : '#4653e3',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Ver detalle"
                style={{
                  color: company?.payment_design?.background_color
                    ? company?.payment_design?.background_color
                    : '#4653e3',
                }}
              />
            </ListItem>
          </div>
        )}
        <div className={styles.infoRow}>
          <div className={styles.nameContainer}>
            {!isMobile && !paymentPlan.image && (
              <div
                className={styles.letterCoontainer}
                style={{
                  background: company?.payment_design?.background_color
                    ? company?.payment_design?.background_color
                    : '#4653e3',
                }}
              >
                <Typography>{paymentPlan.product.name[0].toUpperCase()}</Typography>
              </div>
            )}
            <div>
              <Typography className={styles.title}>Producto</Typography>
              <Typography className={styles.name}>{paymentPlan.product.name}</Typography>
            </div>
          </div>
          <div className={styles.cardInfo}>
            {company?.automatic_payment_methods && (
              <div>
                <Typography className={styles.title}>Medio de pago inscrito</Typography>
                <div className={styles.paymentRow}>
                  <Typography className={styles.name}>
                    {paymentPlan.card
                      ? `XXXX XXXX XXXX ${paymentPlan.card.last_four_digits}`
                      : paymentPlan.pac
                      ? `${paymentPlan.pac.account_type || 'PAC'} •••• ${
                          paymentPlan.pac.account_number?.slice(-4) || ''
                        }`
                      : 'Sin medio de pago inscrito'}
                  </Typography>
                  <ListItem
                    className={styles.editLink}
                    onClick={() => setOpenPopup(true)}
                    style={{
                      color: company?.payment_design?.background_color
                        ? company?.payment_design?.background_color
                        : '#4653e3',
                      textDecorationColor: company?.payment_design?.background_color
                        ? company?.payment_design?.background_color
                        : '#4653e3',
                    }}
                  >
                    <ListItemText primary={'Editar'} />
                  </ListItem>
                </div>
              </div>
            )}
            {!isMobile && (
              <div>
                <ListItem
                  button
                  component={Link}
                  to={`/client/products/payment_plan/${paymentPlan.id}`}
                  className={styles.showButton}
                  style={{
                    borderColor: company?.payment_design?.background_color
                      ? company?.payment_design?.background_color
                      : '#4653e3',
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{
                        color: company?.payment_design?.background_color
                          ? company?.payment_design?.background_color
                          : '#4653e3',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Ver detalle"
                    style={{
                      color: company?.payment_design?.background_color
                        ? company?.payment_design?.background_color
                        : '#4653e3',
                    }}
                  />
                </ListItem>
              </div>
            )}
          </div>
        </div>
        <div
          className={styles.installmentsBox}
          style={{
            background: cardsBackgroundColor,
          }}
        >
          <div className={styles.installmentsInfo}>
            <Typography className={styles.debtTitle}>{`Total: ${formatCurrency(
              totalPlan,
              paymentPlan.currency
            )}`}</Typography>
            {!isMobile && (
              <div className={styles.installmentsRow}>
                <Typography
                  className={styles.number}
                >{`${paymentPlan.total_paid_installments}/${paymentPlan.total_installments} ${pluralInstallments}`}</Typography>
                {debtCount && debtCount > 0 ? (
                  <div className={styles.advice}>
                    <Typography>{`${debtCount} ${pluralDebt} en deuda`}</Typography>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
          {isMobile && (
            <div className={styles.installmentsRow}>
              <Typography
                className={styles.number}
              >{`${paymentPlan.total_paid_installments}/${paymentPlan.total_installments} ${pluralInstallments}`}</Typography>
              {debtCount && debtCount > 0 ? (
                <div className={styles.advice}>
                  <Typography>{`${debtCount} ${pluralDebt} en deuda`}</Typography>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
          <Divider variant="middle" sx={{ margin: '15px 0' }} />
          <div>
            <div className={styles.statementsList}>
              <Table sx={{ minWidth: 500 }}>
                <TableBody>
                  {paymentPlan.next_statements?.map((statement: Statement, index: number) =>
                    index === 0 || (viewMore && index > 0) ? (
                      <TableRow key={statement.id} className={`${debtCount > 0 && styles.debtRow}`}>
                        <TableCell>
                          <Checkbox
                            checked={statementsCart.includes(statement.id || '')}
                            onChange={() => handleCheckbox(statement)}
                            style={{
                              color: statementsCart.includes(statement.id || '')
                                ? company?.payment_design?.background_color
                                  ? company?.payment_design?.background_color
                                  : '#4653e3'
                                : 'inherit',
                            }}
                          />
                        </TableCell>
                        <TableCell className={styles.row}>
                          <Typography>
                            {statement.currency === 'UF'
                              ? formatCurrency(statement.external_amount, statement.currency)
                              : formatCurrency(statement.amount)}
                          </Typography>
                        </TableCell>
                        <TableCell className={`${styles.row} ${styles.dateCell}`}>
                          <div>
                            <Typography>{statement.due_date.replace(/\//g, '-')}</Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : null
                  )}
                </TableBody>
              </Table>
            </div>
            {paymentPlan?.next_statements && paymentPlan?.next_statements.length > 1 && (
              <div
                className={styles.viewMoreButton}
                onClick={() => setViewMore(!viewMore)}
                style={{
                  textDecorationColor: company?.payment_design?.background_color
                    ? company?.payment_design?.background_color
                    : '#4653e3',
                }}
              >
                <Typography
                  style={{
                    color: company?.payment_design?.background_color
                      ? company?.payment_design?.background_color
                      : '#4653e3',
                  }}
                >
                  {viewMore ? 'Ver menos' : 'Ver más'}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <CardsPopup
          state={{ open: openPopup, setOpen: setOpenPopup }}
          projectName={paymentPlan.product.name || ''}
          card={paymentPlan.card?.id || ''}
          productId={paymentPlan.id || ''}
          isPaymentPlan={true}
          cards={cards}
          PACs={PACs}
        />
      </div>
    </div>
  );
};

export default PaymentPlanCard;
