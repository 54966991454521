import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '../../../app/hooks';
import { SellerState } from '../sellerSlice';

// Components
import { Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import InvoicingSetupForm from '../../../common/components/InvoicingSetupForm';

import { useSnackbar } from 'notistack';
import { sellerApi } from '../../../common/api';
import { useFormik } from 'formik';
import * as yup from 'yup';

// Assets
import styles from './CompanySeller.module.scss';
import cStyles from '../../../common/styles/common.module.scss';
import { setDocumentTitle } from '../../../common/utils';
import { GenericObject, InvoiceConfiguration } from '../../../app/type';

const InvoicingProviders: GenericObject = {
  tufacturador: 'TuFacturador',
};

const InvoiceConfigSchema = yup.object().shape({
  document: yup.string().required().label('Tipo de documento'),
  vat_exempt: yup.boolean().required().label('IVA'),
  emit_at: yup.string().required().label('Emisión documento'),
});

const InvoicingSetup = (): React.ReactElement => {
  setDocumentTitle('Configuración Emisión Documentos');
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [invoiceConfiguration, setInvoiceConfiguration] = useState<InvoiceConfiguration>();

  const initialValues = {
    id: invoiceConfiguration?.document || '',
    document: invoiceConfiguration?.document || '',
    vat_exempt: invoiceConfiguration?.vat_exempt || false,
    emit_at: invoiceConfiguration?.emit_at || '',
    emit_credit_note: invoiceConfiguration?.emit_credit_note || false,
    ask_rut: invoiceConfiguration?.ask_rut,
  } as InvoiceConfiguration;

  const formik = useFormik<InvoiceConfiguration>({
    initialValues,
    validationSchema: InvoiceConfigSchema,
    onSubmit: (invoiceConfiguration: InvoiceConfiguration, { setErrors }: any) => {
      setLoading(true);
      sellerApi.companies
        .updateInvoiceConfiguration(company?.id || '', { data: invoiceConfiguration })
        .then(() => {
          enqueueSnackbar('Información actualizada correctamente', { variant: 'success' });
        })
        .catch((err: any): void => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        })
        .finally(() => setLoading(false));
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!company) {
      history.replace('/seller/home');
    } else if (company) {
      sellerApi.companies.invoiceConfiguration(company?.id).then((data) => {
        setInvoiceConfiguration(data.data);
      });
    }
  }, [company?.id]);

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Paper className={styles.infoPaper}>
          <Grid container className={styles.mainContainer}>
            <Grid item xs={12} className={cStyles.paperHeader}>
              <div className={cStyles.paperHeaderContent}>
                <Typography variant="h5">
                  Configuración Emisión de Documentos Tributarios
                </Typography>
                <Typography mt={1}>
                  En esta sección puedes configurar la emisión de documentos tributarios a través
                  del facturador de mercado {InvoicingProviders[company?.invoicing_provider || '']}.
                  La configuración que definas acá se utilizará por defecto en cada uno de los
                  servicios que crees en la plataforma. En caso de necesitar una configuración
                  especial en algun servicio en particular, puedes modificar estas configuraciones
                  en el mismo servicio al momento de crearlo.
                </Typography>
              </div>
            </Grid>
            <div className={styles.formContainer}>
              <InvoicingSetupForm formik={formik} />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <Grid item xs={12} mt={2} display="flex" justifyContent="center" alignItems="center">
                <Button
                  disableElevation
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  className="loader"
                >
                  {loading && <CircularProgress size={20} />}
                  Guardar
                </Button>
              </Grid>
            </form>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default InvoicingSetup;
