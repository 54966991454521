import React, { Fragment, useRef, useState, FormEvent } from 'react';
import {
  Typography,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  useMediaQuery,
} from '@mui/material';

import { billsApi } from '../../common/api';
import { formatCurrency, setDocumentTitle } from '../../common/utils';

import Recaptcha from '../../common/components/Recaptcha';
import {
  faDroplet,
  faLightbulb,
  faGaugeSimpleHigh,
  faRoad,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopUp from '../../common/components/PopUp';

import styles from './BillsPortal.module.scss';
import variables from '../../common/styles/variables.module.scss';
import {
  WATER_COMPANIES,
  ELECTRICITY_COMPANIES,
  GAS_COMPANIES,
  HIGHWAY_COMPANIES,
  PHONE_COMPANIES,
} from '../../common/constants/bills';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

const COMPANIES = [
  WATER_COMPANIES,
  ELECTRICITY_COMPANIES,
  GAS_COMPANIES,
  HIGHWAY_COMPANIES,
  PHONE_COMPANIES,
];

interface FormData {
  [key: string]: any;
}

const BillsPortal = (): React.ReactElement => {
  setDocumentTitle('Zafepay | Cuentas');
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [loading, setLoading] = useState<boolean>(false);
  const recaptchaRef = useRef<{ execute: () => Promise<string> }>();
  const [selectedBill, setSelectedBill] = useState<number>();
  const [formData, setFormData] = useState<FormData>({});
  const [validationError, setValidationError] = useState<boolean>(false);
  const [debtToPay, setDebtToPay] = useState<number>();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleChange = (event: any) => {
    if (selectedBill === undefined) return;
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBillChange = (bill: number) => {
    setFormData({});
    setSelectedBill(bill);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedBill === undefined) return;

    if (formData) {
      const isFormValid =
        Object.keys(formData).length == 2 &&
        Object.keys(formData).every((key: string) => {
          return formData[key].length;
        });
      if (isFormValid) {
        setValidationError(false);
        setLoading(true);
        recaptchaRef.current
          ?.execute()
          .then((token) => {
            if (debtToPay !== undefined) {
              billsApi.bills
                .payDebt(
                  { ...formData, ['debt']: debtToPay, ['utility_type']: selectedBill },
                  token
                )
                .then((data) => {
                  history.push(data.payment_link);
                })
                .catch((error: any) => {
                  console.error({ showError: error });
                  throw error;
                })
                .finally(() => {
                  setLoading(false);
                });
            } else {
              billsApi.bills
                .debt(formData, token)
                .then((data) => {
                  setDebtToPay(data.debt);
                })
                .catch((reason) => {
                  console.error({ showError: reason });
                  if (reason.response.data.message === 'no_debt')
                    setErrorMessage('No hay deuda registrada para los datos ingresados.');
                  else if (reason.response.data.message === 'invalid_data')
                    setErrorMessage(
                      'Datos ingresados inválidos. Asegúrate de ingresar bien los datos.'
                    );
                  else {
                    setErrorMessage('No pudimos obtener tu deuda, inténtalo nuevamente.');
                  }
                  setOpenPopup(true);
                })
                .finally(() => {
                  setValidationError(false);
                  setLoading(false);
                });
            }
          })
          .catch(() => enqueueSnackbar('reCAPTCHA fallido', { variant: 'error' }));
      } else {
        setValidationError(true);
      }
    }
  };

  const bills = ['Agua', 'Luz', 'Gas', 'Autopistas', 'Telefonía'];

  const BillForm =
    selectedBill !== undefined ? (
      <div className={styles.formsContainer}>
        <Fragment>
          <div className={styles.title}>
            <Typography>{bills[selectedBill]}</Typography>
          </div>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.inputContainer}>
              <Typography>Compañía de {bills[selectedBill]}*</Typography>
              <Select
                required
                disabled={debtToPay !== undefined}
                fullWidth
                id="utility_name"
                name="utility_name"
                variant="outlined"
                onChange={handleChange}
                value={formData?.utility_name || ''}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {COMPANIES[selectedBill].map((utility_name) => (
                  <MenuItem key={utility_name} value={utility_name}>
                    {utility_name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={styles.inputContainer}>
              <Typography>
                {selectedBill < 3
                  ? 'Número de cuenta* (sin puntos ni guion)'
                  : 'Rut* (con puntos y guion)'}
              </Typography>
              <TextField
                required
                disabled={debtToPay !== undefined}
                fullWidth
                id="account_number"
                name="account_number"
                type="text"
                variant="outlined"
                onChange={handleChange}
                value={formData?.account_number || ''}
              />
            </div>

            {validationError && (
              <Typography style={{ color: 'red' }}>
                Debes completar todos los campos obligatorios.
              </Typography>
            )}
            {debtToPay !== undefined && (
              <div className={styles.debt}>
                <Typography variant="h5">
                  Deuda a pagar: <strong>{formatCurrency(debtToPay)}</strong>
                </Typography>
              </div>
            )}
            <Button className={styles.submitButton} type="submit" disabled={loading}>
              {loading ? (
                <div className={styles.whiteSpinnerContainer}>
                  <CircularProgress size={'20px'} />
                </div>
              ) : (
                <Typography>{debtToPay !== undefined ? 'Pagar' : 'Buscar deuda'}</Typography>
              )}
            </Button>
          </form>
        </Fragment>
      </div>
    ) : null;

  return (
    <div className={styles.portalContainer}>
      <div className={styles.topBar}>
        <div className={styles.logo}>
          <a href="https://zafepay.com">
            <img
              src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
              alt="Logo Zafepay"
              className={styles.opLogo}
            />
          </a>
        </div>
      </div>
      <div className={styles.banner}>
        <div className={styles.title}>
          <Typography>
            Paga 💸 todas tus cuentas en un solo lugar{' '}
            <a href="https://zafepay.com">
              <span className={styles.zafepay}>zafepay.com</span>
            </a>
          </Typography>
        </div>
        <div className={styles.imageContainer}>
          <img
            src="https://storage.googleapis.com/onlypays-public/assets/images/banner_man_bills.png"
            alt="Banner image"
            className={styles.manImage}
          />
        </div>
      </div>
      <div className={styles.billsContainer}>
        <div className={styles.tabs}>
          <Typography>Servicios básicos</Typography>
        </div>
        <div className={styles.optionsContainer}>
          <div className={styles.row}>
            <div
              className={`${styles.optionButton} ${selectedBill == 0 && styles.selected}`}
              onClick={() => handleBillChange(0)}
            >
              <FontAwesomeIcon icon={faDroplet} />
              <Typography>Agua</Typography>
            </div>
            {selectedBill !== undefined && isMobile && selectedBill == 0 ? BillForm : <></>}
            <div
              className={`${styles.optionButton} ${selectedBill == 1 && styles.selected}`}
              onClick={() => handleBillChange(1)}
            >
              <FontAwesomeIcon icon={faLightbulb} />
              <Typography>Luz</Typography>
            </div>
            {selectedBill !== undefined && isMobile && selectedBill == 1 ? BillForm : <></>}
            <div
              className={`${styles.optionButton} ${selectedBill == 2 && styles.selected}`}
              onClick={() => handleBillChange(2)}
            >
              <FontAwesomeIcon icon={faGaugeSimpleHigh} />
              <Typography>Gas</Typography>
            </div>
            {selectedBill !== undefined && isMobile && selectedBill == 2 ? BillForm : <></>}
          </div>
          {selectedBill !== undefined && !isMobile && selectedBill < 3 ? BillForm : <></>}
          <div className={styles.row}>
            <div
              className={`${styles.optionButton} ${selectedBill == 3 && styles.selected}`}
              onClick={() => handleBillChange(3)}
            >
              <FontAwesomeIcon icon={faRoad} />
              <Typography>Autopistas</Typography>
            </div>
            {selectedBill !== undefined && isMobile && selectedBill == 3 ? BillForm : <></>}
            <div
              className={`${styles.optionButton} ${selectedBill == 4 && styles.selected}`}
              onClick={() => handleBillChange(4)}
            >
              <FontAwesomeIcon icon={faPhone} />
              <Typography>Telefonía</Typography>
            </div>
            {selectedBill !== undefined && isMobile && selectedBill == 4 ? BillForm : <></>}
          </div>
        </div>
        {selectedBill !== undefined && !isMobile && selectedBill >= 3 ? BillForm : <></>}
      </div>
      <div className={styles.footer}>
        <div className={styles.leftColumn}>
          <a href="https://www.zafepay.com/contacto" target="_blank" rel="noopener noreferrer">
            <Typography>¿Necesitas recaudar con nosotros?</Typography>
          </a>
          <a
            href="https://www.zafepay.com/terminos-y-condiciones"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography>Términos y condiciones</Typography>
          </a>
          <a
            href="https://www.zafepay.com/politica-de-privacidad-de-datos"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography>Política de privacidad de datos</Typography>
          </a>
          <div className={styles.logo}>
            <a href="https://zafepay.com">
              <img
                src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
                alt="Logo Zafepay"
                className={styles.logo}
              />
            </a>
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.socialIcon}>
            <a href="https://www.linkedin.com/company/zafepay/mycompany/">
              <img
                src="https://storage.googleapis.com/onlypays-public/assets/images/linkedin_blue.svg"
                alt="Linkedin Zafepay"
              />
            </a>
          </div>
          <div className={styles.socialIcon}>
            <a href="https://www.instagram.com/zafepay/">
              <img
                src="https://storage.googleapis.com/onlypays-public/assets/images/instagram_blue.svg"
                alt="Instagram Zafepay"
              />
            </a>
          </div>
          <div className={styles.socialIcon}>
            <a href="https://wa.me/56974987185">
              <img
                src="https://storage.googleapis.com/onlypays-public/assets/images/whatsapp_blue.svg"
                alt="Whatsapp Zafepay"
              />
            </a>
          </div>
        </div>
      </div>
      <Recaptcha innerRef={recaptchaRef} />
      <PopUp
        state={{ open: openPopup, setOpen: setOpenPopup }}
        title={<Typography variant="h5">Advertencia</Typography>}
        content={
          <div className={styles.popupContent}>
            <Typography>{errorMessage}</Typography>
            <Button className={styles.acceptButton} onClick={() => setOpenPopup(false)}>
              <Typography>Aceptar</Typography>
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default BillsPortal;
