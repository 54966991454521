import React, { ChangeEvent } from 'react';
import { FormikProps } from 'formik';

import { TextField } from '@mui/material';
import styles from '../../features/buyer/Payment.module.scss';
import { CardInfo } from '../../app/type';

interface KushkiCardFormProps {
  formik: FormikProps<CardInfo>;
}

const KushkiCardForm = ({ formik }: KushkiCardFormProps): React.ReactElement => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const id = e.target.id;
    if (id === 'card_number') {
      const val = e.target.value.replace(/\D/g, '').slice(0, 16);
      const groupedDigits = val.match(/.{1,4}/g);
      const formattedString = groupedDigits ? groupedDigits.join(' ') : '';
      formik.setFieldValue(id, formattedString);
    } else if (id === 'name') {
      formik.setFieldValue(id, e.target.value);
    } else if (id === 'expiration_date') {
      const val = e.target.value.replace(/\D/g, '').slice(0, 4);
      const year = val.slice(2, 4);
      const formattedString = `${val.slice(0, 2)}${year ? `/${year}` : ''}`;
      formik.setFieldValue(id, formattedString);
    } else {
      const val = e.target.value.replace(/\D/g, '').slice(0, 4);
      formik.setFieldValue(id, val);
    }
  };

  return (
    <div className={styles.cardInfoContainer}>
      <TextField
        fullWidth
        required
        id="card_number"
        className={styles.inputField}
        placeholder="Número de tarjeta"
        name="card_number"
        value={formik.values.card_number}
        onChange={(e) => {
          formik.handleChange;
          handleInputChange(e);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched.card_number && Boolean(formik.errors?.card_number)}
        helperText={formik.touched.card_number && formik.errors?.card_number}
      />
      <TextField
        fullWidth
        required
        className={styles.inputField}
        placeholder="Nombre"
        type="text"
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors?.name)}
        helperText={formik.touched.name && formik.errors?.name}
      />
      <div className={styles.row}>
        <TextField
          fullWidth
          required
          className={styles.inputField}
          placeholder="MM/YY"
          type="text"
          name="expiration_date"
          id="expiration_date"
          value={formik.values.expiration_date}
          onChange={(e) => {
            formik.handleChange;
            handleInputChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.expiration_date && Boolean(formik.errors?.expiration_date)}
          helperText={formik.touched.expiration_date && formik.errors?.expiration_date}
        />
        <TextField
          fullWidth
          required
          className={styles.inputField}
          placeholder="CVV"
          type="password"
          name="cvv"
          id="cvv"
          value={formik.values.cvv}
          onChange={(e) => {
            formik.handleChange;
            handleInputChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.cvv && Boolean(formik.errors?.cvv)}
          helperText={formik.touched.cvv && formik.errors?.cvv}
        />
      </div>
    </div>
  );
};

export default KushkiCardForm;
