import { ApiList, ApiObject, BuyerEmailConfiguration } from '../../../app/type';

import client from '../client';

export const list = (companyId: string): Promise<ApiList<BuyerEmailConfiguration>> => {
  return client({
    method: 'get',
    url: `/v1/companies/${companyId}/buyer_email_configuration`,
  })
    .then(({ data: body }: { data: ApiList<BuyerEmailConfiguration> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const update = (
  companyId: string,
  buyerEmailConfiguration: BuyerEmailConfiguration
): Promise<ApiObject<BuyerEmailConfiguration>> => {
  return client({
    method: 'post',
    url: `/v1/companies/${companyId}/update_buyer_email_configuration`,
    data: { data: buyerEmailConfiguration },
  })
    .then(({ data: body }: { data: ApiObject<BuyerEmailConfiguration> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export default {
  list,
  update,
};
