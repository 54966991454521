import client from '../client';

export interface RequestObject {
  [key: string]: string | number;
}

interface Debt {
  debt: number;
}

export const debt = (data: RequestObject, recaptcha_token: string): Promise<Debt> => {
  return client({
    method: 'post',
    url: `/v1/bill/bills/debt?recaptcha=${recaptcha_token}`,
    data: data,
  })
    .then(({ data: body }: { data: Debt }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

interface PaymentLink {
  payment_link: string;
}

export const payDebt = (data: RequestObject, recaptcha_token: string): Promise<PaymentLink> => {
  return client({
    method: 'post',
    url: `/v1/bill/bills/pay_debt?recaptcha=${recaptcha_token}`,
    data: data,
  })
    .then(({ data: body }: { data: PaymentLink }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
