import { ApiObject, Buyer, GenericObject } from '../../../app/type';

import client from '../client';

export const show = (buyerId: string, subscriptionId: string): Promise<ApiObject<Buyer>> => {
  return client({
    method: 'get',
    url: `/v1/public/buyers/${buyerId}?subscription_id=${subscriptionId}`,
  })
    .then(({ data: body }: { data: ApiObject<Buyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const extraFields = (buyerId: string, subscriptionId: string): Promise<GenericObject> => {
  return client({
    method: 'get',
    url: `/v1/public/buyers/${buyerId}/extra_fields?subscription_id=${subscriptionId}`,
  })
    .then(({ data: body }: { data: GenericObject }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
