import React from 'react';

import { sellerApi } from '../../../common/api';
import { Company, Statement } from '../../../app/type';

import ResourceList from '../../../common/components/ResourceList';

import { formatCurrency } from '../../../common/utils';

export interface PaymentsProps {
  company?: Company;
}

const Payments = (props: PaymentsProps): React.ReactElement => {
  return (
    <div>
      <ResourceList
        title="Pagos"
        queryFields="Cliente, producto"
        resourceParent={props.company}
        getResourceList={(resId, query, page, filterParams) =>
          sellerApi.statements.list(resId, query, page, {
            ...filterParams,
            status: { statement: { paid: 'activated' } },
          })
        }
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.statements.detailStatementsExcel(parentId, query, filterParams)
        }
        listHeaders={[
          { key: 'to_client', label: 'Depositado' },
          { key: 'to_invoice', label: 'Comisión medio de pago (IVA incluido)' },
          { key: 'total', label: 'Total' },
          { key: 'payment_method', label: 'Medio de pago' },
          { key: 'payment_date', label: 'Fecha de pago' },
          { key: 'buyer_name', label: 'Nombre cliente' },
          { key: 'service_name', label: 'Nombre servicio' },
        ]}
        listColumns={{
          to_client: (object: Statement) => formatCurrency(Math.ceil(object.to_client || 0)),
          to_invoice: (object: Statement) =>
            formatCurrency(Math.floor((object.to_invoice || 0) * 1.19)),
          total: (object: Statement) => formatCurrency(object.amount.toFixed(0)),
          payment_method: (object: Statement) => object.payment_type_translated,
          payment_date: (object: Statement) => object.payment_date,
          buyer_name: (object: Statement) => object.buyer?.name,
          service_name: (object: Statement) => object.service_name,
          name: (object: Statement) => object.product?.name,
        }}
        filtersKey="transparency_statements"
        listMobileHeaders={[
          { key: 'name' },
          { key: 'buyer_name' },
          { key: 'total' },
          { key: 'payment_method' },
          { key: 'payment_date' },
        ]}
      />
    </div>
  );
};

export default Payments;
