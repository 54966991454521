import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from '../../../app/hooks';
import { Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { store } from '../../../app/store';

// Types
import { ApiObject, Company, BankInformation, BillingInformation } from '../../../app/type';

// Features
import { SessionState } from '../../session/sessionSlice';
import { SellerState, setFirstSessionTags } from '../sellerSlice';
import { TITLE_ONBOARDING, SUBTITLE_ONBOARDING } from '../../../common/constants/descriptions';

// Components
import { Container, Paper, Typography, Button, Grid, Avatar, useMediaQuery } from '@mui/material';

import { CompanyForm, BankInformationForm } from './forms';

// Assets
import styles from './CompanySeller.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import { setDocumentTitle } from '../../../common/utils';

interface Step {
  [step: string]: number;
}

interface StepProps {
  state: {
    loading: boolean;
    setCurrentStep: (s: string) => void;
    setSetupInfo: (set: (s: SetupInfo) => SetupInfo) => void;
  };
  initialData?: any;
  companyId?: string;
  legalCheckbox?: { [key: string]: boolean };
  setLegalCheckbox?: (state: { [key: string]: boolean }) => void;
}

const CompanySetup = (props: StepProps): React.ReactElement => {
  const {
    state: { setCurrentStep, setSetupInfo },
    initialData,
  } = props;

  const onSuccess = (data: ApiObject<Company>) => {
    const tmpBankInformation = {
      email: data.data.email,
      name: data.data.name,
      company_id: data.data.id,
    };
    setSetupInfo((s: SetupInfo) => ({
      ...s,
      company: data.data,
      bankInformation: { ...tmpBankInformation },
    }));
    setCurrentStep('bank');
  };

  return (
    <Fragment>
      <CompanyForm initialData={initialData} actionLabel="Continuar" onSuccess={onSuccess} />
    </Fragment>
  );
};

const BankInformationSetup = (props: StepProps): React.ReactElement => {
  const {
    state: { setCurrentStep, setSetupInfo },
    initialData,
    legalCheckbox,
    setLegalCheckbox,
    companyId,
  } = props;

  const onSuccess = (data: ApiObject<BankInformation>) => {
    setSetupInfo((s: SetupInfo) => ({ ...s, bankInformation: data.data }));
    setCurrentStep('final');
  };

  return (
    <Fragment>
      <BankInformationForm
        initialData={initialData}
        actionLabel="Finalizar"
        onSuccess={onSuccess}
        legalCheckbox={legalCheckbox}
        setLegalCheckbox={setLegalCheckbox}
        companyId={companyId}
      />
    </Fragment>
  );
};

interface SetupInfo {
  company: Partial<Company>;
  bankInformation: Partial<BankInformation>;
  billingInformation: Partial<BillingInformation>;
}

const Setup = (): React.ReactElement => {
  setDocumentTitle('Setup');
  const { user } = useSelector(({ session }: { session: SessionState }) => session);
  const { loading } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const { bankInformation, billingInformation } = useSelector(
    ({ seller }: { seller: SellerState }) => seller
  );
  const [taggedSteps, setSteps] = useState<string[]>([]);
  const [legalCheckbox, setLegalCheckbox] = useState<{ [key: string]: boolean }>({
    ilicitEnd: false,
    tyc: false,
  });

  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);

  const [setupInfo, setSetupInfo] = useState<SetupInfo>({
    company: {},
    bankInformation: {},
    billingInformation: {},
  } as SetupInfo);

  useEffect(() => {
    setSetupInfo({
      company: {
        id: company?.id,
        name: company?.name || user?.name,
        email: user?.email,
        kind: company?.kind || 'company',
        business_item: company?.business_item || '',
        phone: company?.phone || 111111111,
      },
      bankInformation: bankInformation || {
        name: company?.name || user?.name,
        email: user?.email || user?.email,
        tax_id: billingInformation?.tax_id || setupInfo.billingInformation.tax_id || '',
      },
      billingInformation: billingInformation || {
        email: company?.email || '',
      },
    });
  }, [company, bankInformation]);

  const [currentStep, setCurrentStep] = useState<string>('choice');

  let title: string = TITLE_ONBOARDING['choice'];
  let subtitle: string = SUBTITLE_ONBOARDING['choice'];

  const handleOmit = () => {
    if (currentStep === 'company') {
      setCurrentStep('bank');
    } else if (currentStep === 'choice') {
      setCurrentStep('company');
    } else if (currentStep === 'bank') {
      setCurrentStep('final');
    }
  };

  useEffect(() => {
    if (currentStep === 'company' && !taggedSteps.includes('company')) {
      TagManager.dataLayer({
        dataLayer: { event: 'company_setup', userId: user?.id, trigger: true },
      });
      setSteps(['company']);
    } else if (currentStep === 'choice' && !taggedSteps.includes('choice')) {
      TagManager.dataLayer({
        dataLayer: { event: 'company_kind_choice', userId: user?.id, trigger: true },
      });
      setSteps([...'choice']);
      store.dispatch(setFirstSessionTags([true]));
    } else if (currentStep === 'bank' && !taggedSteps.includes('bank')) {
      TagManager.dataLayer({
        dataLayer: { event: 'billing_setup', userId: user?.id, trigger: true },
      });
      setSteps([...'bank']);
    }
    currentStep != 'bank' && setLegalCheckbox({ ilicitEnd: false, tyc: false });
  }, [currentStep]);

  const steps: Step = {
    choice: 20,
    company: 250,
    bank: 470,
  };

  const stepsMobile: Step = {
    choice: 30,
    company: 155,
    bank: 280,
  };

  let stepView = (
    <Typography variant="h1" mb={3}>
      NO STEP
    </Typography>
  );

  if (currentStep === 'choice') {
    stepView = (
      <Grid container className={styles.containerChoice}>
        <Grid item>
          <Typography variant="h3">{title}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">{subtitle}</Typography>
        </Grid>
        <Grid item container className={styles.containerPaperChoice}>
          <Grid item className={styles.itemPaperChoice}>
            <Paper
              className={
                setupInfo.company.kind === 'natural' ? styles.paperSelected : styles.paperChoice
              }
              onClick={() => {
                setSetupInfo({
                  bankInformation: setupInfo.bankInformation,
                  company: { ...setupInfo.company, kind: 'natural' },
                  billingInformation: setupInfo.billingInformation,
                });
              }}
            >
              <Grid container className={styles.gridLogo}>
                <Grid item>
                  <img
                    src={
                      'https://storage.googleapis.com/onlypays-public/assets/images/man%20using%20laptop%20and%20talking%20on%20the%20phone.png'
                    }
                    alt="Logo Cliente"
                    className={styles.logoChoicePersona}
                  />
                </Grid>
                <Grid item>
                  <Typography align="center" variant="h6">
                    Persona <br /> Natural
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item className={styles.itemPaperChoice}>
            <Paper
              className={
                setupInfo.company.kind === 'company' ? styles.paperSelected : styles.paperChoice
              }
              onClick={() => {
                setSetupInfo({
                  bankInformation: setupInfo.bankInformation,
                  company: { ...setupInfo.company, kind: 'company' },
                  billingInformation: setupInfo.billingInformation,
                });
              }}
            >
              <Grid container className={styles.gridLogo}>
                <Grid item>
                  <img
                    src={
                      'https://storage.googleapis.com/onlypays-public/assets/images/businessman%20in%20dark%20blue%20suit%20with%20phone%20looking%20aside.png'
                    }
                    alt="Logo Empresa"
                    className={styles.logoChoiceEmpresa}
                  />
                </Grid>
                <Typography align="center" variant="h6">
                  Empresa
                </Typography>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid item container className={styles.containerButtonChoice}>
          <Grid item xs={5}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setCurrentStep('company');
              }}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  } else if (currentStep === 'company') {
    stepView = (
      <CompanySetup
        state={{ loading, setCurrentStep, setSetupInfo }}
        initialData={{
          ...setupInfo.company,
          default_billing_information: {
            tax_id: '',
            business_name: '',
            address: '',
            commune: '',
            activity: '',
          },
        }}
      />
    );
    title = TITLE_ONBOARDING['company'];
    subtitle = SUBTITLE_ONBOARDING['company'];
  } else if (currentStep === 'bank') {
    stepView = (
      <BankInformationSetup
        state={{ loading, setCurrentStep, setSetupInfo }}
        initialData={setupInfo.bankInformation}
        legalCheckbox={legalCheckbox}
        setLegalCheckbox={setLegalCheckbox}
        companyId={company?.id}
      />
    );
    title = TITLE_ONBOARDING['bank'];
    subtitle = SUBTITLE_ONBOARDING['bank'];
  } else if (currentStep === 'final') {
    return <Redirect to={{ pathname: '/seller', state: {} }} />;
  }

  return (
    <Container className={styles.baseContainer} maxWidth={false}>
      <Container className={styles.stepperContainer} maxWidth={false}>
        <Container className={styles.stepperContainerLayout}>
          {isMobile ? (
            <Container className={styles.progressBarEmpty} sx={{ width: '308px', height: '54px' }}>
              <Container
                className={styles.progressBarFull}
                sx={{ width: `${stepsMobile[currentStep]}px`, height: '54px' }}
              ></Container>
            </Container>
          ) : (
            <Container className={styles.progressBarEmpty} sx={{ width: '94px', height: '538px' }}>
              <Container
                className={styles.progressBarFull}
                sx={{ width: '94px', height: `${steps[currentStep]}px` }}
              ></Container>
            </Container>
          )}
          <Grid container className={styles.stepperGrid}>
            <Grid item>
              <Typography
                className={currentStep === 'choice' ? styles.stepSelected : styles.stepUnSelected}
              >
                ¿Tipo de Persona?
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={currentStep === 'company' ? styles.stepSelected : styles.stepUnSelected}
              >
                Datos sobre tu Empresa
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={currentStep === 'bank' ? styles.stepSelected : styles.stepUnSelected}
              >
                Datos bancarios
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container className={styles.container} maxWidth={false}>
        <Paper className={styles.setupPaper}>
          <Grid container className={styles.gridPaperContainer}>
            {currentStep !== 'choice' ? (
              <Grid item container className={styles.gridBanner}>
                <Grid item>
                  <Avatar
                    alt="Remy Sharp"
                    src="https://storage.googleapis.com/onlypays-public/assets/images/side%20view%20of%20young%20man%20wearing%20smart%20watch%20and%20holding%20book.png"
                    className={styles.logoBanner}
                  />
                </Grid>
                <Grid item container sx={{ width: '60%' }}>
                  <Grid item>
                    <Typography variant="h3">{title}</Typography>
                    <Typography variant="h5">{subtitle}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid item container className={styles.grid}>
              <Grid item sx={{ width: '100%' }}>
                {stepView}
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {currentStep !== 'choice' ? (
                  <Grid item xs={5}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() => {
                        if (currentStep === 'company') {
                          setCurrentStep('choice');
                        } else {
                          setCurrentStep('company');
                        }
                      }}
                    >
                      Volver
                    </Button>
                  </Grid>
                ) : null}
                <Grid item xs={5}>
                  {currentStep !== 'choice' ? (
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={handleOmit}
                      disabled={
                        currentStep === 'bank' &&
                        (!legalCheckbox?.['ilicitEnd'] || !legalCheckbox?.['tyc'])
                      }
                    >
                      Completar luego
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Container>
  );
};

export default Setup;
