import { Container, Grid, Modal, Paper } from '@mui/material';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import styles from './PopUp.module.scss';

interface PopUpProps {
  state: {
    open: boolean;
    setOpen: (s: boolean) => void;
  };
  title?: React.ReactElement;
  content: React.ReactElement;
  src?: string;
  extraActions?: React.ReactElement[];
}

export const PopUp = ({
  state,
  title,
  content,
  src,
  extraActions,
}: PopUpProps): React.ReactElement => {
  return (
    <Modal open={state.open} onClose={() => state.setOpen(false)}>
      <Paper className={styles.popUpPaper}>
        <div className={styles.closePopup} onClick={() => state.setOpen(false)}>
          <FontAwesomeIcon icon={faXmark} style={{ color: '#5f5f5f' }} />
        </div>
        <Grid container>
          {src ? (
            <Grid item xs={12} className={styles.gridImage}>
              <Paper className={styles.paperImage}>
                <Container
                  className={styles.containerImage}
                  sx={{ backgroundImage: `url(${src})` }}
                ></Container>
              </Paper>
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            className={styles.contentGrid}
            sx={{ marginTop: `${src ? '-40px' : 'auto'}` }}
          >
            {title || ''}
            {content}
          </Grid>
          <Grid item xs={12} className={styles.buttonGrid}>
            {extraActions}
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};
export default PopUp;
