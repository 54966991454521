import React from 'react';
import { useSnackbar } from 'notistack';

import { sellerApi } from '../../../common/api';
import { Company, Transfer } from '../../../app/type';

import ResourceList from '../../../common/components/ResourceList';
import { formatCurrency } from '../../../common/utils';
import StatusLabel from '../../../common/components/StatusLabel';

export interface TransfersProps {
  company?: Company;
}

const Transfers = (props: TransfersProps): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const onDownloadExcel = (object: Transfer) => {
    sellerApi.transfers
      .downloadExcel(object.id)
      .then(() => {
        enqueueSnackbar('Te enviaremos un correo.', { variant: 'success' });
      })
      .catch((error: any) => {
        console.error(error);
        enqueueSnackbar('Ha ocurrido un error, intenta más tarde.', { variant: 'error' });
      });
  };

  return (
    <div>
      <ResourceList
        title="Transferencias"
        queryFields=""
        resourceParent={props.company}
        getResourceList={sellerApi.transfers.list}
        excelDownloadMethod={(_, parentId) => sellerApi.transfers.generateExcel(parentId)}
        listHeaders={[
          { key: 'amount', label: 'Monto total' },
          { key: 'status', label: 'Estado' },
          { key: 'created_at', label: 'Fecha de depósito' },
        ]}
        listColumns={{
          amount: (object) => formatCurrency(object.amount),
          status: (object) => <StatusLabel status={object.status} type="transfer" />,
          due_date: (object) => object.created_at,
          name: (object) => formatCurrency(object.amount),
        }}
        listActionsHeaders={(object) => {
          if (object) {
            return [{ key: 'download_excel', label: 'Enviar detalle' }];
          }
          return [null];
        }}
        listActions={{
          download_excel: (object) => onDownloadExcel(object),
        }}
        hideQuery
        listMobileHeaders={[{ key: 'name' }, { key: 'created_at' }, { key: 'status' }]}
      />
    </div>
  );
};

export default Transfers;
