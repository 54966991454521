import {
  ApiObject,
  ApiList,
  Product,
  Subscription,
  ExtraField,
  SinglePayment,
} from '../../../app/type';

import client from '../client';

interface CreateData {
  product: Partial<Product>;
}
export const create = (data: CreateData): Promise<ApiObject<Product>> => {
  const formData = new FormData();
  Object.keys(data.product).map((key) => {
    if (key == 'image_file') {
      // @ts-expect-error: ts(7053)
      formData.append('product[image_file]', data.product[key], 'image.png');
    } else {
      // @ts-expect-error: ts(7053)
      formData.append(`product[${key}]`, data.product[key]);
    }
  });
  return client
    .post('/v1/products', formData)
    .then(({ data: body }: { data: ApiObject<Product> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ createError: error });
      throw error;
    });
};

interface UpdateData {
  product: Partial<Product>;
}
export const update = (data: UpdateData): Promise<ApiObject<Product>> => {
  const formData = new FormData();
  Object.keys(data.product).map((key) => {
    if (key == 'image_file') {
      // @ts-expect-error: ts(7053)
      formData.append('product[image_file]', data.product[key], 'image.png');
    } else {
      // @ts-expect-error: ts(7053)
      formData.append(`product[${key}]`, data.product[key]);
    }
  });
  return client
    .put(`/v1/products/${data.product.id}`, formData)
    .then(({ data: body }: { data: ApiObject<Product> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ createError: error });
      throw error;
    });
};

export const list = (
  companyId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: any }
): Promise<ApiList<Product>> => {
  return client({
    method: 'get',
    url: `/v1/products`,
    params: { company_id: companyId, query, page: page || 1, filter_params: filterParams },
  })
    .then(({ data: body }: { data: ApiList<Product> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const show = (productId: string): Promise<ApiObject<Product>> => {
  return client({
    method: 'get',
    url: `/v1/products/${productId}`,
  })
    .then(({ data: body }: { data: ApiObject<Product> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const destroy = (productId: string): Promise<ApiObject<Product>> => {
  return client({
    method: 'delete',
    url: `/v1/products/${productId}`,
  })
    .then(({ data: body }: { data: ApiObject<Product> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ destroyError: error });
      throw error;
    });
};

export const showBoxes = (productId: string): Promise<{ [key: string]: number }> => {
  return client({
    method: 'get',
    url: `/v1/products/${productId}/show_boxes_data`,
  })
    .then(({ data: body }: { data: { [key: string]: number } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ destroyError: error });
      throw error;
    });
};

export const indexBoxes = (companyId: string): Promise<{ [key: string]: number }> => {
  return client({
    method: 'get',
    url: `/v1/products/index_boxes_data`,
    params: { company_id: companyId },
  })
    .then(({ data: body }: { data: { [key: string]: number } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ destroyError: error });
      throw error;
    });
};

export const subscriptions = (
  productId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<Subscription>> => {
  return client({
    method: 'get',
    url: `/v1/products/${productId}/subscriptions`,
    params: { query, page: page || 1, filter_params: filterParams },
  })
    .then(({ data: body }: { data: ApiList<Subscription> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscriptionsError: error });
      throw error;
    });
};

export const singlePayments = (
  productId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<SinglePayment>> => {
  return client({
    method: 'get',
    url: `/v1/products/${productId}/single_payments`,
    params: { query, page: page || 1, filter_params: filterParams },
  })
    .then(({ data: body }: { data: ApiList<SinglePayment> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ singlePayments: error });
      throw error;
    });
};

export const questions = (buyerId: string): Promise<ApiList<ExtraField>> => {
  return client({
    method: 'get',
    url: `v1/products/${buyerId}/questions`,
  })
    .then(({ data: body }: { data: ApiList<ExtraField> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ questionsError: error });
      throw error;
    });
};

export const generateExcel = (
  companyId?: string,
  query?: string,
  filterParams?: { [key: string]: any }
): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/products/generate_excel`,
    params: { company_id: companyId, query: query, filter_params: filterParams },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ changeStatusError: error });
      throw error;
    });
};
