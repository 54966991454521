import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../app/type';

export interface SessionState {
  logged: boolean;
  user: User | null;
  loading: boolean;
  backofficeLogged: boolean;
  clientLogged: boolean;
}

const initialState: SessionState = {
  logged: false,
  user: null,
  loading: false,
  backofficeLogged: false,
  clientLogged: false,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    loginSuccess: (state: SessionState, action: PayloadAction<{ user: User }>) => {
      state.user = action.payload.user;
      state.logged = true;
      state.loading = false;
      state.backofficeLogged = false;
    },
    loginError: (state: SessionState) => {
      state.user = null;
      state.logged = false;
      state.loading = false;
      state.backofficeLogged = false;
    },
    updateToken: (state: SessionState, action: PayloadAction<User['accessToken']>) => {
      if (state.user !== null) {
        state.user.accessToken = action.payload;
      }
      state.loading = false;
    },
    updateUser: (state: SessionState, action: PayloadAction<{ user: Partial<User> }>) => {
      if (state.user !== null) {
        state.user = { ...state.user, ...action.payload.user };
      }
      state.loading = false;
    },
    logoutUser: (state: SessionState) => {
      state.user = null;
      state.logged = false;
      state.loading = false;
      state.backofficeLogged = false;
    },
    startLoading: (state: SessionState) => {
      state.loading = true;
    },
    stopLoading: (state: SessionState) => {
      state.loading = false;
    },
    backofficeLoginSuccess: (state: SessionState, action: PayloadAction<{ user: User }>) => {
      state.user = action.payload.user;
      state.backofficeLogged = true;
      state.loading = false;
      state.logged = false;
    },
    backofficeLoginError: (state: SessionState) => {
      state.user = null;
      state.backofficeLogged = false;
      state.loading = false;
      state.logged = false;
    },
    backofficeLogoutUser: (state: SessionState) => {
      state.user = null;
      state.backofficeLogged = false;
      state.loading = false;
      state.logged = false;
    },
    clientLoginSuccess: (state: SessionState) => {
      state.clientLogged = true;
      state.loading = false;
    },
    clientLogoutSession: (state: SessionState) => {
      state.loading = false;
      state.clientLogged = false;
    },
  },
});

export const {
  loginSuccess,
  loginError,
  updateToken,
  updateUser,
  logoutUser,
  startLoading,
  stopLoading,
  backofficeLoginSuccess,
  backofficeLoginError,
  backofficeLogoutUser,
  clientLoginSuccess,
  clientLogoutSession,
} = sessionSlice.actions;

export default sessionSlice.reducer;
