import React, { useEffect, useState, Fragment } from 'react';
import { Grid, Typography, Container } from '@mui/material';
import InfoBox from '../../common/components/InfoBox';

import { formatCurrency, setDocumentTitle } from '../../common/utils';

// API
import { backofficeApi } from '../../common/api';

// Types
import { ApiObject, ChartData, DashboardBoxes as DashboardBoxesData } from '../../app/type';
import variables from '../../common/styles/variables.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBuilding } from '@fortawesome/free-regular-svg-icons';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';
import { ValueType, NameType } from 'recharts/src/component/DefaultTooltipContent';

// Styles
import styles from './Backoffice.module.scss';

const CustomTooltip = ({ active, label, payload }: TooltipProps<ValueType, NameType>) => {
  if (active)
    return (
      <div style={{ backgroundColor: variables.simplewhite, padding: '5px' }}>
        <Typography variant="body1">{label}</Typography>
        {payload?.map((object) => (
          <Fragment key={object.dataKey}>
            {object.dataKey == 'gathered_amount' && (
              <Typography variant="body1" color={object.color}>
                {`Monto recaudado: ${formatCurrency(object.value as number)}`}
              </Typography>
            )}
            {object.dataKey == 'new_companies' && (
              <Typography variant="body1" color={object.color}>
                {`Empresas nuevas: ${object.value}`}
              </Typography>
            )}
            {object.dataKey == 'active_companies' && (
              <Typography variant="body1" color={object.color}>
                {`Empresas activas: ${object.value}`}
              </Typography>
            )}
            {object.dataKey == 'amount' && (
              <Typography variant="body1" color={object.color}>
                {`Total recaudado: ${formatCurrency(object.value as number)}`}
              </Typography>
            )}
          </Fragment>
        ))}
      </div>
    );
  return null;
};

const Dashboard = (): React.ReactElement => {
  setDocumentTitle('Backoffice Zafepay 🤘');
  const [boxesData, setboxesData] = useState<DashboardBoxesData>();
  const [salesChart, setSalesChart] = useState<{ [key: string]: string | number }[]>();
  const [paymentsChart, setPaymentsChart] = useState<{ [key: string]: string | number }[]>();

  useEffect(() => {
    backofficeApi.dashboard().then((data: ApiObject<DashboardBoxesData>) => {
      setboxesData(data.data);
    });
    backofficeApi
      .salesChart()
      .then((data: ChartData) => setSalesChart(data.data))
      .catch((error: any) => console.error(error));
    backofficeApi.paymentTypesAmountsChart().then((data: ChartData) => setPaymentsChart(data.data));
  }, []);

  const formatYAxis = (value: number): string => {
    return formatCurrency(value);
  };

  return (
    <Fragment>
      <Grid container flexWrap={'nowrap'}>
        <InfoBox
          title="Empresas Naturales"
          kind="info2"
          data={boxesData?.natural_companies}
          style={styles.firstInfoContainer}
          icon={<FontAwesomeIcon icon={faUser} />}
          trend={boxesData?.natural_companies_trend}
        />
        <InfoBox
          title="Empresas No Naturales"
          kind="info2"
          data={boxesData?.regular_companies}
          style={styles.secondInfoContainer}
          icon={<FontAwesomeIcon icon={faBuilding} />}
          trend={boxesData?.regular_companies_trend}
        />
        <InfoBox
          title="Recaudado en el mes"
          kind="info2"
          data={formatCurrency(boxesData?.onlypays_collected)}
          style={styles.thirdInfoContainer}
          icon={<FontAwesomeIcon icon={faDollarSign} />}
          trend={boxesData?.onlypays_collected_trend}
          trendCurrency="%"
        />
      </Grid>
      <Container className={styles.chart}>
        <ResponsiveContainer>
          <BarChart width={730} height={250} data={salesChart}>
            <Tooltip content={<CustomTooltip />} />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis width={80} yAxisId="left" tickFormatter={formatYAxis} orientation="left" />
            <YAxis yAxisId="right" orientation="right" />
            <Tooltip />
            <Bar yAxisId="left" dataKey="gathered_amount" fill={variables.pending} />
            <Bar yAxisId="right" dataKey="new_companies" fill={variables.success} />
            <Bar yAxisId="right" dataKey="active_companies" fill={variables.warning} />
          </BarChart>
        </ResponsiveContainer>
      </Container>
      <Container className={styles.chart}>
        <ResponsiveContainer>
          <BarChart width={730} height={250} data={paymentsChart}>
            <Tooltip content={<CustomTooltip />} />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis width={80} tickFormatter={formatYAxis} orientation="left" />
            <Tooltip />
            <Bar dataKey="amount" fill={variables.pending} />
          </BarChart>
        </ResponsiveContainer>
      </Container>
    </Fragment>
  );
};

export default Dashboard;
