import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '../../../app/hooks';
import { SellerState } from '../sellerSlice';

// Components
import { Grid, Paper, Typography } from '@mui/material';
import ShippingOptions from '../../../common/components/ShippingOptions';

import { useSnackbar } from 'notistack';
import { sellerApi } from '../../../common/api';

// Assets
import styles from './CompanySeller.module.scss';
import cStyles from '../../../common/styles/common.module.scss';
import { setDocumentTitle } from '../../../common/utils';
import { ShippingConfiguration } from '../../../app/type';

const DispatchSetup = (): React.ReactElement => {
  setDocumentTitle('Configuración despachos');
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const history = useHistory();
  const [shippingConfiguration, setShippingConfiguration] = useState<ShippingConfiguration>();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const submitChanges = (regions: string[], communes: { [key: string]: string[] }) => {
    if (shippingConfiguration) {
      setLoading(true);
      sellerApi.companies
        .updateShippingConfiguration(company?.id || '', {
          data: { regions: regions, communes_by_region: communes },
        })
        .then((data) => {
          setShippingConfiguration(data);
          enqueueSnackbar('Información actualizada correctamente', { variant: 'success' });
        })
        .catch((err: any) => {
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (!company) {
      history.replace('/seller/home');
    } else if (company) {
      sellerApi.companies.shippingConfiguration(company?.id).then((data) => {
        setShippingConfiguration(data);
      });
    }
  }, [company?.id]);

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Paper className={styles.infoPaper}>
          <Grid container className={styles.mainContainer}>
            <Grid item xs={12} className={cStyles.paperHeader}>
              <div className={cStyles.paperHeaderContent}>
                <Typography variant="h5">Configuración Despachos</Typography>
                <Typography>
                  En esta sección puedes definir las regiones y comunas a las cuales realizas
                  despacho. Para que tus clientes completen su dirección de despacho debes pedirla
                  en cada uno de los servicios que ofrezcas al momento de crearlos.
                </Typography>
              </div>
            </Grid>
            <div>
              <ShippingOptions
                regions={shippingConfiguration?.regions}
                communes_by_region={shippingConfiguration?.communes_by_region}
                submitCompany={submitChanges}
                loading={loading}
              />
            </div>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DispatchSetup;
