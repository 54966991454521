import client from '../client';
import { ApiList, BillingInformation, ApiObject } from '../../../app/type';

export const index = (companyId?: string): Promise<ApiList<BillingInformation>> => {
  return client({
    method: 'get',
    url: `/v1/billing_informations?company_id=${companyId}`,
  })
    .then(({ data: body }: { data: ApiList<BillingInformation> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

interface CreateData {
  data: Partial<BillingInformation>;
  set?: boolean;
}
export const create = (data: CreateData): Promise<ApiObject<BillingInformation>> => {
  return client({
    method: 'post',
    url: '/v1/billing_informations',
    data,
  })
    .then(({ data: body }: { data: ApiObject<BillingInformation> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ createError: error });
      throw error;
    });
};

interface UpdateData {
  data: Partial<BillingInformation>;
  set?: boolean;
}
export const update = (data: UpdateData): Promise<ApiObject<BillingInformation>> => {
  return client({
    method: 'put',
    url: `/v1/billing_informations/${data.data.id}`,
    data,
  })
    .then(({ data: body }: { data: ApiObject<BillingInformation> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const destroy = (bankInformationId?: string): Promise<ApiObject<BillingInformation>> => {
  return client({
    method: 'delete',
    url: `/v1/billing_informations/${bankInformationId}`,
  })
    .then(({ data: body }: { data: ApiObject<BillingInformation> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export default {
  index,
  create,
  update,
  destroy,
};
