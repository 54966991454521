import axios from 'axios';

// Constants

let API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';
if (process.env.NODE_ENV === 'production') {
  API_URL = process.env.REACT_APP_API_URL || 'https://api.zafepay.com/api';
} else if (process.env.NODE_ENV === 'development') {
  API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';
} else {
  API_URL = process.env.REACT_APP_API_URL || 'https://api.staging.zafepay.com/api';
}

let FRONT_URL = process.env.REACT_APP_FRONT_URL || 'http://localhost:8080';
if (process.env.NODE_ENV === 'production') {
  FRONT_URL = process.env.REACT_APP_FRONT_URL || 'https://app.zafepay.com';
} else if (process.env.NODE_ENV === 'development') {
  FRONT_URL = process.env.REACT_APP_FRONT_URL || 'http://localhost:8080';
} else {
  FRONT_URL = process.env.REACT_APP_FRONT_URL || 'https://staging.zafepay.com';
}

export { API_URL, FRONT_URL };

// Features
import { store } from '../../app/store';
import { updateToken } from '../../features/session/sessionSlice';
import { clientLogout, logout } from './sessions';

const authHeaders = () => {
  const {
    session: { logged, backofficeLogged, user, clientLogged },
  } = store.getState();
  const {
    client: { client },
  } = store.getState();
  if (clientLogged) return { Authorization: client?.auth_code };
  if (!(logged || backofficeLogged) || !user?.accessToken) return {};
  return { Authorization: user.accessToken };
};

export const client = axios.create({
  baseURL: API_URL,
});

// Adds auth headers to every request (when possible)
client.interceptors.request.use((config: any) => {
  config.headers = {
    ...config.headers,
    ...authHeaders(),
  };
  return config;
});

const interceptResponse = (response: any) => {
  if (response.headers?.authorization) {
    const {
      session: { logged, user },
    } = store.getState();
    if (logged && user?.accessToken !== response.headers.authorization) {
      store.dispatch(updateToken(response.headers.authorization));
    }
  }
  return response;
};

const interceptError = (error: any) => {
  if (!axios.isCancel(error) && !error.response) {
    console.error('Ha ocurrido un problema, revisa tu conexión a internet');
  } else if (error.response?.status === 401) {
    const {
      session: { clientLogged },
    } = store.getState();
    if (error.config.url === '/auth/sign_out') return;
    if (clientLogged) {
      clientLogout();
    } else {
      logout().catch(() => 0);
    }
  } else if (error.response?.status === 403) {
    console.error('No estás autorizado para realizar esta acción');
  }
  return Promise.reject(error);
};

// Catch connection and auth errors
client.interceptors.response.use(interceptResponse, interceptError);

export default client;
