import React from 'react';

// Types
import { ApiPagination } from '../../app/type';

import { IconButton, MenuItem, Typography } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ChevronsLeft as DoubleChevronLeftIcon,
  ChevronsRight as DoubleChevronRightIcon,
} from 'react-feather';

import styles from './Pagination.module.scss';

interface PaginationProps {
  page?: ApiPagination;
  disabled?: boolean;
  onChange: (p: number) => void;
  ShowOnSingle?: boolean;
  isMobile?: boolean;
}
export const Pagination = ({
  page,
  onChange,
  disabled,
  ShowOnSingle,
  isMobile,
}: PaginationProps): React.ReactElement | null => {
  const current = page?.current || 1;
  const pages = page?.pages || 1;
  const canPrev = !disabled && current - 1 > 0;
  const canNext = !disabled && current + 1 <= pages;

  const onPrevPage = () => canPrev && onChange(current - 1);
  const onNextPage = () => canNext && onChange(current + 1);
  const onFirstPage = () => canPrev && onChange(1);
  const onLastPage = () => canNext && onChange(pages);

  const handlePageSelect = (e: SelectChangeEvent) => {
    onChange(parseInt(e.target.value));
  };

  if (pages === 1 && current === 1 && !ShowOnSingle) return null;

  const options = Array.from({ length: pages }, (_, i) => (
    <MenuItem key={i + 1} value={(i + 1).toString()}>
      {i + 1}
    </MenuItem>
  ));

  return (
    <div className={`${!isMobile ? styles.pagination : styles.mobilePagination}`}>
      <IconButton
        size="small"
        className={`${!isMobile ? styles.button : styles.mobileButton}`}
        onClick={onFirstPage}
        disabled={!canPrev}
      >
        <DoubleChevronLeftIcon />
      </IconButton>
      <IconButton
        size="small"
        className={`${!isMobile ? styles.button : styles.mobileButton}`}
        onClick={onPrevPage}
        disabled={!canPrev}
      >
        <ChevronLeftIcon />
      </IconButton>
      <div className={`${!isMobile ? styles.counter : styles.mobileCounter}`}>
        <Select
          value={current.toString()}
          onChange={handlePageSelect}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {options}
        </Select>
        <Typography>de {pages}</Typography>
      </div>
      <IconButton
        size="small"
        className={`${!isMobile ? styles.button : styles.mobileButton}`}
        onClick={onNextPage}
        disabled={!canNext}
      >
        <ChevronRightIcon />
      </IconButton>
      <IconButton
        size="small"
        className={`${!isMobile ? styles.button : styles.mobileButton}`}
        onClick={onLastPage}
        disabled={!canNext}
      >
        <DoubleChevronRightIcon />
      </IconButton>
    </div>
  );
};

export default Pagination;
