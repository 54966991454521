import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from '../../../app/hooks';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { es } from 'yup-locales';

yup.setLocale(es);

// Features
import { SessionState } from '../../session/sessionSlice';

// API
import { sessionsApi } from '../../../common/api';

// Components
import {
  Container,
  CircularProgress,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
} from '@mui/material';

// Assets
import cStyles from '../../../common/styles/common.module.scss';

const UpdateSchema = yup.object().shape({
  password: yup.string().required().label('Contraseña actual'),
  new_password: yup.string().min(8).max(32).required().label('Nueva contraseña'),
  confirmation: yup
    .string()
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('new_password')], 'Contraseñas no coinciden'),
    })
    .required()
    .label('Confirmación contraseña'),
});

interface UpdateInfo {
  password: string;
  new_password: string;
  confirmation: string;
}

const UpdatePassword = (): React.ReactElement => {
  const { loading } = useSelector(({ session }: { session: SessionState }) => session);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const formik = useFormik<UpdateInfo>({
    initialValues: { password: '', new_password: '', confirmation: '' },
    validationSchema: UpdateSchema,
    onSubmit: (updateInfo: UpdateInfo, { setErrors }: any) => {
      sessionsApi
        .updatePassword({ password: updateInfo.password, new_password: updateInfo.new_password })
        .then((): void => {
          enqueueSnackbar('Contraseña actualizada', { variant: 'info' });
          history.push('/');
        })
        .catch((err: any): void => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        });
    },
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={cStyles.infoPaper}>
          {/* HEADER */}
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={12} className={cStyles.paperHeader}>
              <div className={cStyles.paperHeaderContent}>
                <Typography variant="h5" sx={{ textAlign: 'center' }}>
                  Cambiar contraseña
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Container maxWidth="sm">
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="password"
                    label="Contraseña actual"
                    type="password"
                    variant="outlined"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="new_password"
                    label="Nueva contraseña"
                    type="password"
                    variant="outlined"
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                    error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                    helperText={formik.touched.new_password && formik.errors.new_password}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="confirmation"
                    label="Confirma contraseña"
                    type="password"
                    variant="outlined"
                    value={formik.values.confirmation}
                    onChange={formik.handleChange}
                    error={formik.touched.confirmation && Boolean(formik.errors.confirmation)}
                    helperText={formik.touched.confirmation && formik.errors.confirmation}
                  />
                </Grid>

                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                    className="loader"
                  >
                    {loading && <CircularProgress size={20} />}
                    Cambiar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UpdatePassword;
