import variables from '../styles/variables.module.scss';

export const STATUS = [
  ['up_to_date', 'Al día'],
  ['in_debt', 'En deuda'],
  ['canceled', 'Cancelado'],
  ['inactive', 'Inactivo'],
  ['finished', 'Finalizado'],
];

export const STATUS_COLORS = Object.fromEntries([
  ['up_to_date', variables.success],
  ['in_debt', variables.warning],
  ['canceled', variables.error],
  ['inactive', variables.pending],
  ['finished', variables.lightygray],
]);

export const STATUS_ES = Object.fromEntries(STATUS);
