import { ApiObject, Company, Payable } from '../../../app/type';

import client from '../client';

export const show = (
  productId: string,
  params?: { [key: string]: any }
): Promise<ApiObject<Payable>> => {
  return client({
    method: 'get',
    url: `/v1/public/products/${productId}`,
    params: params,
  })
    .then(({ data }: { data: ApiObject<Payable> }) => {
      return data;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const productCompany = (productId: string): Promise<ApiObject<Company>> => {
  return client({
    method: 'get',
    url: `/v1/public/products/${productId}/company`,
  })
    .then(({ data }: { data: ApiObject<Company> }) => {
      return data;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export default { show, productCompany };
