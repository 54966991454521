import React, { useEffect, useState } from 'react';

import { Notification } from '../../app/type';

import { Avatar, Grid, Paper, Typography } from '@mui/material';
import { X as RemoveIcon } from 'react-feather';

import styles from '../styles/components/Notification.module.scss';

import variables from '../styles/variables.module.scss';

interface NotificationProps {
  notification: Notification;
  onRemove: (event?: any) => void;
  clientModule?: boolean;
  onNotificationClick?: (id: number) => void;
}

let timerEntrance: ReturnType<typeof setTimeout>;
let timerExit: ReturnType<typeof setTimeout>;

const NotificationBox = (props: NotificationProps): React.ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [closed, setClosed] = useState<boolean>(true);

  useEffect(() => {
    timerEntrance = setTimeout(() => {
      setOpen(true);
      setClosed(false);
    }, 333);

    return () => {
      clearTimeout(timerExit);
      clearTimeout(timerEntrance);
    };
  }, []);

  return (
    <Paper
      className={`${styles.paperNotification} ${open && styles.paperNotificationOpen}  ${
        closed && styles.paperNotificationClosed
      } ${props.clientModule && styles.clientStyles}`}
    >
      <Grid container sx={{ m: '10px' }}>
        <Grid item container spacing={2} wrap={'nowrap'} sx={{ width: '80%' }}>
          <div
            className={styles.divGridContainer}
            style={{ cursor: props.onNotificationClick ? 'pointer' : 'default' }}
            onClick={() => {
              props.onNotificationClick?.(props.notification.id);
              setOpen(false);
            }}
          >
            {!props.clientModule && (
              <Grid item className={styles.avatarGrid}>
                <Avatar
                  src="https://storage.googleapis.com/onlypays-public/assets/images/side%20view%20of%20young%20man%20wearing%20smart%20watch%20and%20holding%20book.png"
                  className={styles.logoBanner}
                  sx={{ bgcolor: variables[props.notification.variant] }}
                ></Avatar>
              </Grid>
            )}
            <Grid item>
              <Typography variant="h5">
                <b>NOTIFICACIÓN</b>
              </Typography>
              <Typography variant="h6">{props.notification.content}</Typography>
            </Grid>
          </div>
        </Grid>
        <Grid item className={styles.removeGrid}>
          <RemoveIcon
            onClick={() => {
              setOpen(false);
              timerExit = setTimeout(() => {
                setClosed(true);
                props.onRemove(props.notification.id);
              }, 333); // variables.animtime
            }}
            className={styles.removeNotification}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NotificationBox;
