import { ApiList, ApiObject, SinglePaymentBuyer, Statement, Email } from '../../../app/type';

import client from '../client';

export const destroySinglePaymentBuyer = (
  singlePaymentBuyerId: string
): Promise<ApiObject<SinglePaymentBuyer>> => {
  return client({
    method: 'delete',
    url: `/v1/single_payment_buyers/${singlePaymentBuyerId}`,
  })
    .then(({ data: body }: { data: ApiObject<SinglePaymentBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const statements = (
  singlePaymentBuyerId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<Statement>> => {
  return client({
    method: 'get',
    url: `/v1/single_payment_buyers/${singlePaymentBuyerId}/statements`,
    params: { query, page: page, filter_params: filterParams || {} },
  })
    .then(({ data: body }: { data: ApiList<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const sendChargeEmail = (singlePaymentBuyerId: string): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/single_payment_buyers/${singlePaymentBuyerId}/send_charge_email`,
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const checkChargeEmail = (singlePaymentBuyerId: string): Promise<Email> => {
  return client({
    method: 'get',
    url: `/v1/single_payment_buyers/${singlePaymentBuyerId}/check_charge_email`,
  })
    .then(({ data: body }: { data: Email }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};
