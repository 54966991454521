import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import { useQuery } from '../../common/utils';

interface CardsSuccessProps {
  loadCardsAndPacs: () => void;
}

const CardSuccess = ({ loadCardsAndPacs }: CardsSuccessProps): React.ReactElement => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();

  useEffect(() => {
    if (query.kind) {
      if (query.status === 'accepted') {
        history.replace('/client/cards');
        enqueueSnackbar(`Te notificaremos en 24-48 horas a tu correo el estado de tu PAC.`, {
          variant: 'success',
          autoHideDuration: null,
        });
      } else {
        history.replace('/client/cards');
        enqueueSnackbar(`Ocurrió un error al procesas el PAC. Inténtalo nuevamente`, {
          variant: 'error',
          autoHideDuration: null,
        });
      }
    } else if (query.status) {
      if (query.status === 'active') {
        loadCardsAndPacs();
        history.replace('/client/cards');
        enqueueSnackbar(`Tarjeta creada exitosamente`, {
          variant: 'success',
          autoHideDuration: null,
        });
      } else {
        history.replace('/client/cards');
        enqueueSnackbar(`Ocurrió un error al crear la tarjeta`, {
          variant: 'error',
          autoHideDuration: null,
        });
      }
    } else {
      loadCardsAndPacs();
      history.replace('/client/cards');
      enqueueSnackbar(`Tarjeta eliminada exitosamente`, {
        variant: 'success',
        autoHideDuration: null,
      });
    }
  }, []);

  return <></>;
};

export default CardSuccess;
