import React, { Fragment, useEffect, useState } from 'react';

// Components
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material';

// Assets
import styles from './ShippingOptions.module.scss';
import { REGIONS_ARRAY as REGIONS } from '../constants/regions';
import { COMMUNES_BY_REGION } from '../constants/communes';

interface ShippingOptionsProps {
  regions: string[] | undefined;
  communes_by_region: { [key: string]: string[] } | undefined;
  setConfiguration?: (regions: string[], communes: { [key: string]: string[] }) => void;
  submitCompany?: (regions: string[], communes: { [key: string]: string[] }) => void;
  loading?: boolean;
  cancelUpdateShipping?: () => void;
}

const ShippingOptions = ({
  regions,
  communes_by_region,
  setConfiguration,
  submitCompany,
  loading,
  cancelUpdateShipping,
}: ShippingOptionsProps): React.ReactElement => {
  const [tab, setTab] = useState<number>(0);
  const [regionsState, setRegionsState] = useState<string[]>([]);
  const [communesByRegions, setCommunesByRegion] = useState<{ [key: string]: string[] }>({});

  useEffect(() => {
    setRegionsState(regions || REGIONS);
    setCommunesByRegion(communes_by_region || COMMUNES_BY_REGION);
  }, [regions, communes_by_region]);

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const changeRegionSelect = (checked: boolean, region: string) => {
    if (checked) {
      setRegionsState((prevRegions) => [...prevRegions, region]);
      setCommunesByRegion((prevCommunes) => ({
        ...prevCommunes,
        [region]: communes_by_region?.[region] || COMMUNES_BY_REGION[region],
      }));
    } else {
      setRegionsState((prevRegions) => prevRegions.filter((reg) => reg !== region));
      setCommunesByRegion((prevCommunes) => {
        const { [region]: _, ...rest } = prevCommunes || {};
        return rest;
      });
    }
  };

  const changeCommuneSelect = (checked: boolean, commune: string) => {
    const region = regionByCommune(commune);
    setCommunesByRegion((prevState) => {
      const currentCommunes = prevState[region] || [];
      const updatedCommunes = checked
        ? [...currentCommunes, commune]
        : currentCommunes.filter((c) => c !== commune);
      return {
        ...prevState,
        [region]: updatedCommunes,
      };
    });
  };

  const regionByCommune = (commune: string): string => {
    return (
      Object.keys(COMMUNES_BY_REGION).find((region) =>
        COMMUNES_BY_REGION[region]?.includes(commune)
      ) || ''
    );
  };

  const saveChanges = () => {
    if (submitCompany) submitCompany(regionsState, communesByRegions);
  };

  return (
    <div className={styles.mainContainer}>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label="Regiones" />
        <Tab label="Comunas" />
      </Tabs>
      <div hidden={tab !== 0}>
        <Typography mt={2} mb={1}>
          Selecciona las regiones a las cuales ofreces despacho.
        </Typography>
        <div className={styles.regionsContainer}>
          {REGIONS.map((region) => (
            <div key={region} className={styles.defaultFieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={region}
                    onChange={(e, checked) => changeRegionSelect(checked, e.target.value)}
                    checked={!!regionsState?.includes(region)}
                  />
                }
                label={region}
                labelPlacement="end"
              />
            </div>
          ))}
        </div>
      </div>
      <div hidden={tab !== 1}>
        <Typography mt={2}>
          Selecciona las comunas que a las cuales haces despacho de las regiones que seleccionaste.
        </Typography>
        <div className={styles.container}>
          {regionsState.map((region) => (
            <Fragment key={region}>
              <Typography variant="h6" mt={1}>
                {region}
              </Typography>
              <div className={styles.communesContainer}>
                {COMMUNES_BY_REGION[region].map((commune) => (
                  <div key={commune} className={styles.communeRow}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={commune}
                          onChange={(e, checked) => changeCommuneSelect(checked, e.target.value)}
                          checked={!!communesByRegions?.[region]?.includes(commune)}
                        />
                      }
                      label={commune}
                      labelPlacement="end"
                    />
                  </div>
                ))}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
      {setConfiguration ? (
        <Grid
          item
          xs={12}
          mt={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Button key={1} variant="outlined" onClick={cancelUpdateShipping}>
            Cancelar
          </Button>
          <Button
            key={2}
            variant="contained"
            type="submit"
            onClick={() => setConfiguration(regionsState, communesByRegions)}
          >
            Continuar
          </Button>
        </Grid>
      ) : (
        <Grid item xs={12} mt={4} display="flex" justifyContent="start" alignItems="start">
          <Button
            disableElevation
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            className="loader"
            onClick={saveChanges}
          >
            {loading && <CircularProgress size={20} />}
            Guardar
          </Button>
        </Grid>
      )}
    </div>
  );
};

export default ShippingOptions;
