import client from '../client';

export const shippingRate = (params: {
  country: string;
  region: string;
  commune: string;
  origin?: string;
  quantities?: { [key: string]: number };
  model_id: string;
  model: string;
  company_id?: string;
}): Promise<{ shipping_rate: number }> => {
  return client({
    method: 'get',
    url: `/v1/public/shipping_rates`,
    params: params,
  })
    .then(({ data }: { data: { shipping_rate: number } }) => {
      return data;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
