import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatchOG,
  useSelector as useSelectorOG,
} from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch = (): any => useAppDispatchOG<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorOG;
