import { ApiList, ApiObject, Card, PaymentPlan } from '../../../app/type';

import client from '../client';

export const paymentPlans = (
  clientId: string,
  cardId: string
): Promise<ApiList<Partial<PaymentPlan>>> => {
  return client({
    method: 'get',
    url: `/v1/client/cards/${cardId}/payment_plans`,
    params: { client_id: clientId },
  })
    .then(({ data: body }: { data: ApiList<Partial<PaymentPlan>> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const assignPaymentPlans = (
  clientId: string,
  cardId: string,
  rejectedCardId: string
): Promise<ApiObject<Card>> => {
  return client({
    method: 'post',
    url: `/v1/client/cards/${cardId}/assign_payment_plans`,
    params: { client_id: clientId, rejected_card_id: rejectedCardId },
  })
    .then(({ data: body }: { data: ApiObject<Card> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const markAsInactive = (clientId: string, cardId: string): Promise<ApiObject<Card>> => {
  return client({
    method: 'post',
    url: `/v1/client/cards/${cardId}/mark_as_inactive`,
    params: { client_id: clientId },
  })
    .then(({ data: body }: { data: ApiObject<Card> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
