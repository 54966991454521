// Card API
import * as buyers from './buyer';
import * as groups from './group';
import * as payables from './payable';
import * as mach from './mach';
import * as fintoc from './fintoc';
import * as products from './product';
import * as payments from './payment';
import * as subscriptions from './subscription';
import * as companies from './company';
import * as shopifyOrders from './shopify/orders';
import * as singlePaymentBuyers from './singlePaymentBuyer';
import * as subscriptionBuyers from './subscriptionBuyer';
import * as paymentPlans from './paymentPlan';
import * as singlePayments from './singlePayment';
import * as shippingRates from './shippingRates';
import * as shopifyProducts from './shopify/products';
import * as checkouts from './checkout';

export {
  buyers,
  groups,
  payables,
  mach,
  fintoc,
  products,
  payments,
  subscriptions,
  companies,
  shopifyOrders,
  singlePaymentBuyers,
  subscriptionBuyers,
  paymentPlans,
  singlePayments,
  shippingRates,
  shopifyProducts,
  checkouts,
};

export default {
  buyers,
  groups,
  payables,
  mach,
  fintoc,
  products,
  payments,
  subscriptions,
  companies,
  shopifyOrders,
  singlePaymentBuyers,
  subscriptionBuyers,
  paymentPlans,
  singlePayments,
  shippingRates,
  shopifyProducts,
  checkouts,
};
