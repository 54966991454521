import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import variables from './variables.module.scss';

export const mainTheme = createTheme(
  {
    palette: {
      primary: {
        main: variables.primary,
        light: variables.primaryLight,
        dark: variables.primaryDark,
        contrastText: variables.primaryText,
      },
      secondary: {
        main: variables.secondary,
        light: variables.secondaryLight,
        dark: variables.secondaryDark,
        contrastText: variables.primaryText,
      },
      success: {
        main: variables.success,
        contrastText: variables.primaryText,
      },
      warning: {
        main: variables.warning,
        contrastText: variables.primaryText,
      },
      error: {
        main: variables.error,
        contrastText: variables.primaryText,
      },
      pending: {
        main: variables.pending,
        contrastText: variables.primaryText,
      },
      yellow: {
        main: variables.yellow,
        contrastText: variables.primaryText,
      },
    },
    shape: {
      borderRadius: variables.borderRadius,
    },
    typography: {
      fontFamily: variables.fonts,
    },
  },
  esES
);

declare module '@mui/material/styles' {
  interface Palette {
    pending: Palette['primary'];
    yellow: Palette['primary'];
  }
  interface PaletteOptions {
    pending: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];
  }
}

export default mainTheme;
