import React, { useEffect, useState } from 'react';
import Query from './Query';
import FilterPopup from './FilterPopup';
import Tags from './Tags';

import styles from './Filter.module.scss';

interface FilterProps {
  filters: string[];
  displayData: { [key: string]: any };
  reloadResource: (query: string, filterParams?: { [key: string]: any }) => void;
  setLocalStorage: (args: { [key: string]: any }, context?: string) => void;
  queryState: [query: string, setQuery: (arg: string) => void];
  popupRef?: React.MutableRefObject<HTMLButtonElement | null>;
  queryFields: string;
}

export const Filter = (props: FilterProps): React.ReactElement => {
  const [statedData, setData] = useState<{ [key: string]: any }>(props.displayData);
  const [query, setQuery] = props.queryState;

  const saveFilter = (args: { [key: string]: any }) => {
    setData(args);
    props.setLocalStorage(args);
  };

  useEffect(() => {
    props.reloadResource(query, statedData);
  }, [query, statedData]);

  if (props.filters.length === 0) {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.topContainer}>
          <Query query={query} setQuery={setQuery} queryFields={props.queryFields} />
        </div>
      </div>
    );
  }

  const saveQuery = (query: string) => {
    setQuery(query);
    props.setLocalStorage({ query: query }, 'query');
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topContainer}>
        <Query query={query} setQuery={saveQuery} queryFields={props.queryFields} />
        <FilterPopup
          filters={props.filters}
          displayData={statedData}
          setDisplayData={saveFilter}
          popupRef={props.popupRef}
        />
      </div>
      <Tags tags={statedData} setData={saveFilter} />
    </div>
  );
};

export default Filter;
