import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Features
import sessionReducer from '../features/session/sessionSlice';
import sellerReducer from '../features/seller/sellerSlice';
import backofficeReducer from '../features/backoffice/backofficeSlice';
import clientReducer from '../features/client/clientSlice';

const sessionPersistConfig = {
  key: 'PL:OP:Session',
  version: 1,
  storage,
  blacklist: ['loading'],
};

const sellerPersistConfig = {
  key: 'PL:OP:Seller',
  version: 1,
  storage,
  blacklist: ['loading'],
};

const backofficePersistConfig = {
  key: 'PL:OP:Backoffice',
  version: 1,
  storage,
  blacklist: ['loading'],
};

const clientPersistConfig = {
  key: 'PL:OP:Client',
  version: 1,
  storage,
  blacklist: ['loading'],
};

const rootReducer = combineReducers({
  session: persistReducer(sessionPersistConfig, sessionReducer),
  seller: persistReducer(sellerPersistConfig, sellerReducer),
  backoffice: persistReducer(backofficePersistConfig, backofficeReducer),
  client: persistReducer(clientPersistConfig, clientReducer),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
