import { Ref, useEffect, useState, useImperativeHandle } from 'react';

// Constants
import { RECAPTCHA_URL, RECAPTCHA_KEY } from '../../common/constants/google';

// Utils
import { loadScript } from '../../common/utils';

export const Recaptcha = ({
  innerRef,
  action = 'login',
}: {
  innerRef: Ref<{ execute: () => Promise<string> } | undefined>;
  action?: string;
}): null => {
  const [isReady, setReady] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useImperativeHandle(innerRef, () => ({
    execute() {
      if (isReady) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return window.grecaptcha.enterprise.execute(RECAPTCHA_KEY, { action });
      }
      return Promise.reject('');
    },
  }));

  useEffect(() => {
    loadScript('recaptcha-key', RECAPTCHA_URL, () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.grecaptcha.enterprise.ready(() => setReady(true));
    });
    return () => {
      const script = document.getElementById('recaptcha-key');
      if (script) script.remove();
      const elems = document.getElementsByClassName('grecaptcha-badge');
      if (elems.length) elems[0].remove();
    };
  }, []);

  return null;
};

export default Recaptcha;
