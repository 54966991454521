import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Collapse,
  useMediaQuery,
} from '@mui/material';

import { ApiList, PaymentTransaction, Statement } from '../../app/type';
import { clientApi } from '../../common/api';
import { useSelector } from 'react-redux';
import { ClientState } from './clientSlice';
import { useHistory } from 'react-router-dom';
import { formatCurrency } from '../../common/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { hexToRgb } from './utils';

import styles from './Client.module.scss';
import variables from '../../common/styles/variables.module.scss';
import PopUp from '../../common/components/PopUp';
import StatusLabel from '../../common/components/StatusLabel';

const Payments = (): React.ReactElement => {
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const { client, company } = useSelector(({ client }: { client: ClientState }) => client);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDetailPopup, setOpenDetailPopup] = useState<boolean>(false);
  const [selectedPayment, setSelectedPayment] = useState<PaymentTransaction>();
  const [payments, setPayments] = useState<PaymentTransaction[]>([]);
  const history = useHistory();
  const rgbColor = company?.payment_design?.background_color
    ? hexToRgb(company?.payment_design?.background_color)
    : null;
  const cardsBackgroundColor = rgbColor
    ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    : '#edeeff';

  useEffect(() => {
    setLoading(true);
    clientApi.paymentTransactions
      .list(client?.id || '')
      .then((data: ApiList<PaymentTransaction>) => {
        setPayments(data.data);
      })
      .catch(() => {
        history.replace('/client/error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const Row = (props: { payment: PaymentTransaction; index: number }): React.ReactElement => {
    const [openCollapse, setOpenCollapse] = useState<boolean>(false);

    return (
      <Fragment>
        <TableRow key={props.payment.id} className={styles.tableRow}>
          <TableCell className={styles.amount}>
            <Typography>{formatCurrency(props.payment.amount)}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{props.payment.payment_date}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{props.payment.payment_method}</Typography>
          </TableCell>
          <TableCell>
            <StatusLabel status={props.payment.status} type="paymentTransaction" />
          </TableCell>
          {!isMobile && (
            <TableCell>
              <div
                className={styles.collapseIcom}
                onClick={() => setOpenCollapse(!openCollapse)}
                style={{
                  background: company?.payment_design?.background_color
                    ? company?.payment_design?.background_color
                    : '#4653e3',
                }}
              >
                <FontAwesomeIcon icon={openCollapse ? faChevronUp : faChevronDown} />
              </div>
            </TableCell>
          )}
        </TableRow>
        <TableRow className={styles.collapseRow}>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse
              in={openCollapse}
              timeout="auto"
              unmountOnExit
              className={styles.collapseBox}
              style={{
                background: cardsBackgroundColor,
                marginBottom: openCollapse ? '20px' : '0',
              }}
            >
              <div className={styles.detailTable}>
                <Table>
                  <TableHead>
                    <TableRow className={styles.tableHeader}>
                      <TableCell>
                        <Typography>Nombre</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Fecha de vencimiento</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Monto pagado</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.payment.statements?.map((statement: Statement) => (
                      <TableRow key={statement.id} className={styles.ta}>
                        <TableCell className={styles.amount}>
                          <Typography>{statement.service_name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{statement.due_date || '-'}</Typography>
                        </TableCell>
                        <TableCell className={styles.amount}>
                          <Typography>{formatCurrency(statement.amount)}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return (
    <div className={styles.cardsContainer}>
      {loading ? (
        <div className={styles.spinnerContainer}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress
              sx={{
                color: company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3',
              }}
            />
          </Box>
        </div>
      ) : (
        <Fragment>
          <div className={styles.tabTitle}>
            <Typography>Historial de pagos</Typography>
          </div>
          <div className={styles.description}>
            <Typography>Acá puedes ver el historial de todos tus pagos realizados.</Typography>
          </div>
          {isMobile ? (
            <div>
              {payments.map((payment: PaymentTransaction) => (
                <div className={styles.mobilePayment} key={payment.id}>
                  <div>
                    <span>
                      <Typography>
                        Pago del <strong>{payment.payment_date}</strong> por{' '}
                        <strong>{formatCurrency(payment.amount)}</strong>
                      </Typography>
                    </span>
                  </div>
                  <div
                    className={styles.detailLink}
                    onClick={() => {
                      setOpenDetailPopup(true);
                      setSelectedPayment(payment);
                    }}
                  >
                    <Typography>
                      <u>Ver detalle</u>
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.paymentsTable}>
              <Table>
                <TableHead>
                  <TableRow className={styles.tableHeader}>
                    <TableCell>
                      <Typography>{isMobile ? 'Monto' : 'Monto total'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Fecha de pago</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Medio de pago</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Estado del pago</Typography>
                    </TableCell>
                    {!isMobile && <TableCell></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((payment: PaymentTransaction, index: number) => (
                    <Row payment={payment} index={index} key={payment.id} />
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
          <PopUp
            state={{ open: openDetailPopup, setOpen: setOpenDetailPopup }}
            title={
              <div>
                <Typography>Detalle del pago</Typography>
              </div>
            }
            content={
              <div className={styles.paymentDetailPopup}>
                <div>
                  <Typography>
                    <strong>Método de pago:</strong>
                  </Typography>
                  <Typography>{selectedPayment?.payment_method}</Typography>
                  <Typography>
                    <strong>Estado del pago:</strong>
                  </Typography>
                  <Typography>
                    <StatusLabel status={selectedPayment?.status} type="paymentTransaction" />
                  </Typography>
                </div>
                <div>
                  <Typography>
                    <strong>Resumen:</strong>
                  </Typography>
                  <div className={styles.paymentSummary}>
                    {selectedPayment?.statements?.map((statement: Statement) => (
                      <div
                        className={styles.paymentDetail}
                        key={statement.id}
                        style={{
                          background: cardsBackgroundColor,
                        }}
                      >
                        <Typography>{statement.service_name}</Typography>
                        <Typography>{formatCurrency(statement.amount)}</Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            }
          />
        </Fragment>
      )}
    </div>
  );
};

export default Payments;
