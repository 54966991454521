import variables from '../styles/variables.module.scss';

export const STATUS = [
  ['pending', 'Pendiente de aprobación'],
  ['paid', 'Pagado'],
  ['error', 'Error'],
  ['conciliated', 'Conciliado'],
];

export const STATUS_COLORS = Object.fromEntries([
  ['pending', variables.pending],
  ['paid', variables.success],
  ['error', variables.error],
  ['conciliated', variables.warning],
]);

export const STATUS_ES = Object.fromEntries(STATUS);
