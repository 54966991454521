import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon } from 'react-feather';

const routes = [
  // {
  //   regex: /^\/seller\/products\/?$/,
  //   label: 'Servicios',
  //   back: '/seller',
  // },
  // {
  //   regex: /^\/seller\/products\//,
  //   label: 'Servicio',
  //   back: '/seller/products',
  //   backLabel: 'Servicios',
  // },
  // {
  //   regex: /^\/seller\/buyers\/?$/,
  //   label: 'Clientes',
  //   back: '/seller',
  // },
  // {
  //   regex: /^\/seller\/buyers\//,
  //   label: 'Cliente',
  //   back: '/seller/buyers',
  //   backLabel: 'Clientes',
  // },
  // {
  //   regex: /^\/seller\/payments\/?$/,
  //   label: 'Pagos',
  //   back: '/seller',
  // },
  // {
  //   regex: /^\/seller\/payments\//,
  //   label: 'Pago',
  //   back: '/seller/payments',
  //   backLabel: 'Pagos',
  // },
  '/seller/home',
  '/seller/recurrent',
  '/seller/single',
  '/seller/buyers',
  '/seller/payments',
];

interface BackButtonProps {
  loading?: boolean;
  label?: string;
  href?: string;
  sx?: any;
}
export const BackButton = ({ label, sx }: BackButtonProps): React.ReactElement => {
  const history: any = useHistory();
  const [show, setShow] = useState<boolean>(true);

  // let to;
  // let onClick;
  // if (href) {
  //   to = href;
  // } else if (history.location.state?.from) {
  //   to = history.location.state.from;
  // } else {
  //   routes.some((route) => {
  //     if (history.location.pathname.match(route.regex)) {
  //       to = route.back;
  //       if (!label) label = route.backLabel;
  //       return true;
  //     }
  //   });
  //   if (!to && history.length > 1) {
  //     onClick = () => history.goBack();
  //   }
  // }

  // label = 'Volver'; // :'c
  // if (!label && history.location.state?.from) {
  //   routes.some((route) => {
  //     if (history.location.state.from.match(route.regex)) {
  //       label = route.label;
  //       return true;
  //     }
  //   });
  // }

  useEffect(() => {
    if (routes.includes(history.location.pathname)) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [history.location.pathname]);

  return (
    <Fragment>
      {show ? (
        <Button
          variant="outlined"
          color="primary"
          // component={to ? Link : Button}
          // to={to || '/'}
          onClick={() => history.goBack()}
          startIcon={<ChevronLeftIcon />}
          sx={{
            borderColor: 'white',
            color: 'white',
            textTransform: 'none',
            '&:hover': {
              borderColor: 'white',
            },
            ...(sx || {}),
          }}
        >
          {label || 'Volver'}
        </Button>
      ) : null}
    </Fragment>
  );
};

export default BackButton;
