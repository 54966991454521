import React, {
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '../../../app/hooks';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { es } from 'yup-locales';

// API
import { sellerApi } from '../../../common/api';

// Features
import { SellerState } from '../sellerSlice';
import { BankInformation, BillingInformation, Company } from '../../../app/type';

// Components
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Drawer,
  TextField,
  Button,
  MenuItem,
  useMediaQuery,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  Slider,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import { Edit as EditIcon } from 'react-feather';
import Avatar from '../../../common/components/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import { generateImage } from '../../../common/utils/cropImage';

// Assets
import cStyles from '../../../common/styles/common.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import styles from './CompanySeller.module.scss';
import { RUTFormater, setDocumentTitle } from '../../../common/utils';
import { ACCOUNT_TYPES, BANKS } from '../../../common/constants/banks';
import PopUp from '../../../common/components/PopUp';
import { COMMUNES } from '../../../common/constants/communes';
import { BUSINESS_ITEMS } from '../../../common/constants/businessItems';
import { Upload as UploadIcon } from 'react-feather';

yup.setLocale(es);

const CompanyPage = (): React.ReactElement => {
  setDocumentTitle('Datos Empresa');
  const { company, loading } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const [bankInformations, setBankInformations] = useState<BankInformation[]>([]);
  const [bankInformation, setBankInformation] = useState<BankInformation>();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const reloadRef =
    useRef<{ reloadBankInformations: () => void; reloadBillingInformations: () => void }>();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [billingDrawerOpen, setBillingDrawerOpen] = useState<boolean>(false);
  const [billingInformations, setBillingInformations] = useState<BillingInformation[]>([]);
  const [billingInformation, setBillingInformation] = useState<BillingInformation>();
  const [deleteBillingOpen, setDeleteBillingOpen] = useState<boolean>(false);
  const [companyDrawerOpen, setCompanyDrawerOpen] = useState<boolean>(false);
  const [croppedArea, setCroppedArea] = React.useState<Area>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [companyFormLoading, setCompanyFormLoading] = useState<boolean>(false);
  const [billingFormLoading, setBillingFormLoading] = useState<boolean>(false);
  const [bankFormLoading, setBankFormLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!company) {
      history.replace('/seller/home');
    } else if (company) {
      sellerApi.companies.show(company.id).catch(console.error);
      sellerApi.companies
        .bankInformations(company.id)
        .then((data) => setBankInformations(data.data));
      sellerApi.billingInformations
        .index(company.id)
        .then((data) => setBillingInformations(data.data));
    }
  }, [company?.id]);

  const formik = useFormik<BankInformation>({
    initialValues: {
      id: bankInformation?.id || '',
      company_id: bankInformation?.company_id || company?.id || '',
      name: bankInformation?.name || '',
      tax_id: bankInformation?.tax_id || '',
      bank: bankInformation?.bank || '',
      account_type: bankInformation?.account_type || '',
      account_number: bankInformation?.account_number || '',
      email: bankInformation?.email || '',
      default: bankInformation?.default || false,
    },
    onSubmit: (values, { setErrors }: any) => {
      setBankFormLoading(true);
      const request = values.id
        ? sellerApi.bankInformations.update
        : sellerApi.bankInformations.create;
      request({ data: values })
        .then(() => {
          enqueueSnackbar('Guardado', { variant: 'success' });
          reloadRef.current?.reloadBankInformations();
          setDrawerOpen(false);
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
          } else if (err?.response?.status === 403) {
            enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        })
        .finally(() => setBankFormLoading(false));
    },
    enableReinitialize: true,
  });

  const billingFormik = useFormik<BillingInformation>({
    initialValues: {
      id: billingInformation?.id || '',
      company_id: billingInformation?.company_id || company?.id || '',
      business_name: billingInformation?.business_name || '',
      tax_id: billingInformation?.tax_id || '',
      activity: billingInformation?.activity || '',
      address: billingInformation?.address || '',
      commune: billingInformation?.commune || '',
      email: billingInformation?.email || '',
    },
    onSubmit: (values, { setErrors }: any) => {
      setBillingFormLoading(true);
      const request = values.id
        ? sellerApi.billingInformations.update
        : sellerApi.billingInformations.create;
      request({ data: values })
        .then(() => {
          enqueueSnackbar('Guardado', { variant: 'success' });
          reloadRef.current?.reloadBillingInformations();
          setBillingDrawerOpen(false);
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
          } else if (err?.response?.status === 403) {
            enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        })
        .finally(() => setBillingFormLoading(false));
    },
    enableReinitialize: true,
  });

  const companyFormik = useFormik<Company>({
    initialValues: {
      id: company?.id || '',
      name: company?.name || '',
      email: company?.email || '',
      business_item: company?.business_item || '',
      phone: company?.phone || 0,
      image: company?.image || '',
    },
    validationSchema: () =>
      yup.object().shape({
        email: yup
          .string()
          .email()
          .matches(/^[\u0020-\u007E]+$/, 'El correo no puede contener tildes')
          .required()
          .label('Correo'),
      }),
    onSubmit: (values) => {
      setCompanyFormLoading(true);
      sellerApi.companies
        .update({ data: values })
        .then(() => {
          setCompanyDrawerOpen(false);
          enqueueSnackbar('Datos guardados correctamente', { variant: 'success' });
        })
        .catch((error) => {
          if (error.response.status === 403) {
            enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Ha ocurrido un error, intenta nuevamente', { variant: 'error' });
          }
        })
        .finally(() => setCompanyFormLoading(false));
    },
  });
  const [urlImage, setUrlImage] = useState<string>(companyFormik.values.image || '');

  const onCropComplete = useCallback(async (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const onUploadImage = (event: any) => {
    setDialogOpen(!dialogOpen);
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  const acceptImage = async () => {
    if (croppedArea) {
      const canvas = await generateImage(image, croppedArea);
      canvas
        ? canvas.toBlob(
            (blob: Blob | null) => {
              if (blob) {
                const newImg = document.createElement('img'),
                  url = URL.createObjectURL(blob);

                newImg.onload = () => {
                  URL.revokeObjectURL(url);
                };
                setUrlImage(URL.createObjectURL(blob));
                newImg.src = url;
                companyFormik.setFieldValue('image_file', blob);
                enqueueSnackbar('Imagen procesada con éxito', { variant: 'success' });
              }
            },
            'image/png',
            0.66
          )
        : null;
      setDialogOpen(!dialogOpen);
    }
  };

  const editBankInformation = (bI: BankInformation) => {
    setBankInformation(bI);
    setDrawerOpen(true);
  };

  const editBillingInformation = (bI: BillingInformation) => {
    setBillingInformation(bI);
    setBillingDrawerOpen(true);
  };

  const deleteBankInformation = () => {
    sellerApi.bankInformations
      .destroy(bankInformation?.id)
      .then(() => {
        enqueueSnackbar('Datos bancarios eliminados', { variant: 'success' });
        reloadRef.current?.reloadBankInformations();
      })
      .catch((error) => {
        if (error.response.data?.meta?.errors?.base) {
          enqueueSnackbar('Los datos bancarios tienen productos asociados', { variant: 'error' });
        } else if (error.response.status === 403) {
          enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Ha ocurrido un error, por favor inténtalo nuevamente', {
            variant: 'error',
          });
        }
      })
      .finally(() => setDeleteOpen(false));
  };

  const deleteBillingInformation = () => {
    sellerApi.billingInformations
      .destroy(billingInformation?.id)
      .then(() => {
        enqueueSnackbar('Datos de facturación eliminados', { variant: 'success' });
        reloadRef.current?.reloadBillingInformations();
      })
      .catch((error) => {
        if (error.response.data?.meta?.errors?.base) {
          enqueueSnackbar('Los datos de facturación tienen productos asociados', {
            variant: 'error',
          });
        } else if (error.response.status === 403) {
          enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Ha ocurrido un error, por favor inténtalo nuevamente', {
            variant: 'error',
          });
        }
      })
      .finally(() => setDeleteBillingOpen(false));
  };

  const handleAccountNumberChange = (value: string) => {
    formik.setFieldValue('account_number', value.replace(/[^0-9]/g, ''));
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newValue = value.replace(/[^\d+]/g, '');
    companyFormik.setFieldValue('phone', newValue);
  };

  useImperativeHandle(reloadRef, () => ({
    reloadBankInformations() {
      if (company) {
        sellerApi.companies
          .bankInformations(company.id)
          .then((data) => setBankInformations(data.data));
      }
    },
    reloadBillingInformations() {
      if (company) {
        sellerApi.billingInformations
          .index(company.id)
          .then((data) => setBillingInformations(data.data));
      }
    },
  }));

  useEffect(() => {
    drawerOpen !== true && setBankInformation(undefined);
  }, [drawerOpen]);

  useEffect(() => {
    deleteOpen !== true && setBankInformation(undefined);
  }, [deleteOpen]);

  useEffect(() => {
    billingDrawerOpen !== true && setBillingInformation(undefined);
  }, [billingDrawerOpen]);

  useEffect(() => {
    deleteBillingOpen !== true && setBillingInformation(undefined);
  }, [deleteBillingOpen]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={cStyles.infoPaper}>
            {/* HEADER */}
            <Grid container>
              <Grid item xs={12}>
                <div className={cStyles.paperHeader}>
                  <Avatar
                    className={cStyles.paperHeaderAvatar}
                    text={company?.name}
                    img={company?.image}
                    context="company"
                  />
                  <div className={cStyles.paperHeaderContent}>
                    <Typography variant="h5" className="secondary">
                      {company?.name}
                    </Typography>
                    <Typography variant="h6" className="secondary">
                      {company?.default_billing_information?.business_name}
                    </Typography>
                  </div>
                  <div className={cStyles.paperHeaderActions}>
                    <IconButton
                      size="medium"
                      color="primary"
                      disabled={loading}
                      onClick={() => setCompanyDrawerOpen(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            </Grid>

            {/* INFO */}
            <Grid container className={cStyles.infoContainer}>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="caption">Nombre de fantasía:</Typography>
                <Typography variant="h6">{company?.name}</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="caption">Correo de contacto:</Typography>
                <Typography variant="h6">{company?.email}</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="caption">
                  {company?.kind === 'natural' ? 'Rubro del negocio' : 'Rubro de la empresa'}
                </Typography>
                <Typography variant="h6">{company?.business_item}</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="caption">Teléfono</Typography>
                <Typography variant="h6">{company?.phone}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={cStyles.infoPaper}>
            <Grid container>
              <Grid item xs={12} className={cStyles.paperHeader}>
                <div className={cStyles.paperHeaderContent}>
                  <Typography variant="h5">Datos bancarios</Typography>
                </div>
                <div className={cStyles.paperHeaderActions}>
                  <IconButton
                    size="medium"
                    color="primary"
                    disabled={loading}
                    onClick={() => setDrawerOpen(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </IconButton>
                </div>
              </Grid>
              {bankInformations.map((bankInformation) => (
                <div key={bankInformation.id} className={styles.informationContainer}>
                  <div className={styles.header}>
                    {isMobile ? (
                      <Fragment>
                        <Typography color="primary" variant="subtitle1">
                          Nombre Titular: {bankInformation.name}
                        </Typography>
                        <Typography color="primary" variant="subtitle1">
                          RUT: {bankInformation.tax_id}
                        </Typography>
                      </Fragment>
                    ) : (
                      <Typography color="primary" variant="subtitle1">
                        Nombre Titular: {bankInformation.name} | RUT: {bankInformation.tax_id}
                      </Typography>
                    )}
                  </div>
                  <div className={styles.actions}>
                    <IconButton
                      size="medium"
                      color="primary"
                      disabled={loading}
                      onClick={() => editBankInformation(bankInformation)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="medium"
                      color="error"
                      disabled={loading}
                      onClick={() => {
                        setDeleteOpen(true);
                        setBankInformation(bankInformation);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </IconButton>
                  </div>
                </div>
              ))}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={cStyles.infoPaper}>
            <Grid container>
              <Grid item xs={12} className={cStyles.paperHeader}>
                <div className={cStyles.paperHeaderContent}>
                  <Typography variant="h5">Datos de facturación</Typography>
                </div>
                <div className={cStyles.paperHeaderActions}>
                  <IconButton
                    size="medium"
                    color="primary"
                    disabled={loading}
                    onClick={() => setBillingDrawerOpen(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </IconButton>
                </div>
              </Grid>
              {billingInformations.map((billingInformation) => (
                <div key={billingInformation.id} className={styles.informationContainer}>
                  <div className={styles.header}>
                    {isMobile ? (
                      <Fragment>
                        <Typography color="primary" variant="subtitle1">
                          Razón Social: {billingInformation.business_name}
                        </Typography>
                        <Typography color="primary" variant="subtitle1">
                          RUT: {billingInformation.tax_id}
                        </Typography>
                      </Fragment>
                    ) : (
                      <Typography color="primary" variant="subtitle1">
                        Razón Social: {billingInformation.business_name} | RUT:{' '}
                        {billingInformation.tax_id}
                      </Typography>
                    )}
                  </div>
                  <div className={styles.actions}>
                    <IconButton
                      size="medium"
                      color="primary"
                      disabled={loading}
                      onClick={() => editBillingInformation(billingInformation)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="medium"
                      color="error"
                      disabled={loading}
                      onClick={() => {
                        setDeleteBillingOpen(true);
                        setBillingInformation(billingInformation);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </IconButton>
                  </div>
                </div>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <form onSubmit={formik.handleSubmit} className={styles.drawerContainer}>
          <div className={styles.drawerField}>
            <Typography>Nombre titular*</Typography>
            <TextField
              required
              id="name"
              type="text"
              autoComplete="name"
              variant="outlined"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </div>
          <div className={styles.drawerField}>
            <Typography>RUT*</Typography>
            <TextField
              required
              id="tax_id"
              type="text"
              autoComplete="tax_id"
              variant="outlined"
              value={formik.values.tax_id}
              onChange={RUTFormater(formik.handleChange)}
              error={formik.touched.tax_id && Boolean(formik.errors.tax_id)}
              helperText={formik.touched.tax_id && formik.errors.tax_id}
            />
          </div>
          <div className={styles.drawerField}>
            <Typography>Número de cuenta*</Typography>
            <TextField
              required
              id="account_number"
              type="text"
              autoComplete="account_number"
              variant="outlined"
              value={formik.values.account_number}
              onChange={(e) => handleAccountNumberChange(e.target.value)}
              error={formik.touched.account_number && Boolean(formik.errors.account_number)}
              helperText={formik.touched.account_number && formik.errors.account_number}
            />
          </div>
          <div className={styles.drawerField}>
            <Typography>Banco*</Typography>
            <TextField
              required
              select
              id="bank"
              name="bank"
              type="text"
              autoComplete="bank"
              variant="outlined"
              value={formik.values.bank}
              onChange={formik.handleChange}
              error={formik.touched.bank && Boolean(formik.errors.bank)}
              helperText={formik.touched.bank && formik.errors.bank}
            >
              {BANKS.map((bank: string) => (
                <MenuItem key={bank} value={bank}>
                  {bank}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={styles.drawerField}>
            <Typography>Tipo de cuenta*</Typography>
            <TextField
              required
              select
              id="account_type"
              name="account_type"
              type="text"
              autoComplete="account_type"
              variant="outlined"
              value={formik.values.account_type}
              onChange={formik.handleChange}
              error={formik.touched.account_type && Boolean(formik.errors.account_type)}
              helperText={formik.touched.account_type && formik.errors.account_type}
            >
              {ACCOUNT_TYPES.map((type: string) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
              {formik.values.bank === 'Banco Estado' && (
                <MenuItem value="Cuenta Vista">Cuenta Rut</MenuItem>
              )}
            </TextField>
          </div>
          <div className={styles.drawerField}>
            <Typography>Correo</Typography>
            <TextField
              id="email"
              type="text"
              autoComplete="email"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>
          <div className={styles.actions}>
            <Button variant="outlined" onClick={() => setDrawerOpen(false)}>
              Cancelar
            </Button>
            <Button variant="contained" type="submit" disabled={bankFormLoading}>
              {bankFormLoading ? (
                <CircularProgress style={{ height: '20px', width: '20px' }} />
              ) : (
                'Guardar'
              )}
            </Button>
          </div>
        </form>
      </Drawer>
      <Drawer anchor="right" open={billingDrawerOpen} onClose={() => setBillingDrawerOpen(false)}>
        <form onSubmit={billingFormik.handleSubmit} className={styles.drawerContainer}>
          <div className={styles.drawerField}>
            <Typography>Razón social*</Typography>
            <TextField
              required
              id="business_name"
              type="text"
              autoComplete="business_name"
              variant="outlined"
              value={billingFormik.values.business_name}
              onChange={billingFormik.handleChange}
              error={
                billingFormik.touched.business_name && Boolean(billingFormik.errors.business_name)
              }
              helperText={billingFormik.touched.business_name && billingFormik.errors.business_name}
            />
          </div>
          <div className={styles.drawerField}>
            <Typography>RUT*</Typography>
            <TextField
              required
              id="tax_id"
              type="text"
              autoComplete="tax_id"
              variant="outlined"
              value={billingFormik.values.tax_id}
              onChange={RUTFormater(billingFormik.handleChange)}
              error={billingFormik.touched.tax_id && Boolean(billingFormik.errors.tax_id)}
              helperText={billingFormik.touched.tax_id && billingFormik.errors.tax_id}
            />
          </div>
          <div className={styles.drawerField}>
            <Typography>Actividad*</Typography>
            <TextField
              required
              id="activity"
              type="text"
              autoComplete="activity"
              variant="outlined"
              value={billingFormik.values.activity}
              onChange={billingFormik.handleChange}
              error={billingFormik.touched.activity && Boolean(billingFormik.errors.activity)}
              helperText={billingFormik.touched.activity && billingFormik.errors.activity}
            />
          </div>
          <div className={styles.drawerField}>
            <Typography>Dirección*</Typography>
            <TextField
              required
              id="address"
              name="address"
              type="text"
              autoComplete="address"
              variant="outlined"
              value={billingFormik.values.address}
              onChange={billingFormik.handleChange}
              error={billingFormik.touched.address && Boolean(billingFormik.errors.address)}
              helperText={billingFormik.touched.address && billingFormik.errors.address}
            />
          </div>
          <div className={styles.drawerField}>
            <Typography>Comuna*</Typography>
            <Autocomplete
              id="commune"
              value={billingFormik.values.commune}
              onChange={(e, value) => billingFormik.setFieldValue('commune', value)}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoComplete="commune"
                  required
                  error={billingFormik.touched.commune && Boolean(billingFormik.errors.commune)}
                  helperText={billingFormik.touched.commune && billingFormik.errors.commune}
                  name="commune"
                  type="text"
                  variant="outlined"
                />
              )}
              options={COMMUNES}
            />
          </div>
          <div className={styles.drawerField}>
            <Typography>Correo de notificación</Typography>
            <TextField
              id="email"
              type="text"
              autoComplete="email"
              variant="outlined"
              value={billingFormik.values.email}
              onChange={billingFormik.handleChange}
              error={billingFormik.touched.email && Boolean(billingFormik.errors.email)}
              helperText={billingFormik.touched.email && billingFormik.errors.email}
            />
          </div>
          <div className={styles.actions}>
            <Button variant="outlined" onClick={() => setBillingDrawerOpen(false)}>
              Cancelar
            </Button>
            <Button variant="contained" type="submit" disabled={billingFormLoading}>
              {billingFormLoading ? (
                <CircularProgress style={{ height: '20px', width: '20px' }} />
              ) : (
                'Guardar'
              )}
            </Button>
          </div>
        </form>
      </Drawer>
      <Drawer anchor="right" open={companyDrawerOpen} onClose={() => setCompanyDrawerOpen(false)}>
        <form onSubmit={companyFormik.handleSubmit} className={styles.drawerContainer}>
          <Grid item xs={12}>
            <Avatar
              className={styles.avatarContainer}
              text={company?.name}
              img={urlImage}
              context="company"
            />
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: '20px' }}>
            <Button variant="contained" component="label">
              Foto de perfil &nbsp; <UploadIcon />{' '}
              <input type="file" accept="image/*" hidden id="image" onChange={onUploadImage} />
            </Button>
          </Grid>
          <div className={styles.drawerField}>
            <Typography>Nombre de fantasía*</Typography>
            <TextField
              required
              id="name"
              type="text"
              autoComplete="name"
              variant="outlined"
              value={companyFormik.values.name}
              onChange={companyFormik.handleChange}
              error={companyFormik.touched.name && Boolean(companyFormik.errors.name)}
              helperText={companyFormik.touched.name && companyFormik.errors.name}
            />
          </div>
          <div className={styles.drawerField}>
            <Typography>Correo*</Typography>
            <TextField
              required
              id="email"
              type="text"
              autoComplete="email"
              variant="outlined"
              value={companyFormik.values.email}
              onChange={companyFormik.handleChange}
              error={companyFormik.touched.email && Boolean(companyFormik.errors.email)}
              helperText={companyFormik.touched.email && companyFormik.errors.email}
            />
          </div>
          <div className={styles.drawerField}>
            <Typography>Rubro del negocio*</Typography>
            <Autocomplete
              id="business_item"
              value={companyFormik.values.business_item}
              onChange={(e, value) => companyFormik.setFieldValue('business_item', value)}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoComplete="business_item"
                  required
                  error={
                    companyFormik.touched.business_item &&
                    Boolean(companyFormik.errors.business_item)
                  }
                  helperText={
                    companyFormik.touched.business_item && companyFormik.errors.business_item
                  }
                  name="business_item"
                  type="text"
                  variant="outlined"
                />
              )}
              options={BUSINESS_ITEMS}
            />
          </div>
          <div className={styles.drawerField}>
            <Typography>Número de teléfono*</Typography>
            <TextField
              required
              id="phone"
              type="text"
              autoComplete="phone"
              variant="outlined"
              value={companyFormik.values.phone}
              onChange={handlePhoneChange}
              error={companyFormik.touched.phone && Boolean(companyFormik.errors.phone)}
              helperText={companyFormik.touched.phone && companyFormik.errors.phone}
            />
          </div>
          <div className={styles.actions}>
            <Button variant="outlined" onClick={() => setCompanyDrawerOpen(false)}>
              Cancelar
            </Button>
            <Button variant="contained" type="submit" disabled={companyFormLoading}>
              {companyFormLoading ? (
                <CircularProgress style={{ height: '20px', width: '20px' }} />
              ) : (
                'Guardar'
              )}
            </Button>
          </div>
        </form>
      </Drawer>
      <PopUp
        content={
          <Typography>
            ¿Estás seguro que quieres eliminar estos datos bancarios? No se puede deshacer esta
            acción
          </Typography>
        }
        state={{ open: deleteOpen, setOpen: setDeleteOpen }}
        extraActions={[
          <Button key={1} variant="outlined" color="primary" onClick={() => setDeleteOpen(false)}>
            Cancelar
          </Button>,
          <Button key={2} variant="contained" color="error" onClick={() => deleteBankInformation()}>
            Eliminar
          </Button>,
        ]}
      />
      <PopUp
        content={
          <Typography>
            ¿Estás seguro que quieres eliminar estos datos de facturación? No se puede deshacer esta
            acción
          </Typography>
        }
        state={{ open: deleteBillingOpen, setOpen: setDeleteBillingOpen }}
        extraActions={[
          <Button
            key={1}
            variant="outlined"
            color="primary"
            onClick={() => setDeleteBillingOpen(false)}
          >
            Cancelar
          </Button>,
          <Button
            key={2}
            variant="contained"
            color="error"
            onClick={() => deleteBillingInformation()}
          >
            Eliminar
          </Button>,
        ]}
      />
      <Dialog open={dialogOpen} keepMounted onClose={() => setDialogOpen(!dialogOpen)}>
        <Grid container>
          <Grid item xs={12}>
            <DialogTitle>Ajusta la imagen por favor</DialogTitle>
          </Grid>
          <Grid item xs={12}>
            <DialogContent>
              <div style={{ position: 'relative', width: '100%', height: 250 }}>
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  objectFit="contain"
                />
              </div>
              <div>
                <Slider
                  value={zoom}
                  min={1}
                  max={2}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(Number(zoom))}
                  classes={{ root: 'slider' }}
                />
              </div>
            </DialogContent>
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <Button onClick={() => setDialogOpen(!dialogOpen)}>Cancelar</Button>
              <Button onClick={acceptImage}>Aceptar</Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </Fragment>
  );
};

export default CompanyPage;
