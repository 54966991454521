import React, { Fragment } from 'react';
import { MobileStepper, Button, IconButton } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import styles from './Stepper.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

interface StepperProps {
  assets: any[];
}

const Stepper = (props: StepperProps): React.ReactElement => {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const maxSteps = props.assets.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return maxSteps <= 1 ? (
    <div className={styles.slidesContainer}>{props.assets[0]}</div>
  ) : (
    <Fragment>
      <div className={styles.slidesContainer}>
        <SwipeableViews index={activeStep} onChangeIndex={handleStepChange}>
          {props.assets.map((asset, index) => (
            <div key={index} className={styles.views}>
              {Math.abs(activeStep - index) <= 2 ? asset : null}
            </div>
          ))}
        </SwipeableViews>
        <div className={styles.absoluteContainerLeft}>
          <IconButton
            size="medium"
            className={styles.iconButton}
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </IconButton>
        </div>
        <div className={styles.absoluteContainerRight}>
          <IconButton
            size="medium"
            className={styles.iconButton}
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </IconButton>
        </div>
      </div>

      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={<Button style={{ display: 'none' }} />}
        backButton={<Button style={{ display: 'none' }} />}
        className={styles.stepper}
      />
    </Fragment>
  );
};

export default Stepper;
