import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// Types
import { ApiObject, SubscriptionBuyer } from '../../app/type';

// API
import { buyerApi } from '../../common/api';

// Constants
import { RECURRENCE_ES } from '../../common/constants/subscriptions';

// Utils
import { formatCurrency } from '../../common/utils';

// Components
import { Container, Grid, Paper, Typography, Button, CircularProgress } from '@mui/material';

import Avatar from '../../common/components/Avatar';

// Assets
import styles from './UpdatePayment.module.scss';
import oneclickLogo from '../../assets/images/seller/payment/oneclick.png';

const UpdatePayment = (): React.ReactElement => {
  const { payableType, payableId } = useParams<{ payableType: string; payableId: string }>();
  const [subscriptionBuyer, setSubscriptionBuyer] = useState<SubscriptionBuyer>();
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<any>({});
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef(null);

  useEffect(() => {
    if (payableId) {
      setLoading(true);
      buyerApi.payables
        .serviceToPay(payableType, payableId)
        .then((data: ApiObject<SubscriptionBuyer>) => {
          setSubscriptionBuyer(data.data);
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error cargando el servicio', { variant: 'error' });
        })
        .finally(() => setLoading(false));
    }
  }, [payableId]);

  const onChangeCard = () => {
    setLoading(true);
    buyerApi.payables
      .changeCard(payableType, payableId)
      .then((body: any) => {
        setPaymentMethod(body.data);
      })
      .catch(() => {
        enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (paymentMethod?.url && formRef?.current) {
      // @ts-expect-error: formRef.current could be undefined
      formRef?.current.submit();
    }
  }, [paymentMethod, formRef]);

  const hiddenForm = () => {
    if (!paymentMethod?.url) return null;
    return (
      <form method="POST" action={paymentMethod.url} ref={formRef}>
        <input type="hidden" name="TBK_TOKEN" value={paymentMethod.token} />
      </form>
    );
  };

  const subscription = subscriptionBuyer?.subscription;
  const recurrence = RECURRENCE_ES[subscription?.recurrence || ''];

  return (
    <div className={styles.sellerContainer}>
      {loading && (
        <div className={styles.loaderContainer}>
          <img
            src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
            alt="Logo Zafepay"
          />
        </div>
      )}
      <div className={styles.topBar}>
        <a href="https://zafepay.com">
          <img
            src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_white.png"
            alt="Logo Zafepay"
            className={styles.opLogo}
          />
        </a>
      </div>
      {subscriptionBuyer?.card_type === 'KushkiCard' ? (
        <Container maxWidth="sm">Kushki</Container>
      ) : (
        <Container maxWidth="sm">
          <Paper className={styles.sellerBody}>
            <Grid container>
              <Grid item xs={12}>
                <Paper elevation={0} className={styles.sellerForm}>
                  <div className={styles.sellerHeader}>
                    <Avatar
                      className={styles.sellerHeaderAvatar}
                      text={subscriptionBuyer?.company_name}
                      context="company"
                    />
                    <div className={styles.sellerHeaderContent}>
                      <Typography variant="h5">{subscriptionBuyer?.company_name}</Typography>
                      <Typography variant="h6">{subscription?.name}</Typography>
                    </div>
                  </div>

                  <div className={styles.sellerPrice} style={{ textAlign: 'center' }}>
                    <Typography className={styles.sellerPriceAmount} variant="subtitle1">
                      <span>
                        <b>{formatCurrency(subscription?.render_amount, subscription?.currency)}</b>
                      </span>
                      <span>/ {recurrence}</span>
                    </Typography>
                  </div>

                  <Grid item xs={12} className={styles.actionsContainer}>
                    <Button
                      disableElevation
                      size="medium"
                      variant="contained"
                      disabled={loading}
                      className={`loader ${styles.submitButton}`}
                      onClick={onChangeCard}
                    >
                      {loading && <CircularProgress size={20} />}
                      Pagar
                    </Button>
                  </Grid>

                  <div className={styles.sellerFooter}>
                    <Typography variant="body1">
                      Serás redirigido a <strong>Transbank</strong>, donde completarás el resto del
                      proceso.
                    </Typography>
                    <img
                      src={oneclickLogo}
                      alt="oneclick logo"
                      className={styles.sellerFooterLogo}
                    />
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
          {hiddenForm()}
        </Container>
      )}
    </div>
  );
};

export default UpdatePayment;
