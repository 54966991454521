import { ApiObject, Company } from '../../../app/type';

import client from '../client';

export const show = (id: string): Promise<ApiObject<Company>> => {
  return client({
    method: 'get',
    url: `/v1/client/companies/${id}`,
  })
    .then(({ data: body }: { data: ApiObject<Company> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
