import { store } from '../../../app/store';
import { ApiList, PaymentMethod } from '../../../app/type';
import { startLoading, stopLoading } from '../../../features/seller/sellerSlice';

import client from '../client';

export const list = (companyId: string): Promise<ApiList<PaymentMethod>> => {
  store.dispatch(startLoading());
  return client({
    method: 'get',
    url: `/v1/payment_methods`,
    params: { company_id: companyId },
  })
    .then(({ data: body }: { data: ApiList<PaymentMethod> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    })
    .finally(() => {
      store.dispatch(stopLoading());
    });
};

export const update = (
  companyId: string,
  paymentMethods: string[]
): Promise<ApiList<PaymentMethod>> => {
  store.dispatch(startLoading());
  return client({
    method: 'put',
    url: `/v1/payment_methods/${companyId}`,
    params: { payment_methods: paymentMethods },
  })
    .then(({ data: body }: { data: ApiList<PaymentMethod> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    })
    .finally(() => {
      store.dispatch(stopLoading());
    });
};

export default {
  list,
  update,
};
