export const BUSINESS_ITEMS = [
  'Agroindustria',
  'Ambiental',
  'Cemento y Materiales',
  'Cultura',
  'Editorial e Imprenta',
  'Electrónica de Consumo',
  'Hipermercados',
  'Investigación',
  'Maquinaria y Equipo',
  'Poder ejecutivo y administración pública',
  'Recursos Humanos',
  'Servicios Funerarios',
  'Siderurgía y Metalurgia',
  'Aeronaves / Astilleros',
  'Agencia de Aduanas',
  'Agrícola / Ganadera',
  'Agropecuaria',
  'Agua / Obras Sanitarias',
  'Alimentos',
  'Arquitectura / Diseño / Decoración',
  'Automotriz',
  'Banca / Financiera',
  'Biotecnología',
  'Carpintería / Muebles',
  'Científica',
  'Combustibles (Gas / Petróleo)',
  'Comercio',
  'Comercio Electrónico',
  'Comercio Exterior',
  'Comercio Mayorista',
  'Comercio Minorista',
  'Construcción',
  'Consultoría / Asesoría',
  'Consumo Masivo',
  'Distribuidora',
  'Educación / Capacitación',
  'Energía / Combustibles',
  'Entretenimiento',
  'Estudios Jurídicos',
  'Exportación / importación',
  'Farmacéutica',
  'Forestal / Papel / Celulosa',
  'Administración Pública',
  'Hotelería / Restaurantes',
  'Inmobiliaria / Propiedades',
  'Imprenta / Editoriales',
  'Industria',
  'Informática / Tecnología',
  'Ingeniería',
  'Internet',
  'Inversiones (Soc / Cías/ Holding)',
  'Logística/ Distribución',
  'Manufacturas Varias',
  'Medicina / Salud',
  'Medios de Comunicación',
  'Metalmecánica',
  'Minería',
  'Organizaciones sin Fines de Lucro',
  'Pesquera / Cultivos Marinos',
  'Productora de Cine y Tv',
  'Publicidad / Marketing / RRPP',
  'Química',
  'Retail',
  'Salmonera',
  'Seguros / Previsión',
  'Seguridad',
  'Servicios Financieros Varios',
  'Servicios Varios',
  'Tecnología de Información',
  'Telecomunicaciones',
  'Textil',
  'Transporte',
  'Turismo',
  'Otra Actividad',
];
