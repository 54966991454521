import { ApiObject, Company, PaymentLinkConfiguration } from '../../../app/type';

import client from '../client';

export const show = (companyId: string): Promise<ApiObject<Company>> => {
  return client({
    method: 'get',
    url: `/v1/public/companies/${companyId}`,
  })
    .then(({ data: body }: { data: ApiObject<Company> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const paymentLinkConfiguration = (
  companyId: string
): Promise<ApiObject<PaymentLinkConfiguration>> => {
  return client({
    method: 'get',
    url: `/v1/public/companies/${companyId}/payment_link_configuration`,
  })
    .then(({ data: body }: { data: ApiObject<PaymentLinkConfiguration> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export default {
  show,
};
