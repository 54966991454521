import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '../../app/hooks';

// Features
import { SessionState } from './sessionSlice';

const Logout = (): React.ReactElement => {
  const { logged } = useSelector(({ session }: { session: SessionState }) => session);
  const history = useHistory();

  useEffect(() => {
    let autoRedirect: ReturnType<typeof setTimeout>;
    if (!logged) {
      history.replace('/');
    } else {
      autoRedirect = setTimeout(() => {
        history.replace('/');
      }, 5000);
    }

    return () => {
      if (autoRedirect) clearTimeout(autoRedirect);
    };
  }, [logged]);
  return <div />;
};

export default Logout;
