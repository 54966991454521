export const BUYER_EMAILS = [
  {
    key: 'charge_success_response',
    name: 'Comprobante de pago',
    description:
      'Correo que se envía a tus clientes para notificarles que realizaron un pago correctamente.',
  },
  {
    key: 'card_subscribed',
    name: 'Tarjeta inscrita exitosamente',
    description:
      'Correo que para notificar al cliente que el proceso de inscripción de su tarjeta (PAT) fue exitoso.',
  },
  {
    key: 'refund_payment_document',
    name: 'Comprobante de devolución',
    description: 'Correo que a tus clientes cuando se hace la devolución de un pago.',
  },
  {
    key: 'charge_subscription',
    name: 'Cobro de cuotas de una suscripción',
    description:
      'Correo que se envía a tus clientes para notificarles que deben realizar el pago de una suscripción en caso que no tengan un medio de pago automático inscrito.',
  },
  {
    key: 'charge_fail_subscription_response',
    name: 'Fallo de cobro a tarjeta inscrita por una Suscripción',
    description:
      'Este correo se envía a tus clientes para advertirles que se intentó realizar un cargo a su tarjeta inscrita sin éxito, y por lo tanto pasa a estar en deuda su inscripción.',
  },
  {
    key: 'charge_subscription_warning',
    name: 'Recordatorio de cobro próximo a la tarjeta inscrita por una Suscripción',
    description:
      'Este correo se envía a tus clientes para avisarles que se va a realizar un cobro a su tarjeta inscrita en los próximos días por alguna de las suscripciones a las que está inscrito.',
  },
  {
    key: 'updated_subscription',
    name: 'Estado de suscripción actualizada',
    description:
      'Correo para notificar a tus clientes cuando alguna de sus suscripciones es pausada, cancelada o activada.',
  },
  {
    key: 'subscription_updated_statement',
    name: 'Actualización en fecha de cobro de una Suscripción',
    description:
      'Correo para notificar a tus clientes la actualización en la fecha de vencimiento de alguna cuota de sus suscripciones.',
  },
  {
    key: 'charge_payment_plan',
    name: 'Cobro de cuotas de un plan de pago',
    description:
      'Correo que se envía a tus clientes para notificarles que deben realizar el pago de una cuota de un plan de pago en caso que no tenga un medio de pago automático inscrito.',
  },
  {
    key: 'enroll_payment',
    name: 'Enrolamiento de medio de pago automático a un Plan de pago',
    description:
      'Correo que se envía cada vez que se crea un plan de pago para invitar a tu cliente a inscribir un medio de pago automático al plan de pago.',
  },
  {
    key: 'charge_payment_plan_warning',
    name: 'Recordatorio de cobro próximo a la tarjeta inscrita por un Plan de pago',
    description:
      'Este correo se envía a tus clientes para avisarles que se va a realizar un cobro a su tarjeta inscrita en los próximos días por una cuota de un plan de pago.',
  },
  {
    key: 'charge_fail_payment_plan_response',
    name: 'Fallo de cobro a tarjeta inscrita por un Plan de pago',
    description:
      'Este correo se envía a tus clientes para advertirles que se intentó realizar un cargo a su tarjeta inscrita sin éxito, y por lo tanto pasa a estar en deuda la cuota del plan de pago.',
  },
  {
    key: 'pac_subscription_intent_succeeded',
    name: 'Inscripción de PAC en proceso',
    description:
      'Correo para notificar a tu cliente que se está procesando la solicitud de inscripción de un PAC.',
  },
  {
    key: 'pac_subscription_active',
    name: 'Inscripción de PAC exitosa',
    description:
      'Correo para notificar a tu cliente que el proceso de inscripción de su PAC fue exitoso.',
  },
  {
    key: 'pac_subscription_intent_failed',
    name: 'Inscripción de PAC rechazada',
    description:
      'Correo para notificar a tu cliente que el proceso de inscripción de su PAC fue rechazado e invitarlo a inscribirlo nuevamente.',
  },
  {
    key: 'pac_subscription_canceled',
    name: 'Inscripción de PAC cancelada',
    description:
      'Correo para notificar a tu cliente que un PAC fue cancelado e invitarlo a inscribir un medio de pago automático nuevamente.',
  },
];
