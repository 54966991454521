import { ApiList, ApiObject, PAC, PaymentPlan } from '../../../app/type';

import client from '../client';

export const createPAC = (
  clientId: string,
  paymentPlanId?: string,
  rejectedCardId?: string,
  productType?: string
): Promise<ApiObject<PAC>> => {
  return client({
    method: 'post',
    url: `/v1/client/fintoc_subscriptions`,
    params: {
      client_id: clientId,
      product_id: paymentPlanId,
      product_type: productType,
      rejected_card_id: rejectedCardId,
    },
  })
    .then(({ data: body }: { data: ApiObject<PAC> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const list = (clientId: string, params?: { [key: string]: any }): Promise<ApiList<PAC>> => {
  return client({
    method: 'get',
    url: `/v1/client/fintoc_subscriptions`,
    params: { client_id: clientId, ...params },
  })
    .then(({ data: body }: { data: ApiList<PAC> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const paymentPlans = (
  clientId: string,
  cardId: string
): Promise<ApiList<Partial<PaymentPlan>>> => {
  return client({
    method: 'get',
    url: `/v1/client/fintoc_subscriptions/${cardId}/payment_plans`,
    params: { client_id: clientId },
  })
    .then(({ data: body }: { data: ApiList<Partial<PaymentPlan>> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const assignPaymentPlans = (
  clientId: string,
  cardId: string,
  rejectedCardId: string
): Promise<ApiObject<PAC>> => {
  return client({
    method: 'post',
    url: `/v1/client/fintoc_subscriptions/${cardId}/assign_payment_plans`,
    params: { client_id: clientId, rejected_card_id: rejectedCardId },
  })
    .then(({ data: body }: { data: ApiObject<PAC> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const markAsInactive = (clientId: string, cardId: string): Promise<ApiObject<PAC>> => {
  return client({
    method: 'post',
    url: `/v1/client/fintoc_subscriptions/${cardId}/mark_as_inactive`,
    params: { client_id: clientId },
  })
    .then(({ data: body }: { data: ApiObject<PAC> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
