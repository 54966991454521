import React, { MutableRefObject } from 'react';

import { FormikProps } from 'formik';
import { Buyer, Discount, Payable, ShippingInformation } from '../../../../app/type';

import ProductDisplayerAndSelector from '../ProductDisplayer/ProductDisplayerAndSelector';
import PaymentGateways from '../../PaymentGateways';

import styles from './styles.module.scss';
import { Button } from '@mui/material';

interface ShopifyOrderProps {
  formik: FormikProps<Partial<Buyer>>;
  shippingFormik: FormikProps<ShippingInformation>;
  payable: Payable;
  setPayable: (arg: Payable) => void;
  cardRegistered: boolean;
  setCurrPaymentMethod: (arg: string) => void;
  currPaymentMethod: string;
  setCardRegistered: (arg: boolean) => void;
  selectPaymentMethod: (arg: string) => void;
  mercadoPagoRef: MutableRefObject<null>;
  mpEmailRef: MutableRefObject<null>;
  mpIssuer: string;
  mpLoaded: boolean;
  loadingPayment: boolean;
  setCardInfo: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  kushkiTokenFail: boolean;
  setLoadingPayment: (arg: boolean) => void;
  customRecurrenceProps?: {
    selectedRecurrence: string | null;
    setSelectedRecurrence: (arg: string | null) => void;
    amount: number | null;
    setAmount: (arg: number | null) => void;
    errors: { [key: string]: string | null };
  };
  prevStep: () => void;
  shippingCost: number | null;
  extraDiscount?: Partial<Discount>;
  productsToSubscribeState?: {
    state: string[] | undefined;
    setState: (a: string[]) => void;
  };
}

const ShopifyPayment = (props: ShopifyOrderProps): React.ReactElement => {
  return (
    <div className={styles.container}>
      <div className={styles.productDisplayContainer}>
        <ProductDisplayerAndSelector
          payable={props.payable}
          setPayable={props.setPayable}
          step="payment"
          shippingCost={props.shippingCost}
          customRecurrenceProps={props.customRecurrenceProps}
          extraDiscount={props.extraDiscount}
          productsToSubscribeState={props.productsToSubscribeState}
        />
      </div>
      <div className={styles.paymentsContainer}>
        <PaymentGateways
          payable={props.payable}
          cardRegistered={props.cardRegistered}
          setCurrPaymentMethod={props.setCurrPaymentMethod}
          currPaymentMethod={props.currPaymentMethod}
          setCardRegistered={props.setCardRegistered}
          selectPaymentMethod={props.selectPaymentMethod}
          mercadoPagoRef={props.mercadoPagoRef}
          mpEmailRef={props.mpEmailRef}
          mpIssuer={props.mpIssuer}
          mpLoaded={props.mpLoaded}
          loadingPayment={props.loadingPayment}
          overwriteStyles
          setCardInfo={props.setCardInfo}
          kushkiTokenFail={props.kushkiTokenFail}
          setLoadingPayment={props.setLoadingPayment}
        />
        <Button
          variant="outlined"
          className={styles.returnButton}
          style={{
            backgroundColor: 'white',
            color: props.payable?.company?.payment_design?.background_color || '#4653E3',
            borderColor: props.payable?.company?.payment_design?.background_color || '#4653E3',
          }}
          onClick={props.prevStep}
        >
          Volver
        </Button>
      </div>
    </div>
  );
};

export default ShopifyPayment;
