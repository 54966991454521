import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from '../../app/hooks';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { es } from 'yup-locales';

yup.setLocale(es);

// Features
import { SessionState } from './sessionSlice';

// API
import { sessionsApi } from '../../common/api';

// Components
import { Card, CircularProgress, Typography, TextField, Button, Grid } from '@mui/material';

// Assets
import styles from './Login.module.scss';

const RecoverySchema = yup.object().shape({
  email: yup.string().email().required().label('Correo'),
});

const ChangeSchema = yup.object().shape({
  password: yup.string().min(8).max(32).required().label('Contraseña'),
  confirmation: yup
    .string()
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], 'Contraseñas no coinciden'),
    })
    .required()
    .label('Confirmación'),
});

interface RecoveryInfo {
  email: string;
}
interface ChangeInfo {
  password: string;
  confirmation: string;
}

const Register = (): React.ReactElement => {
  const { loading } = useSelector(({ session }: { session: SessionState }) => session);
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useParams<{ token?: string }>();
  const history = useHistory();

  const onChange = (changeInfo: ChangeInfo) => {
    if (!token) return;
    sessionsApi
      .changePassword({ password: changeInfo.password, token })
      .then((): void => {
        enqueueSnackbar('Contraseña actualizada', { variant: 'info' });
        history.push('/auth/login');
      })
      .catch((err: any): void => {
        if (err?.response?.status === 400) {
          Object.values(err.response.data?.errors || {}).forEach((error) => {
            enqueueSnackbar(error as string, { variant: 'error' });
          });
        } else {
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
        }
      });
  };

  const onRecover = (recoveryInfo: RecoveryInfo) => {
    sessionsApi
      .recoverPassword(recoveryInfo.email)
      .then(() => {
        enqueueSnackbar('Te hemos enviado un correo con instrucciones', { variant: 'info' });
        history.push('/auth/login');
      })
      .catch((err: any): void => {
        console.error(err);
        enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
      });
  };

  let Form: React.ReactElement;
  if (token) {
    const formik = useFormik<ChangeInfo>({
      initialValues: { password: '', confirmation: '' },
      validationSchema: ChangeSchema,
      onSubmit: onChange,
    });
    Form = (
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="password"
          label="Nueva contraseña"
          type="password"
          variant="outlined"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />

        <TextField
          fullWidth
          id="confirmation"
          label="Confirma contraseña"
          type="password"
          variant="outlined"
          value={formik.values.confirmation}
          onChange={formik.handleChange}
          error={formik.touched.confirmation && Boolean(formik.errors.confirmation)}
          helperText={formik.touched.confirmation && formik.errors.confirmation}
        />

        <Button
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
          className="loader"
        >
          {loading && <CircularProgress size={20} />}
          Cambiar
        </Button>
      </form>
    );
  } else {
    const formik = useFormik<RecoveryInfo>({
      initialValues: { email: '' },
      validationSchema: RecoverySchema,
      onSubmit: onRecover,
    });
    Form = (
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="email"
          label="Correo"
          type="text"
          autoComplete="email"
          variant="outlined"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
          className="loader"
        >
          {loading && <CircularProgress size={20} />}
          Recuperar
        </Button>
      </form>
    );
  }

  return (
    <div className={styles.recoveryContainer}>
      <Card className={`${styles.card} ${styles.recoveryBox}`}>
        <Typography variant="h6" mb={5}>
          Recuperar contraseña
        </Typography>

        <div className={styles.form}>{Form}</div>

        <Grid container alignItems="center" justifyContent="space-between" direction="row" pt={3}>
          <Grid item xs={6} alignItems="flex-start">
            <Link to="/auth/login">
              <Typography>Iniciar sesión</Typography>
            </Link>
          </Grid>
          {token && (
            <Grid item alignItems="flex-end">
              <Link to="/auth/recovery">
                <Typography alignSelf="flex-end">Solicitar nuevo link</Typography>
              </Link>
            </Grid>
          )}
        </Grid>
      </Card>
      <img
        className={styles.logoImage}
        src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_white.png"
        alt="Logo Zafepay"
      />
    </div>
  );
};

export default Register;
