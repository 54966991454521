import React from 'react';
import { useSnackbar } from 'notistack';
import { useGoogleLogin } from '@react-oauth/google';
import TagManager from 'react-gtm-module';

// API
import { sessionsApi } from '../../common/api';

// Assets
import styles from './Login.module.scss';
import { Button, Avatar } from '@mui/material';

interface LoginProps {
  backoffice?: boolean;
  triggerTagManager?: boolean;
}

const Login = ({ backoffice, triggerTagManager }: LoginProps): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const responseGoogle = (response: any) => {
    console.error(response);
    enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      if (backoffice) {
        sessionsApi
          .backofficeLogin({
            user: { provider: 'google_oauth2', token: response.access_token },
          })
          .catch((err: any): void => {
            console.error(err);
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          });
      } else {
        sessionsApi
          .loginWithSocial('google_oauth2', response.access_token)
          .then(() => {
            triggerTagManager &&
              TagManager.dataLayer({
                dataLayer: { event: 'register' },
              });
          })
          .catch((err: any): void => {
            console.error(err);
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          });
      }
    },
    onError: responseGoogle,
  });

  return (
    <Button
      className={styles.googleButton}
      onClick={() => googleLogin()}
      startIcon={
        <Avatar
          src={'https://storage.googleapis.com/onlypays-public/assets/images/google_small_logo.png'}
        />
      }
    >
      Continuar con Google
    </Button>
  );
};

export default Login;
