import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { User } from '../../app/type';
import { AxiosError } from 'axios';

import { useQuery } from '../../common/utils';
import { sessionsApi } from '../../common/api';

import styles from './Confirmation.module.scss';
import { Typography, Button } from '@mui/material';

const Confirmation = (): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);
  const query = useQuery();
  const [user, setUser] = useState<User>();
  const [error, setError] = useState<number>();
  const history = useHistory();

  useEffect(() => {
    query.confirmation_token &&
      sessionsApi
        .confirmEmail(query.confirmation_token)
        .then(({ data: data }) => setUser(data))
        .catch((error: AxiosError) => {
          setError(error?.response?.status);
          Sentry.captureException(error);
        })
        .finally(() => setLoading(false));
  }, [query]);

  return loading ? (
    <div className={styles.loaderContainer}>
      <img
        src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
        alt="Logo Zafepay"
      />
    </div>
  ) : (
    <div className={styles.mainContainer}>
      <div className={styles.whiteSquare}>
        <div className={styles.imageCircle}>
          <img
            src={
              error === 403 || user
                ? 'https://storage.googleapis.com/onlypays-public/assets/images/green_check_mark.svg'
                : 'https://storage.googleapis.com/onlypays-public/assets/images/warning.svg'
            }
          />
        </div>
        {user ? (
          <div className={styles.textContainer}>
            <Typography fontSize={26} fontWeight={700}>
              ¡Muchas gracias por confirmar tu cuenta!
            </Typography>
            <Typography>Ahora puedes continuar usando Zafepay con normalidad</Typography>
            <Button onClick={() => history.replace('/seller/home')} variant="contained">
              Ir a Zafepay
            </Button>
          </div>
        ) : error === 403 ? (
          <div className={styles.textContainer}>
            <Typography fontSize={26} fontWeight={700}>
              ¡Ya has confirmado tu cuenta!
            </Typography>
            <Typography>Te invitamos a seguir usando Zafepay</Typography>
            <Button onClick={() => history.replace('/seller/home')} variant="contained">
              Ir a Zafepay
            </Button>
          </div>
        ) : (
          <div className={styles.textContainer}>
            <Typography fontSize={26} fontWeight={700}>
              ¡Ha ocurrido un error!
            </Typography>
            <Typography>Te recomendamos intentar nuevamente más tarde</Typography>
            <Button onClick={() => history.replace('/seller/home')} variant="contained">
              Ir a Zafepay
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Confirmation;
