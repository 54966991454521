import React, { Fragment, useState } from 'react';
import {
  Typography,
  Button,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { Payable, Suggestion } from '../../app/type';
import PopUp from '../../common/components/PopUp';

import styles from './BuyerSuggestion.module.scss';
import variables from '../../common/styles/variables.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

interface BuyerSuggestionProps {
  payable?: Payable;
  state: {
    open: boolean;
    setOpen: (s: boolean) => void;
  };
  suggestions: Suggestion[];
  acceptSuggestion: (suggestions: Suggestion[], selection: string | null) => void;
  abortSelectedSuggestion: (s: string | null | undefined) => void;
  subscriptionState: { state: string | null; setState: (s: string | null) => void };
  setSuggestionOffered: (o: boolean) => void;
}

const BuyerSuggestion = (props: BuyerSuggestionProps): React.ReactElement => {
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const singleSuggestion = props.suggestions.length === 1;
  const [selectSuggestion, openSelectSuggestion] = useState<boolean>(false);
  const companyColor = props.payable?.company.payment_design
    ? props.payable?.company.payment_design.background_color
    : '#4653E3';

  const extraFieldsRender = (suggestion: Suggestion) => {
    return Object.entries(props.payable?.extra_fields || {}).map(
      ([id, question]: [string, string]) => {
        return (
          <Grid item xs={12} md={6} key={id} className={styles.textContainer}>
            <Typography variant="caption">{question}</Typography>
            <Typography variant="subtitle1">{suggestion.extra_fields?.[id] || '-'}</Typography>
          </Grid>
        );
      }
    );
    return [<></>];
  };

  const handleRadio = (_: any, id: string) => {
    props.subscriptionState.setState(id);
  };

  const handleClosePopUp = () => {
    props.acceptSuggestion(props.suggestions, props.subscriptionState.state);
    openSelectSuggestion(false);
  };

  const handleAccordionChange = (expanded: boolean, suggestion: string) => {
    expanded
      ? props.subscriptionState.setState(suggestion)
      : props.subscriptionState.setState(null);
  };

  const handleSelectSubscriptionAccept = () => {
    props.state.setOpen(false);
    if (singleSuggestion) {
      props.acceptSuggestion(props.suggestions, props.subscriptionState.state);
    } else {
      openSelectSuggestion(true);
    }
  };

  const suggestionsAccordion = (
    <div className={styles.accordionContainer}>
      {props.suggestions.map((suggestion) => {
        return (
          <Accordion
            key={suggestion.id}
            className={styles.accordion}
            sx={{
              '&:before': {
                display: 'none',
              },
            }}
            onChange={(_, expanded) => handleAccordionChange(expanded, suggestion.id)}
            expanded={
              Object.keys(suggestion.extra_fields || {}).length > 0 &&
              suggestion.id === props.subscriptionState.state
            }
          >
            <AccordionSummary
              expandIcon={
                Object.keys(suggestion.extra_fields || {}).length > 0 && (
                  <FontAwesomeIcon icon={faChevronDown} />
                )
              }
            >
              <Grid container>
                <Grid item container xs={12} md={6}>
                  {!singleSuggestion ? (
                    <Fragment>
                      <Grid item xs={3}>
                        <Radio
                          checked={props.subscriptionState.state === suggestion.id}
                          value={suggestion.id}
                          onChange={(event) => handleRadio(event, suggestion.id)}
                          style={
                            props.subscriptionState.state === suggestion.id
                              ? { color: companyColor }
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid item xs={9} className={styles.textContainer}>
                        {!isMobile && <Typography variant="caption">Nombre</Typography>}
                        <Typography variant="subtitle1">{suggestion.buyer_name}</Typography>
                      </Grid>
                    </Fragment>
                  ) : (
                    <Grid item xs={12} md={5} className={styles.textContainer}>
                      {!isMobile && <Typography variant="caption">Nombre</Typography>}
                      <Typography variant="subtitle1">{suggestion.buyer_name}</Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} md={5} className={styles.textContainer}>
                  {!isMobile && <Typography variant="caption">Email</Typography>}
                  <Typography variant="subtitle1">{suggestion.buyer_email}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container className={styles.extraFieldsGrid}>
                {extraFieldsRender(suggestion)}
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );

  const selectSubscriptionPopUpRender = (
    <div className={styles.suggestionOptionsContainer}>
      <Typography variant="h5" className={styles.subtitle}>
        Encontramos{' '}
        {singleSuggestion
          ? 'la siguiente suscripción existente'
          : 'las siguientes suscripciones existentes'}
        :
      </Typography>
      {suggestionsAccordion}
    </div>
  );

  const subscriptionWarningPopUpRender = (
    <div className={styles.warningPopUpContentContainer}>
      <Typography fontWeight={600} fontSize={20} mb={1}>
        Hemos encontrado {singleSuggestion ? 'una suscripción' : 'suscripciones'} a este servicio
        con los mismos datos que ingresaste
      </Typography>
      <Typography fontWeight={400} fontSize={16} mb={2}>
        {singleSuggestion
          ? '¿Quieres continuar con la suscripción asociada a los datos que se muestran a continuación?'
          : '¿Quieres continuar con alguna de las suscripciones ya exitentes?'}
      </Typography>
      {singleSuggestion && suggestionsAccordion}
    </div>
  );

  const cancelStates = () => {
    props.state.setOpen(false);
    openSelectSuggestion(false);
    props.subscriptionState.setState(null);
  };

  return (
    <Fragment>
      <PopUp
        state={props.state}
        content={subscriptionWarningPopUpRender}
        extraActions={[
          <Button
            onClick={() => {
              props.setSuggestionOffered(true);
              cancelStates();
            }}
            variant="outlined"
            key={1}
            className={styles.cancelButton}
            style={{
              color: companyColor,
              borderColor: companyColor,
            }}
          >
            No, crear una nueva suscripción
          </Button>,
          <Button
            onClick={handleSelectSubscriptionAccept}
            variant="contained"
            key={2}
            className={styles.acceptButton}
            style={{
              color: 'white',
              background: companyColor,
            }}
          >
            {singleSuggestion
              ? 'Continuar con suscripción existente'
              : 'Ver suscripciones existentes'}
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: selectSuggestion, setOpen: openSelectSuggestion }}
        content={selectSubscriptionPopUpRender}
        extraActions={[
          <Button
            onClick={cancelStates}
            variant="outlined"
            key={1}
            className={styles.cancelButton}
            style={{
              color: companyColor,
              borderColor: companyColor,
            }}
          >
            Cancelar
          </Button>,
          <Button
            onClick={handleClosePopUp}
            variant="contained"
            key={2}
            className={styles.acceptButton}
            style={{
              background: companyColor,
              color: 'white',
            }}
            disabled={!singleSuggestion && props.subscriptionState.state === null}
          >
            Aplicar suscripción
          </Button>,
        ]}
      />
    </Fragment>
  );
};

export default BuyerSuggestion;
