import {
  ApiObject,
  ApiList,
  Statement,
  PaymentTransaction,
  GenericObject,
  ProductHistory,
  InvoiceDocument,
} from '../../../app/type';

import client from '../client';

export const list = (
  companyId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: any }
): Promise<ApiList<Statement>> => {
  return client({
    method: 'get',
    url: `/v1/statements`,
    params: { company_id: companyId, query, page: page || 1, filter_params: filterParams },
  })
    .then(({ data: body }: { data: ApiList<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const markAsPaid = (statementId: string): Promise<ApiObject<Statement>> => {
  return client({
    method: 'post',
    url: `/v1/statements/${statementId}/mark_as_paid`,
  })
    .then(({ data: body }: { data: ApiObject<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const statementsExcel = (
  parent?: string,
  parentId?: string,
  query?: string,
  filterParams?: { [key: string]: string | Date | number | null | undefined },
  onlyPaid?: boolean
): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: '/v1/statements/statements_excel',
    params: {
      parent: parent,
      parent_id: parentId,
      query,
      filter_params: filterParams || {},
      only_paid: onlyPaid,
    },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const refund = (
  statementId: string,
  groupId?: string,
  status?: string
): Promise<GenericObject> => {
  return client
    .post(`v1/statements/${statementId}/refund`, { group_id: groupId, status: status })
    .then(({ data: body }: { data: GenericObject }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const boxesData = (companyId?: string): Promise<{ [key: string]: string }> => {
  return client({
    method: 'get',
    url: `/v1/statements/transparency_boxes_data`,
    params: { company_id: companyId },
  })
    .then(({ data: body }: { data: { [key: string]: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const paymentTransactions = (statementId: string): Promise<ApiList<PaymentTransaction>> => {
  return client({
    method: 'get',
    url: `/v1/statements/${statementId}/payment_transactions`,
  })
    .then(({ data: body }: { data: ApiList<PaymentTransaction> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const updateDueDate = (
  statementId: string,
  dueDate: string,
  updateDop: boolean
): Promise<ApiObject<Statement>> => {
  return client({
    method: 'post',
    url: `/v1/statements/${statementId}/update_due_date`,
    data: { due_date: dueDate, update_dop: updateDop },
  })
    .then(({ data: body }: { data: ApiObject<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const retryCharge = (statementId: string): Promise<ApiObject<Statement>> => {
  return client({
    method: 'post',
    url: `/v1/statements/${statementId}/retry_charge`,
  })
    .then(({ data: body }: { data: ApiObject<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const detailStatementsExcel = (
  parentId?: string,
  query?: string,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: '/v1/statements/detail_statements_excel',
    params: { company_id: parentId, query, filter_params: filterParams || {} },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const productsHistory = (statementId: string): Promise<ApiList<ProductHistory>> => {
  return client({
    method: 'get',
    url: `/v1/statements/${statementId}/product_histories`,
  })
    .then(({ data: body }: { data: ApiList<ProductHistory> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const retryInvoiceDocument = (
  statementId: string,
  invoiceDocumentId: string
): Promise<ApiObject<InvoiceDocument>> => {
  return client({
    method: 'post',
    url: `/v1/statements/${statementId}/retry_invoice_document`,
    params: { invoice_document_id: invoiceDocumentId },
  })
    .then(({ data: body }: { data: ApiObject<InvoiceDocument> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export default {
  list,
  markAsPaid,
  statementsExcel,
  refund,
  boxesData,
  paymentTransactions,
  updateDueDate,
  productsHistory,
  retryInvoiceDocument,
};
