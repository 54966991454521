import { DefaultExtraFieldRequestResponse } from '../../../app/type';

import client from '../client';

export const list = (companyId: string): Promise<DefaultExtraFieldRequestResponse> => {
  return client({
    method: 'get',
    url: `/v1/default_extra_fields`,
    params: { id: companyId },
  })
    .then(({ data: body }: { data: DefaultExtraFieldRequestResponse }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};
