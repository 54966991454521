import { ApiList, ApiObject, BankInformation } from '../../../app/type';
import client from '../client';

interface CreateData {
  data: Partial<BankInformation>;
  set?: boolean;
}
export const create = (data: CreateData): Promise<ApiObject<BankInformation>> => {
  return client({
    method: 'post',
    url: '/v1/bank_informations',
    data,
  })
    .then(({ data: body }: { data: ApiObject<BankInformation> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ createError: error });
      throw error;
    });
};

interface UpdateData {
  data: Partial<BankInformation>;
  set?: boolean;
}
export const update = (data: UpdateData): Promise<ApiObject<BankInformation>> => {
  return client({
    method: 'put',
    url: `/v1/bank_informations/${data.data.id}`,
    data,
  })
    .then(({ data: body }: { data: ApiObject<BankInformation> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const destroy = (bankInformationId?: string): Promise<ApiObject<BankInformation>> => {
  return client({
    method: 'delete',
    url: `/v1/bank_informations/${bankInformationId}`,
  })
    .then(({ data: body }: { data: ApiObject<BankInformation> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const index = (companyId?: string): Promise<ApiList<BankInformation>> => {
  return client({
    method: 'get',
    url: `/v1/bank_informations?company_id=${companyId}`,
  })
    .then(({ data: body }: { data: ApiList<BankInformation> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export default {
  create,
  update,
  index,
};
