import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from '../../app/hooks';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { es } from 'yup-locales';

yup.setLocale(es);

import GoogleLogin from './GoogleLogin';
import TagManager from 'react-gtm-module';

// Features
import { SessionState } from './sessionSlice';

// API
import { sessionsApi } from '../../common/api';

// Components
import {
  Container,
  Grid,
  Card,
  CircularProgress,
  Typography,
  TextField,
  Button,
} from '@mui/material';

import Recaptcha from '../../common/components/Recaptcha';

// Assets
import styles from './Login.module.scss';
import { setDocumentTitle } from '../../common/utils';

const LoginSchema = yup.object().shape({
  email: yup.string().email().required().label('Correo'),
  password: yup.string().max(32).required().label('Contraseña'),
});

interface LoginInfo {
  email: string;
  password: string;
}

const Login = (): React.ReactElement => {
  setDocumentTitle('Zafepay | Inicio de Sesión');
  const { loading } = useSelector(({ session }: { session: SessionState }) => session);
  const { enqueueSnackbar } = useSnackbar();
  const recaptchaRef = useRef<{ execute: () => Promise<string> }>();

  const formik = useFormik<LoginInfo>({
    initialValues: { email: '', password: '' },
    validationSchema: LoginSchema,
    onSubmit: (loginInfo: LoginInfo, { setErrors }: any) => {
      recaptchaRef.current
        ?.execute()
        .then((token: string) => {
          sessionsApi.loginWithEmail(loginInfo, token).catch((err: any): void => {
            if (err?.response?.status === 400) {
              setErrors(err.response.data?.meta?.errors);
            } else if (err?.response?.status === 401) {
              if (err?.response?.data?.errors?.[0] === 'Unconfirmed') {
                enqueueSnackbar('Debes confirmar tu correo', { variant: 'error' });
              } else {
                enqueueSnackbar('Correo o contraseña incorrecta', { variant: 'error' });
              }
            } else if (err?.response?.status === 422) {
              enqueueSnackbar('reCAPTCHA fallido', { variant: 'error' });
            } else {
              enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
            }
          });
        })
        .catch(() => enqueueSnackbar('reCAPTCHA fallido', { variant: 'error' }));
    },
  });

  const executeTagManager = () => {
    TagManager.dataLayer({
      dataLayer: { event: 'redirect_to_register' },
    });
  };

  return (
    <Container className={styles.baseContainer} maxWidth={false}>
      <Container className={styles.logoLoginContainer}>
        <Grid container>
          <Grid item>
            <img
              src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_white.png"
              alt="Logo Zafepay"
              className={styles.logoImage}
            />
          </Grid>
          <Grid item>
            <Typography variant="h3">Desde hoy todo es más simple.</Typography>
            <Typography variant="h5">Realiza tus cobros de forma:</Typography>
            <Typography variant="h5">
              <strong>fácil, rápida y segura.</strong>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container className={styles.container}>
        <Recaptcha innerRef={recaptchaRef} />
        <Card className={styles.card}>
          <Grid container className={styles.grid}>
            <Grid item>
              <Typography variant="h3">¡Hola &#128075;!</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" align="center">
                Te damos la bienvenida a la plataforma de <strong>Zafepay</strong>, en donde podrás
                realizar tus cobros.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">Iniciar sesión</Typography>
            </Grid>
            <Grid item className={styles.formGridItem}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>Correo</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="email"
                      type="text"
                      autoComplete="email"
                      variant="outlined"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Contraseña</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="password"
                      type="password"
                      variant="outlined"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <Link to="/auth/recovery">¿Olvidaste tu contraseña?</Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      disableElevation
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading}
                      className="loader"
                    >
                      {loading && <CircularProgress size={20} />}
                      Ingresar
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      disableElevation
                      size="large"
                      variant="outlined"
                      color="primary"
                      type="submit"
                      disabled={loading}
                      component={Link}
                      to="/auth/signup"
                      onClick={executeTagManager}
                    >
                      Registrarse
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item>
              <GoogleLogin />
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Container>
  );
};

export default Login;
