import client from '../../client';

import { ApiObject, Order } from '../../../../app/type';

export const show = (orderId: number, companyId: string): Promise<ApiObject<Order>> => {
  return client({
    method: 'get',
    url: `/v1/public/shopify/orders/${orderId}?company_id=${companyId}`,
  })
    .then(({ data: body }: { data: ApiObject<Order> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const subscribe = (
  paymentMethod: string,
  cardRegistered: boolean,
  extraData: { [key: string]: any },
  order: Order,
  companyId?: string
): Promise<any> => {
  return client({
    method: 'post',
    url: `/v1/public/shopify/orders/${order?.order_id}/subscribe`,
    params: {
      company_id: companyId,
      customer: order.customer,
      items: JSON.stringify(order.items),
      payment_method: paymentMethod,
      card_registered: cardRegistered,
      extra_data: extraData,
      shipping: order.shipping,
    },
  })
    .then(({ data: body }: { data: any }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
