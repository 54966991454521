import React from 'react';

import { backofficeApi } from '../../common/api';
import { Transfer } from '../../app/type';
import { formatCurrency } from '../../common/utils';

import ResourceList from '../../common/components/ResourceList';
import StatusLabel from '../../common/components/StatusLabel';

const Transfers = (): React.ReactElement => {
  return (
    <ResourceList
      title="Transferencias"
      queryFields="holahola"
      getResourceList={(_id, _, page) => backofficeApi.transfers.list(page)}
      listHeaders={[
        { key: 'transfer_date', label: 'Fecha Transferencia' },
        { key: 'company', label: 'Empresa' },
        { key: 'amount', label: 'Monto' },
        { key: 'status', label: 'Estado' },
      ]}
      listColumns={{
        transfer_date: (response: Transfer) => response.created_at,
        company: (response: Transfer) => response.company.name,
        amount: (response: Transfer) => formatCurrency(response.amount),
        status: (response: Transfer) => <StatusLabel status={response.status} type="transfer" />,
      }}
      resourceParent={{ id: '' }}
      hideQuery
    />
  );
};

export default Transfers;
