import client from '../../client';

export const variantStock = (
  variantIds?: string[] | undefined
): Promise<{ stock: { [key: string]: number } | string }> => {
  return client({
    method: 'get',
    url: `/v1/public/shopify/products/variant_stock`,
    params: { ids: variantIds },
  })
    .then(({ data: body }: { data: { stock: { [key: string]: number } | string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
