import { pick } from 'lodash';

import { store } from '../../app/store';
import { ApiObject, User } from '../../app/type';

import {
  loginSuccess,
  loginError,
  logoutUser,
  startLoading,
  stopLoading,
  backofficeLoginSuccess,
  backofficeLoginError,
  backofficeLogoutUser,
  clientLogoutSession,
} from '../../features/session/sessionSlice';

import { clearData as clearSellerData } from '../../features/seller/sellerSlice';
import { clearData as clearBackofficeData } from '../../features/backoffice/backofficeSlice';
import { clearData as clearClientData } from '../../features/client/clientSlice';

import client from './client';

interface SignupData {
  user: { name: string; email: string; password: string };
  recaptcha?: string;
}
export const signup = (data: SignupData): any => {
  store.dispatch(startLoading());
  return client({
    method: 'post',
    url: '/signup',
    data,
  })
    .then(({ data: body, headers }: any) => {
      const user = {
        ...pick(body.data, ['id', 'email', 'name', 'new_record']),
        accessToken: headers.authorization,
      } as User;
      store.dispatch(loginSuccess({ user }));
      return body;
    })
    .catch((error: any) => {
      console.error({ signupError: error });
      store.dispatch(loginError());
      throw error;
    });
};

interface LoginData {
  user: { provider: string; email?: string; password?: string; token?: string };
  recaptcha?: string;
}
export const login = (data: LoginData): any => {
  store.dispatch(startLoading());
  return client({
    method: 'post',
    url: '/login',
    data,
  })
    .then(({ data: body, headers }: any) => {
      const user = {
        ...pick(body.data, ['id', 'email', 'name', 'new_record', 'confirmed']),
        accessToken: headers.authorization,
      } as User;
      store.dispatch(loginSuccess({ user }));
      return body;
    })
    .catch((error: any) => {
      console.error({ loginError: error });
      store.dispatch(loginError());
      throw error;
    });
};

export const backofficeLogin = (data: LoginData): any => {
  store.dispatch(startLoading());
  return client({
    method: 'post',
    url: '/backoffice/login',
    data,
  })
    .then(({ data: body, headers }: any) => {
      const user = {
        ...pick(body.data, ['id', 'email', 'name', 'new_record']),
        accessToken: headers.authorization,
      } as User;
      store.dispatch(backofficeLoginSuccess({ user }));
    })
    .catch((error: any) => {
      console.error({ backofficeLoginError: error });
      store.dispatch(backofficeLoginError());
      throw error;
    });
};

export const loginWithEmail = (
  user: { email: string; password: string },
  recaptcha?: string
): any => {
  return login({ user: { ...user, provider: 'email' }, recaptcha });
};

export const loginWithSocial = (provider: string, token: string): any => {
  return login({ user: { provider, token } });
};

export const logout = (): any => {
  return client({
    method: 'delete',
    url: '/logout',
  }).finally(() => {
    store.dispatch(clearSellerData());
    store.dispatch(logoutUser());
  });
};

export const backofficeLogout = (): any => {
  return client({
    method: 'delete',
    url: '/backoffice/logout',
  }).finally(() => {
    store.dispatch(clearBackofficeData());
    store.dispatch(backofficeLogoutUser());
  });
};

export const recoverPassword = (email: string): any => {
  store.dispatch(startLoading());
  return client({
    method: 'post',
    url: '/v1/users/password_recovery',
    data: { email },
  }).finally(() => {
    store.dispatch(logoutUser());
  });
};

export const updatePassword = (change: { password: string; new_password: string }): any => {
  store.dispatch(startLoading());
  return client({
    method: 'post',
    url: '/v1/users/update_password',
    data: change,
  }).finally(() => {
    store.dispatch(stopLoading());
  });
};

export const changePassword = (change: { token: string; password: string }): any => {
  store.dispatch(startLoading());
  return client({
    method: 'post',
    url: '/v1/users/change_password',
    data: change,
  }).finally(() => {
    store.dispatch(logoutUser());
  });
};

export const confirmEmail = (token: string): Promise<ApiObject<User>> => {
  return client({
    method: 'get',
    url: `/confirmation/?confirmation_token=${token}`,
  });
};

export const resendConfirmationEmail = (): any => {
  return client({
    method: 'post',
    url: `/confirmations/resend_confirmation`,
  });
};

export const clientLogout = (): any => {
  store.dispatch(clearClientData());
  store.dispatch(clientLogoutSession());
};

export default {
  login,
  loginWithEmail,
  loginWithSocial,
  logout,
  recoverPassword,
  changePassword,
  backofficeLogin,
  confirmEmail,
};
