import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Company, Client } from '../../app/type';

export interface ClientState {
  client?: Client;
  company?: Partial<Company>;
  company_id?: string;
}

const initialState: ClientState = {};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClient: (state: ClientState, action: PayloadAction<Client | undefined>) => {
      if (action.payload) {
        state.client = action.payload;
      } else {
        state.client = undefined;
      }
    },
    setCompany: (state: ClientState, action: PayloadAction<Partial<Company> | undefined>) => {
      if (action.payload) {
        state.company = action.payload;
        state.company_id = action.payload.id;
      } else {
        state.company = undefined;
      }
    },
    clearData: (state: ClientState) => {
      state.company = undefined;
      state.client = undefined;
      return;
    },
  },
});

export const { setClient, setCompany, clearData } = clientSlice.actions;

export default clientSlice.reducer;
