export const COMMUNES = [
  'Arica',
  'Camarones',
  'Putre',
  'General Lagos',
  'Iquique',
  'Alto Hospicio',
  'Pozo Almonte',
  'Camiña',
  'Colchane',
  'Huara',
  'Pica',
  'Antofagasta',
  'Mejillones',
  'Sierra Gorda',
  'Taltal',
  'Calama',
  'Ollagüe',
  'San Pedro de Atacama',
  'Tocopilla',
  'María Elena',
  'Chañaral',
  'Diego de Almagro',
  'Caldera',
  'Copiapó',
  'Tierra Amarilla',
  'Alto del Carmen',
  'Freirina',
  'Huasco',
  'Vallenar',
  'Canela',
  'Illapel',
  'Los Vilos',
  'Salamanca',
  'Andacollo',
  'Coquimbo',
  'La Higuera',
  'La Serena',
  'Paihuaco',
  'Vicuña',
  'Combarbalá',
  'Monte Patria',
  'Ovalle',
  'Punitaqui',
  'Río Hurtado',
  'Isla de Pascua',
  'Calle Larga',
  'Los Andes',
  'Rinconada',
  'San Esteban',
  'La Ligua',
  'Papudo',
  'Petorca',
  'Zapallar',
  'Hijuelas',
  'La Calera',
  'La Cruz',
  'Limache',
  'Nogales',
  'Olmué',
  'Quillota',
  'Algarrobo',
  'Cartagena',
  'El Quisco',
  'El Tabo',
  'San Antonio',
  'Santo Domingo',
  'Catemu',
  'Llaillay',
  'Panquehue',
  'Putaendo',
  'San Felipe',
  'Santa María',
  'Casablanca',
  'Concón',
  'Juan Fernández',
  'Puchuncaví',
  'Quilpué',
  'Quintero',
  'Valparaíso',
  'Villa Alemana',
  'Viña del Mar',
  'Colina',
  'Lampa',
  'Tiltil',
  'Pirque',
  'Puente Alto',
  'San José de Maipo',
  'Buin',
  'Calera de Tango',
  'Paine',
  'San Bernardo',
  'Alhué',
  'Curacaví',
  'María Pinto',
  'Melipilla',
  'San Pedro',
  'Cerrillos',
  'Cerro Navia',
  'Conchalí',
  'El Bosque',
  'Estación Central',
  'Huechuraba',
  'Independencia',
  'La Cisterna',
  'La Granja',
  'La Florida',
  'La Pintana',
  'La Reina',
  'Las Condes',
  'Lo Barnechea',
  'Lo Espejo',
  'Lo Prado',
  'Macul',
  'Maipú',
  'Ñuñoa',
  'Pedro Aguirre Cerda',
  'Peñalolén',
  'Providencia',
  'Pudahuel',
  'Quilicura',
  'Quinta Normal',
  'Recoleta',
  'Renca',
  'San Miguel',
  'San Joaquín',
  'San Ramón',
  'Santiago',
  'Vitacura',
  'El Monte',
  'Isla de Maipo',
  'Padre Hurtado',
  'Peñaflor',
  'Talagante',
  'Codegua',
  'Coínco',
  'Coltauco',
  'Doñihue',
  'Graneros',
  'Las Cabras',
  'Machalí',
  'Malloa',
  'Mostazal',
  'Olivar',
  'Peumo',
  'Pichidegua',
  'Quinta de Tilcoco',
  'Rancagua',
  'Rengo',
  'Requínoa',
  'San Vicente de Tagua Tagua',
  'La Estrella',
  'Litueche',
  'Marchihue',
  'Navidad',
  'Peredones',
  'Pichilemu',
  'Chépica',
  'Chimbarongo',
  'Lolol',
  'Nancagua',
  'Palmilla',
  'Peralillo',
  'Placilla',
  'Pumanque',
  'San Fernando',
  'Santa Cruz',
  'Cauquenes',
  'Chanco',
  'Pelluhue',
  'Curicó',
  'Hualañé',
  'Licantén',
  'Molina',
  'Rauco',
  'Romeral',
  'Sagrada Familia',
  'Teno',
  'Vichuquén',
  'Colbún',
  'Linares',
  'Longaví',
  'Parral',
  'Retiro',
  'San Javier',
  'Villa Alegre',
  'Yerbas Buenas',
  'Constitución',
  'Curepto',
  'Empedrado',
  'Maule',
  'Pelarco',
  'Pencahue',
  'Río Claro',
  'San Clemente',
  'San Rafael',
  'Talca',
  'Bulnes',
  'Chillán',
  'Chillán Viejo',
  'Cobquecura',
  'Coelemu',
  'Coihueco',
  'El Carmen',
  'Ninhue',
  'Ñiquén',
  'Pemuco',
  'Pinto',
  'Portezuelo',
  'Quirihue',
  'Ránquil',
  'Treguaco',
  'Quillón',
  'San Carlos',
  'San Fabián',
  'San Ignacio',
  'San Nicolás',
  'Yungay',
  'Arauco',
  'Cañete',
  'Contulmo',
  'Curanilahue',
  'Lebu',
  'Los Álamos',
  'Tirúa',
  'Alto Biobío',
  'Antuco',
  'Cabrero',
  'Laja',
  'Los Ángeles',
  'Mulchén',
  'Nacimiento',
  'Negrete',
  'Quilaco',
  'Quilleco',
  'San Rosendo',
  'Santa Bárbara',
  'Tucapel',
  'Yumbel',
  'Chiguayante',
  'Concepción',
  'Coronel',
  'Florida',
  'Hualpén',
  'Hualqui',
  'Lota',
  'Penco',
  'San Pedro de la Paz',
  'Santa Juana',
  'Talcahuano',
  'Tomé',
  'Carahue',
  'Cholchol',
  'Cunco',
  'Curarrehue',
  'Freire',
  'Galvarino',
  'Gorbea',
  'Lautaro',
  'Loncoche',
  'Melipeuco',
  'Nueva Imperial',
  'Padre las Casas',
  'Perquenco',
  'Pitrufquén',
  'Pucón',
  'Saavedra',
  'Temuco',
  'Teodoro Schmidt',
  'Toltén',
  'Vilcún',
  'Villarrica',
  'Angol',
  'Collipulli',
  'Curacautín',
  'Ercilla',
  'Lonquimay',
  'Los Sauces',
  'Lumaco',
  'Purén',
  'Renaico',
  'Traiguén',
  'Victoria',
  'Corral',
  'Lanco',
  'Los Lagos',
  'Máfil',
  'Mariquina',
  'Paillaco',
  'Panguipulli',
  'Valdivia',
  'Futrono',
  'La Unión',
  'Lago Ranco',
  'Río Bueno',
  'Ancud',
  'Castro',
  'Chonchi',
  'Curaco de Vélez',
  'Dalcahue',
  'Puqueldón',
  'Queilén',
  'Quemchi',
  'Quellón',
  'Quinchao',
  'Calbuco',
  'Cochamó',
  'Fresia',
  'Frutillar',
  'Llanquihue',
  'Los Muermos',
  'Maullín',
  'Puerto Montt',
  'Puerto Varas',
  'Osorno',
  'Puerto Octay',
  'Purranque',
  'Puyehue',
  'Río Negro',
  'San Juan de la Costa',
  'San Pablo',
  'Chaitén',
  'Futaleufú',
  'Hualaihué',
  'Palena',
  'Aisén',
  'Cisnes',
  'Guaitecas',
  'Cochrane',
  "O'Higgins",
  'Tortel',
  'Coihaique',
  'Lago Verde',
  'Chile Chico',
  'Río Ibáñez',
  'Antártica',
  'Cabo de Hornos (Ex Navarino)',
  'Laguna Blanca',
  'Punta Arenas',
  'Río Verde',
  'San Gregorio',
  'Porvenir',
  'Primavera',
  'Timaukel',
  'Natales',
  'Torres del Paine',
  'Cabildo',
  'Paiguano',
  'Coinco',
  'Paredones',
];

export const COMMUNES_BY_REGION: { [key: string]: string[] } = {
  'Arica y Parinacota': ['Arica', 'Camarones', 'Putre', 'General Lagos'],
  Tarapacá: ['Iquique', 'Alto Hospicio', 'Pozo Almonte', 'Camiña', 'Colchane', 'Huara', 'Pica'],
  Antofagasta: [
    'Antofagasta',
    'Mejillones',
    'Sierra Gorda',
    'Taltal',
    'Calama',
    'Ollagüe',
    'San Pedro de Atacama',
    'Tocopilla',
    'María Elena',
  ],
  Atacama: [
    'Copiapó',
    'Caldera',
    'Tierra Amarilla',
    'Chañaral',
    'Diego de Almagro',
    'Vallenar',
    'Alto del Carmen',
    'Freirina',
    'Huasco',
  ],
  Coquimbo: [
    'La Serena',
    'Coquimbo',
    'Andacollo',
    'La Higuera',
    'Paiguano',
    'Vicuña',
    'Illapel',
    'Canela',
    'Los Vilos',
    'Salamanca',
    'Ovalle',
    'Combarbalá',
    'Monte Patria',
    'Punitaqui',
    'Río Hurtado',
  ],
  Valparaíso: [
    'Valparaíso',
    'Casablanca',
    'Concón',
    'Juan Fernández',
    'Puchuncaví',
    'Quintero',
    'Viña del Mar',
    'Isla de Pascua',
    'Los Andes',
    'Calle Larga',
    'Rinconada',
    'San Esteban',
    'La Ligua',
    'Cabildo',
    'Papudo',
    'Petorca',
    'Zapallar',
    'Quillota',
    'Calera',
    'Hijuelas',
    'La Cruz',
    'Nogales',
    'San Antonio',
    'Algarrobo',
    'Cartagena',
    'El Quisco',
    'El Tabo',
    'Santo Domingo',
    'San Felipe',
    'Catemu',
    'Llaillay',
    'Panquehue',
    'Putaendo',
    'Santa María',
    'Quilpué',
    'Limache',
    'Olmué',
    'Villa Alemana',
  ],
  "O'Higgins": [
    'Rancagua',
    'Codegua',
    'Coinco',
    'Coltauco',
    'Doñihue',
    'Graneros',
    'Las Cabras',
    'Machalí',
    'Malloa',
    'Mostazal',
    'Olivar',
    'Peumo',
    'Pichidegua',
    'Quinta de Tilcoco',
    'Rengo',
    'Requínoa',
    'San Vicente',
    'Pichilemu',
    'La Estrella',
    'Litueche',
    'Marchihue',
    'Navidad',
    'Paredones',
    'San Fernando',
    'Chépica',
    'Chimbarongo',
    'Lolol',
    'Nancagua',
    'Palmilla',
    'Peralillo',
    'Placilla',
    'Pumanque',
    'Santa Cruz',
  ],
  Maule: [
    'Talca',
    'Constitución',
    'Curepto',
    'Empedrado',
    'Maule',
    'Pelarco',
    'Pencahue',
    'Río Claro',
    'San Clemente',
    'San Rafael',
    'Cauquenes',
    'Chanco',
    'Pelluhue',
    'Curicó',
    'Hualañé',
    'Licantén',
    'Molina',
    'Rauco',
    'Romeral',
    'Sagrada Familia',
    'Teno',
    'Vichuquén',
    'Linares',
    'Colbún',
    'Longaví',
    'Parral',
    'Retiro',
    'San Javier',
    'Villa Alegre',
    'Yerbas Buenas',
  ],
  Ñuble: [
    'Cobquecura',
    'Coelemu',
    'Ninhue',
    'Portezuelo',
    'Quirihue',
    'Ránquil',
    'Treguaco',
    'Bulnes',
    'Chillán Viejo',
    'Chillán',
    'El Carmen',
    'Pemuco',
    'Pinto',
    'Quillón',
    'San Ignacio',
    'Yungay',
    'Coihueco',
    'Ñiquén',
    'San Carlos',
    'San Fabián',
    'San Nicolás',
  ],
  Biobío: [
    'Concepción',
    'Coronel',
    'Chiguayante',
    'Florida',
    'Hualqui',
    'Lota',
    'Penco',
    'San Pedro de la Paz',
    'Santa Juana',
    'Talcahuano',
    'Tomé',
    'Hualpén',
    'Lebu',
    'Arauco',
    'Cañete',
    'Contulmo',
    'Curanilahue',
    'Los Álamos',
    'Tirúa',
    'Los Ángeles',
    'Antuco',
    'Cabrero',
    'Laja',
    'Mulchén',
    'Nacimiento',
    'Negrete',
    'Quilaco',
    'Quilleco',
    'San Rosendo',
    'Santa Bárbara',
    'Tucapel',
    'Yumbel',
    'Alto Biobío',
  ],
  Araucanía: [
    'Temuco',
    'Carahue',
    'Cunco',
    'Curarrehue',
    'Freire',
    'Galvarino',
    'Gorbea',
    'Lautaro',
    'Loncoche',
    'Melipeuco',
    'Nueva Imperial',
    'Padre las Casas',
    'Perquenco',
    'Pitrufquén',
    'Pucón',
    'Saavedra',
    'Teodoro Schmidt',
    'Toltén',
    'Vilcún',
    'Villarrica',
    'Cholchol',
    'Angol',
    'Collipulli',
    'Curacautín',
    'Ercilla',
    'Lonquimay',
    'Los Sauces',
    'Lumaco',
    'Purén',
    'Renaico',
    'Traiguén',
    'Victoria',
  ],
  'Los Ríos': [
    'Valdivia',
    'Corral',
    'Lanco',
    'Los Lagos',
    'Máfil',
    'Mariquina',
    'Paillaco',
    'Panguipulli',
    'La Unión',
    'Futrono',
    'Lago Ranco',
    'Río Bueno',
  ],
  'Los Lagos': [
    'Puerto Montt',
    'Calbuco',
    'Cochamó',
    'Fresia',
    'Frutillar',
    'Los Muermos',
    'Llanquihue',
    'Maullín',
    'Puerto Varas',
    'Castro',
    'Ancud',
    'Chonchi',
    'Curaco de Vélez',
    'Dalcahue',
    'Puqueldón',
    'Queilén',
    'Quellón',
    'Quemchi',
    'Quinchao',
    'Osorno',
    'Puerto Octay',
    'Purranque',
    'Puyehue',
    'Río Negro',
    'San Juan de la Costa',
    'San Pablo',
    'Chaitén',
    'Futaleufú',
    'Hualaihué',
    'Palena',
  ],
  Aysén: [
    'Coihaique',
    'Lago Verde',
    'Aisén',
    'Cisnes',
    'Guaitecas',
    'Cochrane',
    "O'Higgins",
    'Tortel',
    'Chile Chico',
    'Río Ibáñez',
  ],
  Magallanes: [
    'Punta Arenas',
    'Laguna Blanca',
    'Río Verde',
    'San Gregorio',
    'Cabo de Hornos (Ex Navarino)',
    'Antártica',
    'Porvenir',
    'Primavera',
    'Timaukel',
    'Natales',
    'Torres del Paine',
  ],
  'Región Metropolitana': [
    'Cerrillos',
    'Cerro Navia',
    'Conchalí',
    'El Bosque',
    'Estación Central',
    'Huechuraba',
    'Independencia',
    'La Cisterna',
    'La Florida',
    'La Granja',
    'La Pintana',
    'La Reina',
    'Las Condes',
    'Lo Barnechea',
    'Lo Espejo',
    'Lo Prado',
    'Macul',
    'Maipú',
    'Ñuñoa',
    'Pedro Aguirre Cerda',
    'Peñalolén',
    'Providencia',
    'Pudahuel',
    'Quilicura',
    'Quinta Normal',
    'Recoleta',
    'Renca',
    'Santiago',
    'San Joaquín',
    'San Miguel',
    'San Ramón',
    'Vitacura',
    'Puente Alto',
    'Pirque',
    'San José de Maipo',
    'Colina',
    'Lampa',
    'Tiltil',
    'San Bernardo',
    'Buin',
    'Calera de Tango',
    'Paine',
    'Melipilla',
    'Alhué',
    'Curacaví',
    'María Pinto',
    'San Pedro',
    'Talagante',
    'El Monte',
    'Isla de Maipo',
    'Padre Hurtado',
    'Peñaflor',
  ],
};
