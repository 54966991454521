import { ApiList, ApiObject, SinglePaymentBuyer } from '../../../app/type';

import client from '../client';

export const list = (clientId: string): Promise<ApiList<SinglePaymentBuyer>> => {
  return client({
    method: 'get',
    url: `/v1/client/single_payment_buyers`,
    params: { client_id: clientId },
  })
    .then(({ data: body }: { data: ApiList<SinglePaymentBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const show = (
  singlePaymentBuyerId: string,
  clientId: string
): Promise<ApiObject<SinglePaymentBuyer>> => {
  return client({
    method: 'get',
    url: `/v1/client/single_payment_buyers/${singlePaymentBuyerId}`,
    params: { client_id: clientId },
  })
    .then(({ data: body }: { data: ApiObject<SinglePaymentBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
