import * as React from 'react';
import { createContext } from 'react';
import { NotificationContext, Notification } from '../../app/type';

const NotifyContext = createContext<NotificationContext | null>(null);

export const useNotifyContext = (): NotificationContext => {
  return React.useContext(NotifyContext) as NotificationContext;
};

const NotifyProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);

  const addNotification = (
    description: string | React.ReactElement,
    config: { variant: 'success' | 'pending' | 'warning' | 'error' | 'info' }
  ): number => {
    const newNotification: Notification = {
      id: Date.now(), // to create unique ids
      content: description,
      variant: config.variant,
    };
    setNotifications([...notifications, newNotification]);
    return newNotification.id;
  };

  const removeNotification = (id: number) => {
    setNotifications(notifications.filter((notif: Notification) => notif.id !== id));
  };

  return (
    <NotifyContext.Provider value={{ notifications, addNotification, removeNotification }}>
      {children}
    </NotifyContext.Provider>
  );
};

export default NotifyProvider;
