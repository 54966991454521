import variables from '../styles/variables.module.scss';

export const STATUS = [
  ['active', 'Activo'],
  ['inactive', 'Inactivo'],
];

export const STATUS_COLORS = Object.fromEntries([
  ['active', variables.success],
  ['inactive', variables.lightygray],
]);

export const RECURRENCE = [
  ['single', 'Puntual'],
  ['week', 'Semanal'],
  ['month', 'Mensual'],
  ['bimonth', 'Cada 2 meses'],
  ['quarter', 'Trimestral'],
  ['semester', 'Semestral'],
  ['year', 'Anual'],
  ['custom', 'Personalizada'],
];

export const STATUS_ES = Object.fromEntries(STATUS);
export const RECURRENCE_ES = Object.fromEntries(RECURRENCE);
