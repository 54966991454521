import React, { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useSelector } from '../../app/hooks';

import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import { generateImage } from '../utils/cropImage';
import {
  Avatar as MuiAvatar,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Slider,
  Button,
} from '@mui/material';

import { Camera as CameraIcon } from 'react-feather';

import { SellerState } from '../../features/seller/sellerSlice';
import { sellerApi } from '../api';
import { useSnackbar } from 'notistack';

interface AvatarProps {
  img?: string;
  text?: string;
  alt?: string;
  textLetters?: number;
  autoColor?: boolean;
  className?: any;
  upload?: boolean;
  context?: 'product' | 'buyer' | 'company' | 'payment';
}

interface CompanyInfo {
  id: string;
  image_file?: Blob;
}

export const Avatar = ({
  img,
  text,
  alt,
  upload,
  context,
  ...rest
}: AvatarProps): React.ReactElement => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState<string>('');
  const [croppedArea, setCroppedArea] = React.useState<Area>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const submitFormRef = useRef<HTMLButtonElement>(null);
  let srcImage: string;
  switch (context) {
    case 'buyer':
      srcImage =
        'https://storage.googleapis.com/onlypays-public/assets/images/side%20view%20of%20young%20man%20wearing%20smart%20watch%20and%20holding%20book.png';
      break;
    case 'product':
      srcImage = 'https://storage.googleapis.com/onlypays-public/assets/images/product_box.svg';
      break;
    case 'company':
      srcImage = 'https://storage.googleapis.com/onlypays-public/assets/images/example_store.svg';
      break;
    case 'payment':
      srcImage =
        'https://storage.googleapis.com/onlypays-public/assets/images/shopping%20cart%20with%20boxes.svg';
      break;
    default:
      srcImage =
        img ||
        'https://storage.googleapis.com/onlypays-public/assets/images/side%20view%20of%20young%20man%20wearing%20smart%20watch%20and%20holding%20book.png';
  }

  const formik = useFormik<CompanyInfo>({
    initialValues: { id: company?.id || '', image_file: new Blob() },
    onSubmit: (companyInfo: CompanyInfo, { setErrors }: any) => {
      enqueueSnackbar('Foto recibida y siendo actualizada', { variant: 'info' });
      sellerApi.companies
        .update({ data: companyInfo })
        .then(() => null)
        .catch((err: any): void => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        });
    },
  });

  useEffect(() => {
    formik.initialValues.id = company?.id || '';
  }, [company]);

  const Camera = styled(CameraIcon)(() => ({
    width: 22,
    height: 22,
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: 2,
  }));

  const onCropComplete = useCallback(async (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const onUploadImage = (event: any) => {
    setDialogOpen(!dialogOpen);
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    if (inputFileRef.current) inputFileRef.current.click();
  };

  const acceptImage = async () => {
    if (croppedArea) {
      const canvas = await generateImage(image, croppedArea);
      canvas
        ? canvas.toBlob(
            (blob: Blob | null) => {
              if (blob) {
                const newImg = document.createElement('img'),
                  url = URL.createObjectURL(blob);

                newImg.onload = () => {
                  URL.revokeObjectURL(url);
                };

                newImg.src = url;
                formik.setFieldValue('image_file', blob);
                enqueueSnackbar('Imagen procesada con éxito', { variant: 'success' });
                if (submitFormRef.current) submitFormRef.current.click();
              }
            },
            'image/png',
            0.66
          )
        : null;
      setDialogOpen(!dialogOpen);
    }
  };

  if (upload) {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <Fragment>
              <input
                type="file"
                accept="image/*"
                hidden
                id="image"
                ref={inputFileRef}
                onChangeCapture={onUploadImage}
              />
              <Camera onClick={onBtnClick} />
            </Fragment>
          }
          style={{ cursor: 'pointer' }}
        >
          <MuiAvatar src={srcImage} alt={alt || text} {...rest} />
        </Badge>

        <Dialog open={dialogOpen} keepMounted onClose={() => setDialogOpen(!dialogOpen)}>
          <Grid container>
            <Grid item xs={12}>
              <DialogTitle>Ajusta la imagen por favor</DialogTitle>
            </Grid>
            <Grid item xs={12}>
              <DialogContent>
                <div style={{ position: 'relative', width: '100%', height: 250 }}>
                  <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    objectFit="contain"
                  />
                </div>
                <div>
                  <Slider
                    value={zoom}
                    min={1}
                    max={2}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => setZoom(Number(zoom))}
                    classes={{ root: 'slider' }}
                  />
                </div>
              </DialogContent>
            </Grid>
            <Grid item xs={12}>
              <DialogActions>
                <Button onClick={() => setDialogOpen(!dialogOpen)}>Cancelar</Button>
                <Button onClick={acceptImage}>Aceptar</Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Dialog>
        <button hidden type="submit" ref={submitFormRef}></button>
      </form>
    );
  }

  if (upload && img) {
    return (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={<Camera />}
      >
        <MuiAvatar src={srcImage} alt={alt || text} {...rest} />
      </Badge>
    );
  }

  if (img) {
    return <MuiAvatar src={img} alt={alt || text} {...rest} />;
  }

  return <MuiAvatar src={srcImage} alt={alt || text} {...rest} />;
};

export default Avatar;
