import client from '../client';

export const redirect_buy_product = (query: string): any => {
  return client({
    method: 'get',
    url: `/v1/external/shopify/integrations/buy_product${query}&version=v2`,
  })
    .then(({ data: body }: { data: Promise<any> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ error });
      throw error;
    });
};
export const redirect_index_product = (query: string): any => {
  return client({
    method: 'get',
    url: `/v1/external/shopify/integrations${query}&version=v2`,
  })
    .then(({ data: body }: { data: Promise<any> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ error });
      throw error;
    });
};

export default {
  redirect_buy_product,
  redirect_index_product,
};
