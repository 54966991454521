import React from 'react';
import ReactDOM from 'react-dom';
import './common/styles/index.scss';
import App from './app/App';
import { store, persistor } from './app/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import PersistLoader from './common/components/PersistLoader';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://d9ecbba16842476a85345fbb30794d99@o256157.ingest.sentry.io/6126797',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['api.zafepay.com'],
      }),
    ],
    environment: 'prod',
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<PersistLoader />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
