import { Transfer, ApiList } from '../../../app/type';

import client from '../client';

export const list = (
  companyId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<Transfer>> => {
  return client({
    method: 'get',
    url: `/v1/transfers`,
    params: { id: companyId, query, page: page || 1, filter_params: filterParams },
  })
    .then(({ data: body }: { data: ApiList<Transfer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const downloadExcel = (resourceId?: string): Promise<{ message: string }> => {
  return client({
    method: 'get',
    url: `/v1/transfers/${resourceId}/download_excel`,
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const boxesData = (companyId?: string): Promise<{ [key: string]: any }> => {
  return client({
    method: 'get',
    url: `/v1/transfers/transparency_boxes_data`,
    params: { company_id: companyId },
  })
    .then(({ data: body }: { data: { [key: string]: any } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const generateExcel = (parentId?: string): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/transfers/generate_excel`,
    params: { company_id: parentId },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export default { list, downloadExcel, boxesData };
