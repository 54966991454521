import React from 'react';
import { backofficeApi } from '../../common/api';

import ResourceList from '../../common/components/ResourceList';

import { ResponseStatement } from '../../app/type';
import { formatCurrency } from '../../common/utils';

const PaidStatements = (): React.ReactElement => {
  return (
    <ResourceList
      title="Cobros Realizados"
      queryFields="Por Arreglar"
      getResourceList={(_id, query, page) => backofficeApi.paidStatements(query, page)}
      listHeaders={[
        process.env.NODE_ENV === 'development' ? { key: 'id', label: 'ID del pago' } : null,
        { key: 'amount', label: 'Monto' },
        { key: 'bank_commission', label: 'Comisión medio de pago' },
        { key: 'payment_method', label: 'Medio de pago' },
        { key: 'company_name', label: 'Empresa' },
        { key: 'product_name', label: 'Producto' },
        { key: 'payment_date', label: 'Fecha de pago' },
      ]}
      listMobileHeaders={[
        process.env.NODE_ENV === 'development' ? { key: 'id' } : null,
        { key: 'amount' },
        { key: 'payment_date' },
        { key: 'company' },
      ]}
      listColumns={{
        id: (res: ResponseStatement) => res.id.slice(0, 8),
        product_name: (res: ResponseStatement) => res.product?.name,
        company_name: (res: ResponseStatement) => res.company?.name,
        amount: (res: ResponseStatement) => formatCurrency(res.amount),
        bank_commission: (res: ResponseStatement) => formatCurrency(res.to_invoice),
        payment_date: (res: ResponseStatement) => res.payment_date,
        payment_method: (res: ResponseStatement) => res.payment_type,
      }}
      resourceParent={{ id: 'none' }}
      defaultRoute="backoffice/dashboard"
      excelDownloadMethod={(query) => backofficeApi.paidStatementsExcel(query)}
    />
  );
};

export default PaidStatements;
