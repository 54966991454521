import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BackofficeCompany, ResponseStatement, ResponseSeller } from '../../app/type';
export interface BackofficeState {
  company?: BackofficeCompany;
  responseStatement?: ResponseStatement;
  responseSeller?: ResponseSeller;
  loading: boolean;
}

const initialState: BackofficeState = {
  loading: false,
};

export const backofficeSlice = createSlice({
  name: 'backoffice',
  initialState,
  reducers: {
    startLoading: (state: BackofficeState) => {
      state.loading = true;
    },
    stopLoading: (state: BackofficeState) => {
      state.loading = false;
    },
    setBackofficeCompany: (
      state: BackofficeState,
      action: PayloadAction<BackofficeCompany | undefined>
    ) => {
      if (action.payload) {
        state.company = action.payload;
      } else {
        state.company = undefined;
      }
    },
    clearData: () => {
      return initialState;
    },
    setResponseStatement: (
      state: BackofficeState,
      action: PayloadAction<ResponseStatement | undefined>
    ) => {
      if (action.payload) {
        state.responseStatement = action.payload;
      } else {
        state.responseStatement = undefined;
      }
    },
    setResponseSeller: (
      state: BackofficeState,
      action: PayloadAction<ResponseSeller | undefined>
    ) => {
      if (action.payload) {
        state.responseSeller = action.payload;
      } else {
        state.responseSeller = undefined;
      }
    },
  },
});

export const {
  startLoading,
  stopLoading,
  setBackofficeCompany,
  clearData,
  setResponseStatement,
  setResponseSeller,
} = backofficeSlice.actions;

export default backofficeSlice.reducer;
