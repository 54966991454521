import React, { useState } from 'react';
import { Typography, useMediaQuery } from '@mui/material';

import PaymentPlanCard from '../components/PaymentPlanCard';
import SubscriptionBuyerCard from '../components/SubscriptionBuyerCard';
import SinglePaymentBuyerCard from '../components/SinglePaymentBuyerCard';

import { Card, PAC, PaymentPlan, SinglePaymentBuyer, SubscriptionBuyer } from '../../../app/type';
import { useShopCartContext } from '../../../common/contexts/ShopCart';
import PaymentsPopup from '../components/PaymentsPopup';

import styles from './Product.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import StatementsCart from '../components/StatementsCart';

interface ProductIndexProps {
  paymentPlans: PaymentPlan[];
  subscriptionBuyers: SubscriptionBuyer[];
  singlePaymentBuyers: SinglePaymentBuyer[];
  cards: Card[];
  PACs: PAC[];
}

const ProductIndex = ({
  paymentPlans,
  subscriptionBuyers,
  singlePaymentBuyers,
  cards,
  PACs,
}: ProductIndexProps): React.ReactElement => {
  const [openPaymentsPopup, setOpenPaymentsPopup] = useState<boolean>(false);
  const { statementsCart, amountToPay } = useShopCartContext();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);

  return (
    <div className={`${styles.indexContainer} ${amountToPay > 0 && styles.amountToPayHeight}`}>
      <div className={styles.tabTitle}>
        <Typography>Productos</Typography>
      </div>
      <div className={styles.description}>
        <Typography>
          Acá puedes ver todos los servicios y productos activos a los que estás inscrito.
        </Typography>
      </div>
      <div className={styles.plansContainer}>
        {paymentPlans.map((pp: PaymentPlan) => (
          <PaymentPlanCard paymentPlan={pp} key={pp.id} cards={cards} PACs={PACs} />
        ))}
        {subscriptionBuyers.map((subscriptionBuyer: SubscriptionBuyer) => (
          <SubscriptionBuyerCard
            subscriptionBuyer={subscriptionBuyer}
            key={subscriptionBuyer.id}
            cards={cards}
            PACs={PACs}
          />
        ))}
        {singlePaymentBuyers.map((singlePaymentBuyer: SinglePaymentBuyer) => (
          <SinglePaymentBuyerCard
            singlePaymentBuyer={singlePaymentBuyer}
            key={singlePaymentBuyer.id}
          />
        ))}
        {!isMobile && amountToPay > 0 && <div className={styles.toPayDiv}></div>}
      </div>
      {amountToPay > 0 && <StatementsCart setOpenPaymentsPopup={setOpenPaymentsPopup} />}
      <PaymentsPopup
        state={{ open: openPaymentsPopup, setOpen: setOpenPaymentsPopup }}
        amount={amountToPay}
        statements={statementsCart}
        cards={cards}
      />
    </div>
  );
};

export default ProductIndex;
