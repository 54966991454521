import { ApiObject, Payable } from '../../../app/type';

import client from '../client';

export const show = (
  singlePaymentBuyerId: string,
  params?: { [key: string]: any }
): Promise<ApiObject<Payable>> => {
  return client({
    method: 'get',
    url: `/v1/public/single_payment_buyers/${singlePaymentBuyerId}`,
    params: params,
  })
    .then(({ data }: { data: ApiObject<Payable> }) => {
      return data;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
