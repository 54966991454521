// React, packages and api
import React, { useEffect, useRef, useState } from 'react';
import { sellerApi } from '../../../common/api';
import { useHistory, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// Slice and utils
import { useSelector, useDispatch } from '../../../app/hooks';
import { SellerState, setSubscription, setSinglePayment } from '../sellerSlice';
import { formatCurrency, setDocumentTitle } from '../../../common/utils';
import { RECURRENCE_ES } from '../../../common/constants/subscriptions';

// MUI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// Styles and assets
import styles from './Show.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import cStyles from '../../../common/styles/common.module.scss';
import { ReactComponent as ProductsIcon } from '../../../assets/images/seller/products_icon.svg';
import { Edit as EditIcon, Trash as TrashIcon } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagShopping, faCreditCard } from '@fortawesome/free-solid-svg-icons';

// Components
import Avatar from '../../../common/components/Avatar';
import Stepper from '../../../common/components/Stepper';
import InfoBox from '../../../common/components/InfoBox';
import ResourceList from '../../../common/components/ResourceList';
import StatusLabel from '../../../common/components/StatusLabel';
import PopUp from '../../../common/components/PopUp';

// Types
import { Subscription, SinglePayment } from '../../../app/type';

const ProductShow = (): React.ReactElement => {
  const { product } = useSelector(({ seller }: { seller: SellerState }) => seller);
  setDocumentTitle(product?.name || 'Productos');
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const reloadRef = useRef<{ reloadResource: () => void }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [singlePayments, setSinglePayments] = useState<SinglePayment[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const productHasPayables = singlePayments.length + subscriptions.length !== 0;
  const [boxesData, setBoxesData] = useState<{ [key: string]: number }>({});

  const closeDeleteDialog = () => {
    if (product) {
      sellerApi.products
        .destroy(product?.id)
        .then(() => {
          history.push('/seller/products');
          enqueueSnackbar('Producto eliminado', { variant: 'success' });
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
              variant: 'error',
            });
          } else if (error.response?.status === 400) {
            enqueueSnackbar('El producto no puede ser eliminado por tener pagos completados', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Ha ocurrido un error eliminando el producto', { variant: 'error' });
          }
        });
    }
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    if (product?.id) {
      sellerApi.products
        .showBoxes(product.id)
        .then((data: { [key: string]: number }) => setBoxesData(data))
        .catch(() =>
          enqueueSnackbar('Ha ocurrido un error obteniendo las estadísticas del producto', {
            variant: 'error',
          })
        );
    }
  }, [product]);

  const boxes = (width?: number) => [
    <InfoBox
      key={1}
      title="TOTAL RECAUDADO EN EL MES"
      data={formatCurrency(boxesData.gathered_in_month || 0)}
      kind="info2"
      style={styles.firstInfoContainer}
      icon={<FontAwesomeIcon icon={faBagShopping} className="icon" />}
      width={width}
    />,
    <InfoBox
      key={2}
      title="TOTAL RECAUDADO"
      data={formatCurrency(boxesData.total_gathered || 0)}
      kind="info2"
      style={styles.secondInfoContainer}
      icon={<FontAwesomeIcon icon={faCreditCard} className="icon" />}
      width={width}
    />,
  ];

  return (
    <>
      <div className={styles.start}>
        <div className={styles.startHeader}>
          <ProductsIcon />
          <Typography variant={isMobile ? 'h5' : 'h4'}>
            <span className={styles.startTitle}>Productos</span>
          </Typography>
        </div>
        <Typography variant="body1">
          Podrás inscribir tus <b>productos</b> en tus suscripciones y pagos únicos.
        </Typography>
      </div>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Paper className={cStyles.infoPaper}>
            {/* HEADER */}
            <Grid container>
              <Grid item xs={12}>
                <div className={cStyles.paperHeader}>
                  <Avatar
                    className={cStyles.paperHeaderAvatar}
                    text={product?.name}
                    img={product?.image}
                    context="product"
                  />
                  <div className={cStyles.paperHeaderContent}>
                    <Typography variant={isMobile ? 'h6' : 'h5'}>
                      <b>{product?.name}</b>
                    </Typography>
                    {!isMobile && (
                      <Typography variant="subtitle1">{product?.description}</Typography>
                    )}
                  </div>
                  <div className={cStyles.paperHeaderActions}>
                    <div className={`${cStyles.baseAction} ${cStyles.editAction}`}>
                      <IconButton
                        size="medium"
                        component={Link}
                        to={`/seller/products/${product?.id}/edit`}
                        className={`${cStyles.icon} ${cStyles.editIcon}`}
                      >
                        {<EditIcon />}
                      </IconButton>
                      <Typography variant="body2">Editar</Typography>
                    </div>
                    <div className={`${cStyles.baseAction} ${cStyles.deleteAction}`}>
                      <IconButton
                        size="medium"
                        onClick={() => setDeleteDialogOpen(true)}
                        className={`${cStyles.icon} ${cStyles.deleteIcon}`}
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={20} /> : <TrashIcon />}
                      </IconButton>
                      <Typography variant="body2">Eliminar</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>

            {/* INFO */}
            <Grid container className={cStyles.infoContainer}>
              {isMobile && product?.description ? (
                <Grid item xs={12}>
                  <Typography variant="caption">Descripción:</Typography>
                  <Typography variant="h6">{product?.description}</Typography>
                </Grid>
              ) : null}
              <Grid item xs={6} md={4}>
                <Typography variant="caption">Valor:</Typography>
                <Typography variant="h6">
                  <b>{formatCurrency(product?.value, product?.currency)}</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography variant="caption">Referencia externa:</Typography>
                <Typography variant="h6">
                  <b>{product?.external_id || '-'}</b>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {isMobile ? (
        <Stepper assets={boxes(12).slice(0, -1)} />
      ) : (
        <Grid container className={styles.containerGrid} flexWrap={'nowrap'}>
          {boxes().map((box) => box)}
        </Grid>
      )}

      <ResourceList
        title="Suscripciones"
        queryFields="Nombre"
        setResource={setSubscription}
        getResourceList={async (resId, query, page, filterParams) => {
          setLoading(true);
          return sellerApi.products
            .subscriptions(resId, query, page, filterParams)
            .then((data) => {
              setSubscriptions(data.data);
              return data;
            })
            .finally(() => setLoading(false));
        }}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.subscriptions.generateExcel('product', parentId, query, filterParams)
        }
        resourceParent={product}
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'value', label: 'Valor' },
          { key: 'recurrence', label: 'Periodicidad' },
          { key: 'status', label: 'Estado' },
          { key: 'active_clients', label: 'Clientes Activos' },
        ]}
        listMobileHeaders={[{ key: 'name' }, { key: 'value' }, { key: 'status' }]}
        listColumns={{
          name: (res: Subscription) => res.name,
          status: (res: Subscription) => <StatusLabel status={res.status} type="subscription" />,
          value: (res: Subscription) => formatCurrency(res.render_amount, res.currency),
          recurrence: (res: Subscription) => RECURRENCE_ES[res.recurrence],
          active_clients: (res: Subscription) => res.active_clients,
        }}
        listActionsHeaders={(res?: Subscription) => {
          if (res) return [{ key: 'show', label: 'Ver Suscripción' }];
          return [null];
        }}
        listActions={{
          show: (res: Subscription) => {
            dispatch(setSubscription(res));
            history.push(`/seller/subscriptions/${res.id}`);
          },
        }}
        innerRef={reloadRef}
        filtersKey="product_subscriptions_index"
        persistFilters
      />

      <ResourceList
        title="Pagos Únicos"
        queryFields="Nombre"
        setResource={setSinglePayment}
        getResourceList={async (resId, query, page, filterParams) => {
          setLoading(true);
          return sellerApi.products
            .singlePayments(resId, query, page, filterParams)
            .then((data) => {
              setSinglePayments(data.data);
              return data;
            })
            .finally(() => setLoading(false));
        }}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.singlePayments.generateExcel('product', parentId, query, filterParams)
        }
        resourceParent={product}
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'value', label: 'Valor' },
          { key: 'status', label: 'Estado' },
          { key: 'received_payments', label: 'Pagos Recibidos' },
        ]}
        listMobileHeaders={[{ key: 'name' }, { key: 'value' }, { key: 'status' }]}
        listColumns={{
          name: (res: SinglePayment) => res.name,
          value: (res: SinglePayment) => formatCurrency(res.render_amount, res.currency),
          status: (res: SinglePayment) => <StatusLabel status={res.status} type="singlePayment" />,
          received_payments: (res: SinglePayment) => res.recieved_single_payments,
        }}
        listActionsHeaders={(res?: SinglePayment) => {
          if (res)
            return [
              { key: 'show', label: 'Ver Pago Único' },
              // { key: 'download_excel', label: 'Descargar Excel', icon: faArrowAltCircleDown },
            ];
          return [null];
        }}
        listActions={{
          show: (res: SinglePayment) => {
            dispatch(setSinglePayment(res));
            history.push(`/seller/single_payments/${res.id}`);
          },
          // download_excel: (res: Product) => downloadExcel(res),
        }}
        innerRef={reloadRef}
        filtersKey="product_single_payments_index"
        persistFilters
      />

      {/* DIALOGS */}
      <PopUp
        state={{ open: deleteDialogOpen, setOpen: setDeleteDialogOpen }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          productHasPayables ? (
            <>
              <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
                Este producto está incluido en las siguientes suscripciones o pagos únicos:
              </Typography>
              {subscriptions.map((subscription, index) => (
                <Typography key={index}>- {subscription.name}</Typography>
              ))}
              {singlePayments.map((singlePayment, index) => (
                <Typography key={index}>- {singlePayment.name}</Typography>
              ))}
              <Typography align="center">
                Esto modificará el precio de todos ellos y la acción es irreversible. ¿Deseas
                continuar?
              </Typography>
            </>
          ) : (
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              Esta acción es irreversible. ¿Estás seguro/a que deseas continuar?
            </Typography>
          )
        }
        extraActions={[
          <Button onClick={closeDeleteDialog} color="error" variant="contained" key={1}>
            Si
          </Button>,
          <Button onClick={() => setDeleteDialogOpen(false)} variant="outlined" key={2}>
            No
          </Button>,
        ]}
      />
    </>
  );
};

export default ProductShow;
