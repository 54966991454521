import variables from '../styles/variables.module.scss';

export const PERIODS: { [key: string]: string } = {
  week: 'semanas',
  month: 'meses',
  bimonth: 'cada 2 meses',
  quarter: 'trimestres',
  semester: 'semestres',
  year: 'años',
};

export const STATUS = [
  ['active', 'Activo'],
  ['inactive', 'Inactivo'],
];

export const P_STATUS_COLORS = Object.fromEntries([
  ['active', variables.success],
  ['inactive', variables.lightygray],
]);

export const P_STATUS_ES = Object.fromEntries(STATUS);

export const APPORTION = {
  hasApportion:
    'Por ejemplo, eliges que tu servicio sea mensual y se pague los 15. Si tu cliente se inscribió un 5, entonces se le cobrará un proporcional a esos 10 días',
  doesNotHaveApportion:
    'Se le cobrará el monto de la suscripción entera independiente del día que se inscriba tu cliente',
};

export const CUMULATIVE_DEBT = {
  accumulatesDebt:
    'Si un cliente no te paga en la fecha acordada, entonces se le creará otra cuota y acumulará deuda.',
  doesNotAccumulateDebt:
    'Si un cliente no te paga en la fecha acordada, entonces no se crearán más cuotas hasta que el cliente pague lo que deba.',
};
