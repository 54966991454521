import React, { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './Filter.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

let queryTimeout: ReturnType<typeof setTimeout>;

interface QueryProps {
  query: string;
  setQuery: (query: string) => void;
  queryFields: string;
}

export const Query = (props: QueryProps): React.ReactElement => {
  const [loadingIcon, setLoadingIcon] = useState<boolean>(false);

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (queryTimeout) clearTimeout(queryTimeout);

    setLoadingIcon(true);
    queryTimeout = setTimeout(() => {
      props.setQuery(event.target.value);
      setLoadingIcon(false);
    }, 1000);
  };

  useEffect(() => {
    props.setQuery(props.query);
  }, [props.query]);

  return (
    <TextField
      InputProps={{
        startAdornment: loadingIcon ? (
          <CircularProgress size={20} className={styles.icon} />
        ) : (
          <FontAwesomeIcon icon={faMagnifyingGlass} className={styles.icon} />
        ),
      }}
      className={styles.queryBox}
      onChange={handleQueryChange}
      defaultValue={props.query}
      placeholder={props.queryFields}
    />
  );
};

export default Query;
