export const BANKS = [
  'Banco Santander',
  'Banco BICE',
  'Banco Internacional',
  'Banco Itaú',
  'Banco de Chile / Edwards-Citi',
  'Banco BCI',
  'Banco Estado',
  'Banco Falabella',
  'Banco Security',
  'Scotiabank',
  'HSBC Bank',
  'Banco Ripley',
  'Banco Consorcio',
  'Coopeuch',
];

export const ACCOUNT_TYPES = ['Cuenta Corriente', 'Cuenta Vista', 'Cuenta de Ahorro'];
