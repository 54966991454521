import React, { useState } from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { Statement } from '../../../app/type';
import { useShopCartContext } from '../../../common/contexts/ShopCart';
import { formatCurrency } from '../../../common/utils';
import { useSelector } from '../../../app/hooks';
import { ClientState } from '../clientSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../products/Product.module.scss';
import variables from '../../../common/styles/variables.module.scss';

interface StatementsCartProps {
  setOpenPaymentsPopup: (value: boolean) => void;
}

const StatementsCart = ({ setOpenPaymentsPopup }: StatementsCartProps): React.ReactElement => {
  const [showCartDetail, setShowCartDetail] = useState<boolean>(false);
  const { amountToPay, fullStatementsCart, removeStatementsCart } = useShopCartContext();
  const { company } = useSelector(({ client }: { client: ClientState }) => client);
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);

  return (
    <div
      className={styles.toPayContainer}
      style={{ paddingTop: showCartDetail ? '50px' : 'inherit' }}
    >
      <div
        className={styles.collapseCircle}
        onClick={() => setShowCartDetail(!showCartDetail)}
        style={{
          background: company?.payment_design?.background_color
            ? company?.payment_design?.background_color
            : '#4653e3',
        }}
      >
        <FontAwesomeIcon icon={showCartDetail ? faChevronDown : faChevronUp} />
      </div>
      <Collapse
        className={styles.statementsCollapse}
        in={showCartDetail}
        timeout="auto"
        unmountOnExit
      >
        <Table size="small">
          <TableBody>
            {fullStatementsCart.map((statement: Statement) => (
              <TableRow key={statement.id} className={styles.statementRow}>
                <TableCell>
                  <Typography variant="h1">{statement.service_name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5">{formatCurrency(statement.amount)}</Typography>
                </TableCell>
                <TableCell>
                  <div
                    className={styles.deleteFromCart}
                    onClick={() => removeStatementsCart(statement)}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                    {!isMobile && <Typography>Eliminar</Typography>}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Collapse>
      <Typography sx={{ marginTop: '10px' }}>Total a pagar:</Typography>
      <div className={styles.totalAmount}>
        <Typography>{formatCurrency(amountToPay)}</Typography>
      </div>
      <div
        className={styles.payButton}
        onClick={() => setOpenPaymentsPopup(true)}
        style={{
          background: company?.payment_design?.background_color
            ? company?.payment_design?.background_color
            : '#4653e3',
        }}
      >
        <Typography>Pagar cuotas</Typography>
      </div>
    </div>
  );
};

export default StatementsCart;
