import variables from '../styles/variables.module.scss';

export const STATUS = [
  ['', 'Enviandose al SII'],
  ['sii_waiting', 'Esperando respuesta SII'],
  ['sii_accepted', 'Aceptado por SII'],
  ['sii_rejected', 'Rechazado por SII'],
  ['company_received', 'Recibido por receptor'],
  ['company_accepted', 'Aceptado por receptor'],
  ['company_rejected', 'Rechazado por receptor'],
  ['canceled', 'Anulado'],
  ['company_not_received', 'No recibido por receptor'],
  ['auto_accepted', 'Autoaceptado'],
  ['sii_accepted_with_warnings', 'Aceptado con observaciones por SII'],
  ['no_folios', 'Sin folios disponibles'],
  ['missing_data', 'Datos de facturación incompletos'],
];

export const STATUS_COLORS = Object.fromEntries([
  ['', variables.pending],
  ['sii_waiting', variables.warning],
  ['sii_accepted', variables.success],
  ['sii_rejected', variables.error],
  ['company_received', variables.success],
  ['company_accepted', variables.success],
  ['company_rejected', variables.error],
  ['canceled', variables.warning],
  ['company_not_received', variables.warning],
  ['auto_accepted', variables.success],
  ['sii_accepted_with_warnings', variables.warning],
  ['no_folios', variables.error],
  ['missing_data', variables.error],
]);

export const STATUS_ES = Object.fromEntries(STATUS);
