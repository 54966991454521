import client from '../client';

interface FintocResponse {
  status: number;
  payment_id: number;
  success_url?: string;
  failure_url?: string;
}

export const check_status = (paymentId: number): Promise<FintocResponse> => {
  return client({
    method: 'get',
    url: `/v1/public/fintoc/${paymentId}/check_status`,
  })
    .then(({ data: body }: { data: FintocResponse }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscribeError: error });
      throw error;
    });
};

export default {
  check_status,
};
