import { ApiObject, Company, CompanyEmailConfiguration } from '../../../app/type';

import client from '../client';

export const getValues = (companyId: string): Promise<CompanyEmailConfiguration> => {
  return client({
    method: 'get',
    url: `/v1/companies/${companyId}/company_email_configuration`,
  })
    .then(({ data: body }: { data: Promise<CompanyEmailConfiguration> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const update = (
  companyId: string,
  CompanyEmailConfiguration: CompanyEmailConfiguration
): Promise<CompanyEmailConfiguration> => {
  return client({
    method: 'put',
    url: `/v1/companies/${companyId}/update_company_email_subscription_configuration`,
    data: { data: CompanyEmailConfiguration },
  })
    .then(({ data: body }: { data: Promise<CompanyEmailConfiguration> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const updateDisabledEmails = (
  companyId: string,
  disabledEmails: string[]
): Promise<ApiObject<Company>> => {
  return client({
    method: 'put',
    url: `/v1/companies/${companyId}/update_disabled_emails`,
    data: { disabled_emails: disabledEmails },
  })
    .then(({ data: body }: { data: Promise<ApiObject<Company>> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export default {
  getValues,
  update,
  updateDisabledEmails,
};
