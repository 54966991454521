import { ApiObject, Client } from '../../../app/type';

import client from '../client';

export const sendAuthenticationCode = (
  email: string,
  companyId: string
): Promise<ApiObject<Client>> => {
  return client({
    method: 'post',
    url: `/v1/client/buyers/create_authentication_code`,
    params: { email: email, company_id: companyId },
  })
    .then(({ data: body }: { data: ApiObject<Client> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const validateAuthenticationCode = (
  email: string,
  code: string,
  companyId: string
): Promise<ApiObject<Client>> => {
  return client({
    method: 'post',
    url: `/v1/client/buyers/validate_authentication_code`,
    params: { email: email, code: code, company_id: companyId },
  })
    .then(({ data: body }: { data: ApiObject<Client> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const authenticateClientById = (
  buyerId: string,
  companyId: string
): Promise<ApiObject<Client>> => {
  return client({
    method: 'post',
    url: `/v1/client/buyers/authenticate_client_by_id`,
    params: { buyer_id: buyerId, company_id: companyId },
  })
    .then(({ data: body }: { data: ApiObject<Client> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
