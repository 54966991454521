import React, { useMemo, useState } from 'react';

// Components
import {
  useMediaQuery,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';

// Assets
import styles from './TableList.module.scss';
import variables from '../styles/variables.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { formatCurrency } from '../utils';

import { Row } from './Row';

import { ApiList } from '../../app/type';
import { Header, Renderers } from './ResourceListTypes';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface TableListProps<T, R> {
  resources: any[];
  headers: (Header | null)[];
  listActionsHeaders?: (res?: T) => ({
    key?: string;
    label?: string;
    icon?: IconDefinition;
    color?: 'error' | 'success' | 'warning' | 'info';
  } | null)[];
  listActions?: { [key: string]: (object: T) => void };
  columns?: Renderers<T>;
  loading?: boolean;
  className?: string;
  onStatistics?: (total?: number, count?: number) => void;
  msgOnError?: string;
  getCollapseResources?: (resourceId: string) => Promise<ApiList<R>>;
  listCollapseHeaders?: (Header | null)[];
  listCollapseColumns?: Renderers<R>;
  chips?: Header[];
  handleChipClick?: (chip: Header) => () => void;
  listCollapseActionsHeaders?: (res?: R) => ({ key?: string; label?: string } | null)[];
  listCollapseActions?: { [key: string]: (object: R) => void };
  actionsTitle?: string;
  collapseActionsTitle?: string;
}

const TableList = <T, R>({
  resources,
  headers,
  listActions,
  listActionsHeaders,
  columns,
  loading,
  className,
  onStatistics,
  msgOnError,
  getCollapseResources,
  listCollapseHeaders,
  listCollapseColumns,
  chips,
  handleChipClick,
  listCollapseActionsHeaders,
  listCollapseActions,
  actionsTitle,
  collapseActionsTitle,
}: TableListProps<T, R>): React.ReactElement => {
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const defaultRender = (key: string) => (res: any) => res[key] || '-';
  const [menuEl, setMenuEl] = useState<{ [key: string]: any }>({});
  const openMenu = (key: string, event: any) => setMenuEl({ [key]: event?.currentTarget });
  const closeMenu = () => setMenuEl({});
  const hasActions = listActionsHeaders ? listActionsHeaders?.().length > 0 : false;
  const [displayChips, setDisplayChips] = useState<[boolean, Element | null]>([false, null]);

  const onItemSelect = (key: string, res: T) => () => {
    closeMenu();
    if (listActions) listActions[key]?.(res);
  };

  const filteredOptions = (res: T) => {
    if (listActionsHeaders)
      return listActionsHeaders(res).filter((o) => o?.key && o?.label) as Header[];
  };

  const headersToUse = useMemo((): Header[] => {
    return headers.filter((h) => h) as Header[];
  }, [isMobile, headers]);

  const cellRenders = useMemo((): Renderers<T> => {
    const filtered = headers.filter((h) => h) as Header[];
    return filtered.reduce((hash: Renderers<T>, header: Header) => {
      let renderer = defaultRender(header.key);
      if (columns?.[header.key]) renderer = columns[header.key];
      return { ...hash, [header.key]: renderer };
    }, {});
  }, [headers]);

  const handleSendButton = () => {
    if (onStatistics) {
      let total = 0;
      let count = 0;
      resources.forEach((r: any) => {
        total += r.amount * (1 - r.total_commission);
        count += 1;
      });
      onStatistics(total, count);
    }
  };

  let paymentTotal = 0;
  resources.forEach((r: any) => {
    paymentTotal += r.amount;
  });

  let commissionTotal = 0;
  resources.forEach((r: any) => {
    commissionTotal += parseFloat((r.amount * r.total_commission).toFixed(2));
  });

  return (
    <TableContainer className={className}>
      <Table
        className={`${styles.table} ${hasActions ? styles.actionedTable : styles.notActionedTable}`}
      >
        <TableHead className={styles.tableHead}>
          <TableRow className={styles.regularRow}>
            {headersToUse.map((header: Header) => (
              <TableCell key={header.key}>{header.label}</TableCell>
            ))}
            {getCollapseResources ? (
              chips?.length ? (
                <>
                  <TableCell>
                    <div
                      className={styles.chipsButton}
                      onClick={(event) => setDisplayChips([!displayChips[0], event.currentTarget])}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                  </TableCell>
                  <Menu
                    open={displayChips[0]}
                    anchorEl={displayChips[1]}
                    onClose={() => setDisplayChips([false, null])}
                  >
                    {chips.map((value) => (
                      <MenuItem key={value.key} onClick={handleChipClick && handleChipClick(value)}>
                        {value.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                <TableCell />
              )
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody className={styles.tableBody}>
          {loading ? (
            <TableRow className={styles.regularRow}>
              <TableCell align="center" colSpan={headersToUse.length + 1}>
                {loading ? 'Cargando...' : msgOnError ? msgOnError : 'Sin resultados'}
              </TableCell>
            </TableRow>
          ) : resources.length === 0 ? (
            <TableRow className={styles.regularRow}>
              <TableCell align="center" colSpan={headersToUse.length + 1}>
                {msgOnError ? msgOnError : 'Sin resultados'}
              </TableCell>
            </TableRow>
          ) : (
            resources.map((res: any) => (
              <Row
                key={res.id}
                res={res}
                headersToUse={headersToUse}
                onItemSelect={onItemSelect}
                cellRenders={cellRenders}
                hasActions={hasActions}
                filteredOptions={filteredOptions}
                menuEl={menuEl}
                openMenu={openMenu}
                closeMenu={closeMenu}
                getCollapseResources={getCollapseResources}
                listCollapseHeaders={listCollapseHeaders}
                listCollapseColumns={listCollapseColumns}
                listCollapseActionsHeaders={listCollapseActionsHeaders}
                listCollapseActions={listCollapseActions}
                actionsTitle={actionsTitle}
                collapseActionsTitle={collapseActionsTitle}
              />
            ))
          )}
          {onStatistics && (
            <TableRow className={styles.regularRow} sx={{ width: '100%' }}>
              <TableCell>
                <Button onClick={handleSendButton} variant="contained">
                  Enviar
                </Button>
              </TableCell>
              <TableCell>{formatCurrency(paymentTotal)}</TableCell>
              <TableCell>{formatCurrency(commissionTotal)}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableList;
