import { ApiList, Card, GenericObject } from '../../../app/type';

import client from '../client';

export const create = (
  clientId: string,
  productId?: string,
  rejectedCardId?: string,
  productType?: string
): Promise<GenericObject> => {
  return client({
    method: 'post',
    url: `/v1/client/webpay_cards`,
    params: {
      client_id: clientId,
      product_id: productId,
      rejected_card_id: rejectedCardId,
      product_type: productType,
    },
  })
    .then(({ data: body }: { data: GenericObject }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const list = (clientId: string): Promise<ApiList<Card>> => {
  return client({
    method: 'get',
    url: `/v1/client/webpay_cards`,
    params: { client_id: clientId },
  })
    .then(({ data: body }: { data: ApiList<Card> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
